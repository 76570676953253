import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../core/common/dataTable/index";
import { TableData } from "../../core/data/interface";
import PredefinedDateRanges from "../../core/common/datePicker";
import CommonSelect from "../../core/common/commonSelect";
import {
  category2,
  invoiceNumber,
  paymentMethod,
  source,
} from "../../core/common/selectoption/selectoption";
import { Button, DatePicker } from "antd";
import { all_routes } from "../router/all_routes";
import TooltipOption from "../../core/common/tooltipOption";
import ImageWithBasePath from "../../core/common/imageWithBasePath";
import axiosInstance from "../auth/axiosInstance";
import Select from "react-select";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const AccountsLiabilities = () => {
  const routes = all_routes;
  // const data = accounts_liability_data;

  const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const [ledgerList, setLedgerList] = useState<any>([]);

  const typeOptions = [
    { label: "Asset", value: "asset" },
    { label: "Liability", value: "liability" },
    { label: "Liabilities", value: "liability" },
    { label: "Expense", value: "Expense" },
  ];
  const [editData, setEditData] = useState<any>();
  const ledgerTypeOptions = [
    { label: "Select", value: "Select" },
    { label: "Asset", value: "asset" },
    { label: "Income", value: "Expense" },
    { label: "Liabilities", value: "liability" },
    { label: "Expense", value: "expense" },
  ];
  const ledgerCategoryOptions = [
    { label: "Select", value: "Select" },
    { label: "Payment Method", value: "payment" },
    { label: "Fees Type", value: "feesType" },
    { label: "Salary Type", value: "salaryType" },
    { label: "Other", value: "other" },
  ];
  const defaultCatOption =
    ledgerCategoryOptions.find((option: any) => option.value === editData?.categoryType) || null;
  const defaultTypeOption =
    ledgerTypeOptions.find((option: any) => option.value === editData?.ledgerType) || null;

  const [categoryOptions, setcategoryOptions] = useState<any>([{ label: "", value: "" }]);
  const [subCategoryOptions, setSubCategoryOptions] = useState<any>([{ label: "", value: "" }]);
  function formatIndianNumber(number: any) {
    const parts = number.toString().split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1] ? "." + parts[1] : "";

    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);

    const formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    return otherDigits
      ? formattedOtherDigits + "," + lastThreeDigits + decimalPart
      : lastThreeDigits + decimalPart;
  }
  const [paymentOptions, setPaymentOptions] = useState([
    { label: "Payment Method", value: "Paymenth method" },
  ]);
  const [liabilityList, setLiabilitiesList] = useState([]);
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const [liabilityData, setLiabilitiesData] = useState<any>({
    id: null,
    name: "",
    type: "credit",
    description: "",
    schoolId: schoolId,
    paymentMethodName: "",
    paymentMethodId: 0,
    categoryId: undefined,
    subCategoryId: 0,
    date: "",
    amount: "",
    invoiceNo: "",
    paidBy: "",
    userId: null,
    // userId: 1,
  });
  const getAll = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/ledger/school/" + schoolId);

    setLedgerList(res.data.filter((d: any) => d.ledgerType === "liability"));
  };
  const editOne = async (e: any) => {
    e.preventDefault();
    const { id, ...data } = editData;
    await axiosInstance.patch("/ledger/" + editData.id, data);
    getAll();
  };
  const deleteOne = async (e: any) => {
    e.preventDefault();
    await axiosInstance.delete("/ledger/" + editData.id);
    getAll();
  };
  const addOne = async (e: any) => {
    e.preventDefault();
    // console.log(editData);

    await axiosInstance.post("/ledger", editData);
    getAll();
  };
  console.log("🤣🤣", schoolId);

  useEffect(() => {
    getAll();
  }, [schoolId]);
  // const handleAddLiabilitiesRequest = async () => {
  //   try {
  //     const response = await axiosInstance.post("/schoolAccountRoutes", liabilityData);
  //     fetchLiabilitiessList();
  //     console.log("Expense Category added successfully:", response.data);
  //   } catch (error) {
  //     console.error("Error adding homework:", error);
  //   }
  // };

  // const handleEditLiabilities = async () => {
  //   //event.preventDefault(); // Prevent the default link behavior
  //   try {
  //     const response = await axiosInstance.put(`/schoolAccountRoutes/` + liabilityData.id, liabilityData);
  //     fetchLiabilitiessList();
  //     console.log("Expense Category edited successfully:", response.data);
  //   } catch (error) {
  //     console.error("Error adding homework:", error);
  //   }
  // };

  // const handleDeleteLiabilities = async () => {
  //   // event.preventDefault(); // Prevent the default link behavior
  //   await axiosInstance.delete("/schoolAccountRoutes/" + liabilityData.id);
  //   fetchLiabilitiessList();
  // };
  const [view, setView] = useState("list");
  const [activeLedger, setActiveLedger] = useState<any>({});

  const getLedgerTransactions = async () => {
    if (!schoolId || !activeLedger?.id) return;
    const res = await axiosInstance.get(
      "/schoolAccountRoutes/school/" + schoolId + "/ledger/" + activeLedger?.name
    );
    setActiveLedger((prev: any) => ({ ...prev, transactions: res.data }));
  };
  useEffect(() => {
    if (view === "single") getLedgerTransactions();
  }, [view]);
  const ledgerColms = [
    {
      title: "Description",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text: string) => <>{dayjs(text).format("DD-MMM-YYYY")}</>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text: string) => (
        <>
          {
            <span className={`badge ${text === "credit" ? "badge-success" : "badge-danger"}`}>
              {text}
            </span>
          }
        </>
      ),
    },
    {
      title: "Invoice No",
      dataIndex: "invoiceNo",
    },
    {
      title: "Vch. No",
      dataIndex: "vchNo",
    },
  ];
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: any, record: any) => (
        <Link
          to="#"
          onClick={() => {
            setView("single");
            setActiveLedger(record);
          }}
          className="link-primary"
        >
          {text}
        </Link>
      ),
      sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },
    {
      title: "Ledger",
      dataIndex: "name",
      sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },

    {
      title: "Ledger Type",
      dataIndex: "ledgerType",
      // sorter: (a: TableData, b: TableData) => a.description.length - b.description.length,
    },
    {
      title: "Category Type",
      dataIndex: "categoryType",
      // sorter: (a: TableData, b: TableData) => a.description.length - b.description.length,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      // sorter: (a: TableData, b: TableData) => a.description.length - b.description.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <>
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_ledger"
                    onClick={() => {
                      setEditData(record);
                      console.log(editData);
                    }}
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Edit
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                    onClick={() => {
                      setEditData(record);
                    }}
                  >
                    <i className="ti ti-trash-x me-2" />
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setEditData((prev: any) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1"> Ledgers (Liabilitiess)</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Finance &amp; Accounts</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Categories
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              {/* <TooltipOption /> */}
              <div className="mb-2 d-flex gap-2">
                <Link
                  to="#"
                  className="btn btn-primary d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#journal-modal"
                >
                  <i className="ti ti-clipboard-check" />
                  Journal Entry
                </Link>
                <Link
                  to="#"
                  className="btn btn-primary d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#add_ledger"
                  onClick={() => {
                    setEditData({
                      name: "",
                      ledgerType: "liability",
                      categoryType: "",
                      balance: "",
                      schoolId,
                    });
                  }}
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Add Liabilities
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {view === "list" && (
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                <h4 className="mb-3"> Ledger (Liabilitiess) List</h4>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="input-icon-start mb-3 me-2 position-relative">
                    <PredefinedDateRanges />
                  </div>
                  <div className="dropdown mb-3 me-2">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      <i className="ti ti-filter me-2" />
                      Filter
                    </Link>
                    <div className="dropdown-menu drop-width">
                      <form>
                        <div className="d-flex align-items-center border-bottom p-3">
                          <h4>Filter</h4>
                        </div>
                        <div className="p-3 pb-0 border-bottom">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Liabilities</label>
                                <CommonSelect
                                  className="select"
                                  options={category2}
                                  defaultValue={category2[0]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-3 d-flex align-items-center justify-content-end">
                          <Link to="#" className="btn btn-light me-3">
                            Reset
                          </Link>
                          <button type="submit" className="btn btn-primary">
                            Apply
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="dropdown mb-3">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-sort-ascending-2 me-2" />
                      Sort by A-Z
                    </Link>
                    <ul className="dropdown-menu p-3">
                      <li>
                        <Link to="#" className="dropdown-item rounded-1 active">
                          Ascending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Descending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Viewed
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Added
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {ledgerList && ledgerList.length > 0 && (
                <div className="card-body p-0 py-3">
                  {/* Expenses Category List */}
                  <Table dataSource={ledgerList} columns={columns} Selection={false} />
                  {/* /Expenses Category List */}
                </div>
              )}
            </div>
          )}
          {view === "single" && (
            <div className="card">
              <div className="card-header">
                <button className="btn btn-primary " onClick={() => setView("list")}>
                  <i className="fas fa-chevron-left me-2" />
                  Back
                </button>
              </div>
              <div className="card-body">
                <h4 className="form-title mb-3">Ledger Details</h4>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <span className="form-label me-1">Ledger Name:</span>
                    {activeLedger?.name}
                  </div>
                  <div className="col-md-3">
                    <span className="form-label me-1">Ledger Type:</span>
                    {activeLedger?.ledgerType}
                  </div>
                  <div className="col-md-3">
                    <span className="form-label me-1">Category Type:</span>
                    {activeLedger?.categoryType}
                  </div>
                  <div className="col-md-3">
                    <span className="form-label me-1">Balance:</span>
                    {activeLedger?.balance}
                  </div>
                </div>
                <div className="row">
                  <h4 className="form-title mb-3">Transactions</h4>
                  {activeLedger.transactions && (
                    <Table
                      dataSource={activeLedger.transactions}
                      columns={ledgerColms}
                      Selection={false}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* Add Ledger*/}
      <div className="modal fade" id="add_ledger">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Liabilities</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Liabilities Name </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={editData?.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Category Type</label>
                      <Select
                        name="categoryType"
                        onChange={(obj: any) =>
                          setEditData((prev: any) => ({ ...prev, categoryType: obj.value }))
                        }
                        options={ledgerCategoryOptions}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <label className="form-label">Ledger Type</label>

                        <Select
                          isDisabled={true}
                          value={{ label: "Liabilities", value: "liability" }}
                          name="ledgerType"
                          options={ledgerTypeOptions}
                        />
                      </div>
                      {(editData?.ledgerType === "asset" ||
                        editData?.ledgerType === "liability") && (
                        <div className="col-md-6">
                          <label htmlFor="" className="form-label">
                            Opening Balance
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="balance"
                            value={editData?.balance}
                            onChange={handleInputChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                  Cancel
                </Link>
                <button
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                  onClick={(e) => addOne(e)}
                >
                  Add Liabilities
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Add Ledger */}
      {/* Edit Ledger */}
      <div className="modal fade" id="edit_ledger">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Liabilities</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Liabilities Name </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={editData?.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Category Type</label>
                      <Select
                        name="categoryType"
                        className="react-select"
                        onChange={(obj: any) =>
                          setEditData((prev: any) => ({ ...prev, categoryType: obj.value }))
                        }
                        options={ledgerCategoryOptions}
                        value={defaultCatOption}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <label className="form-label">Ledger Type</label>

                        <Select
                          name="ledgerType"
                          className="react-select"
                          isDisabled={true}
                          value={{ label: "Liabilities", value: "liability" }}
                          options={ledgerTypeOptions}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label">
                          Opening Balance
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="balance"
                          value={editData?.balance}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                  Cancel
                </Link>
                <button
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                  onClick={(e) => editOne(e)}
                >
                  Edit Liabilities
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Edit Expenses Category */}
      {/* Delete Modal */}
      <div className="modal fade" id="delete-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-body text-center">
                <span className="delete-icon">
                  <i className="ti ti-trash-x" />
                </span>
                <h4>Confirm Deletion</h4>
                <p>You want to delete all the marked items, this cant be undone once you delete.</p>
                <div className="d-flex justify-content-center">
                  <Link to="#" className="btn btn-light me-3" data-bs-dismiss="modal">
                    Cancel
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                    onClick={(e) => deleteOne(e)}
                  >
                    Yes, Delete
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Delete Modal */}
      {/* journal modal */}
      {/* <div className="modal fade" id="journal-modal">
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Journal Modal</h4>
            <span>{dayjs(Date.now()).format("DD-MMM-YYYY").toString()}</span>

            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <div className="modal-body">
            <Table
              dataSource={journalData}
              Selection={false}
              columns={jounralColms}
              footer={footer}
            />
          </div>
          <div className="modal-footer">
            <button
              data-bs-dismiss="modal"
              onClick={(e) => handleSubmit(e)}
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div> */}
      {/* journal modal */}
    </div>
  );
};

export default AccountsLiabilities;
