import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Table from "../../core/common/dataTable/index";
import { expense_category_data } from "../../core/data/json/expenses_category_data";
import { TableData } from "../../core/data/interface";
import CommonSelect from "../../core/common/commonSelect";
import { category2 } from "../../core/common/selectoption/selectoption";
import PredefinedDateRanges from "../../core/common/datePicker";
import { all_routes } from "../router/all_routes";
import TooltipOption from "../../core/common/tooltipOption";
import axiosInstance from "../auth/axiosInstance";
import { useSelector } from "react-redux";
import Select from "react-select";
declare global {
  interface Window {
    bootstrap: any;
  }
}

const SubCategory = () => {
  const routes = all_routes;
  const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const [subCategoryList, setSubCategoryList] = useState<any>([]);
  const { id: categoryId } = useParams();
  const [categoryOptions, setcategoryOptions] = useState<any>([{ value: "", label: "" }]);
  // const fetchExpenseCategoryList = async () => {
  //   try {
  //     const response = await axiosInstance.get("/expenseCategory", {
  //       params: {
  //         schoolId: userDetails.schoolId,
  //       },
  //     });
  //     // console.log(response.data);
  //     const transformedData = response.data.map((item: any, idx: number) => ({
  //       id: item.id,
  //       name: item.name,
  //       description: item.description,
  //       key: item.id,
  //     }));
  //     console.log(transformedData);

  //     if (transformedData.length > 0) {
  //       setExpenseCategoryList(transformedData);
  //     } else {
  //       setExpenseCategoryList([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching expense Category list:", error);
  //   }
  // };
  const [editData, setEditData] = useState<any>();
  const getCategoryOptions = async () => {
    if (!schoolId) return;
    const res: any = await axiosInstance.get("/category/school/" + schoolId);
    setcategoryOptions(res.data.map((c: any) => ({ label: c.name, value: c.id })));
  };
  const getAll = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get(
      "/subCategory/school/" + schoolId + "/category/" + categoryId
    );
    setSubCategoryList(res.data);
  };
  const editOne = async (e: any) => {
    e.preventDefault();
    const { id, ...data } = editData;
    await axiosInstance.patch("/subCategory/" + editData.id, data);
    getAll();
  };
  const deleteOne = async (e: any) => {
    e.preventDefault();
    await axiosInstance.delete("/subCategory/" + editData.id);
    getAll();
  };
  const addOne = async (e: any) => {
    e.preventDefault();
    // console.log(editData);

    await axiosInstance.post("/subCategory", editData);
    getAll();
  };
  useEffect(() => {
    getAll();
    getCategoryOptions();
  }, [schoolId]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setEditData((prev: any) => ({ ...prev, [name]: value }));
  };

  // const handleAddExpenseCategoryRequest = async (
  //   event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   event.preventDefault(); // Prevent the default link behavior
  //   try {
  //     const response = await axiosInstance.post("/expenseCategory", expenseCategoryData);
  //     fetchExpenseCategoryList();
  //     const addModal = window.bootstrap.Modal(document.getElementById("add_expenses_category"));
  //     if (addModal) {
  //       addModal.hide();
  //     }
  //   } catch (error) {
  //     console.error("Error adding homework:", error);
  //   }
  // };

  // const handleEditExpenseCategory = async (
  //   event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   //event.preventDefault(); // Prevent the default link behavior
  //   try {
  //     const response = await axiosInstance.put(
  //       `/expenseCategory/` + expenseCategoryData.id,
  //       expenseCategoryData
  //     );
  //     fetchExpenseCategoryList();
  //   } catch (error) {
  //     console.error("Error adding homework:", error);
  //   }
  // };

  // const handleDeleteExpenseCategory = async (
  //   event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   // event.preventDefault(); // Prevent the default link behavior
  //   try {
  //     const response = await axiosInstance.delete("/expenseCategory/" + expenseCategoryData.id);
  //     fetchExpenseCategoryList();
  //   } catch (error) {
  //     console.error("Error adding homework:", error);
  //   }
  // };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: any) => (
        <Link to="#" className="link-primary">
          {text}
        </Link>
      ),
      sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },
    {
      title: "Sub Category Name",
      dataIndex: "name",
      sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a: TableData, b: TableData) => a.description.length - b.description.length,
    },
    {
      title: "Paid",
      dataIndex: "paid",
    },
    {
      title: "Due",
      dataIndex: "due",
    },
    {
      title: "Discount",
      dataIndex: "discount",
    },
    {
      title: "Total",
      dataIndex: "total",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <>
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_expenses_category"
                    onClick={() => {
                      setEditData(record);
                    }}
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Edit
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                    onClick={() => {
                      setEditData(record);
                    }}
                  >
                    <i className="ti ti-trash-x me-2" />
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">
                Sub Categories {"("}
                {categoryOptions?.find((c: any) => c.value === Number(categoryId))?.label} {")"}
              </h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Finance &amp; Accounts</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Sub Categories
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
              <div className="mb-2">
                <Link
                  to="#"
                  className="btn btn-primary d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#add_expenses_category"
                  onClick={() => {
                    setEditData({ schoolId, categoryId: Number(categoryId) });
                  }}
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Add Sub Category
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3"> Categories List</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div className="dropdown-menu drop-width">
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 pb-0 border-bottom">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Category</label>
                              <CommonSelect
                                className="select"
                                options={category2}
                                defaultValue={category2[0]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {subCategoryList && subCategoryList.length > 0 && (
              <div className="card-body p-0 py-3">
                {/* Expenses Category List */}
                <Table dataSource={subCategoryList} columns={columns} Selection={false} />
                {/* /Expenses Category List */}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* Add Expenses Category */}
      <div className="modal fade" id="add_expenses_category">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Sub Category</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Sub Category Name </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <textarea
                        rows={4}
                        className="form-control"
                        defaultValue={""}
                        name="description"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Change Category </label>
                      <Select
                        name="categoryId"
                        options={categoryOptions}
                        onChange={(obj: any) =>
                          setEditData((prev: any) => ({ ...prev, categoryId: obj.value }))
                        }
                        value={categoryOptions.find((c: any) => c.value === Number(categoryId))}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Paid </label>
                      <input
                        type="number"
                        className="form-control"
                        name="paid"
                        value={editData?.paid}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Due </label>
                      <input
                        type="text"
                        className="form-control"
                        name="due"
                        value={editData?.due}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Discount </label>
                      <input
                        type="text"
                        className="form-control"
                        name="discount"
                        value={editData?.discount}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Total </label>
                      <input
                        type="text"
                        className="form-control"
                        name="total"
                        value={editData?.total}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                  Cancel
                </Link>
                <button
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                  onClick={(e) => addOne(e)}
                >
                  Add Category
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Add Expenses Category */}
      {/* Edit Expenses Category */}
      <div className="modal fade" id="edit_expenses_category">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Category</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Sub Category Name </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={editData?.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <textarea
                        rows={4}
                        className="form-control"
                        // defaultValue={""}
                        value={editData?.description}
                        name="description"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Change Category </label>
                      <Select
                        name="categoryId"
                        options={categoryOptions}
                        onChange={(obj: any) =>
                          setEditData((prev: any) => ({ ...prev, categoryId: obj.value }))
                        }
                        value={categoryOptions.find((c: any) => c?.value === editData?.categoryId)}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Paid </label>
                      <input
                        type="number"
                        className="form-control"
                        name="paid"
                        value={editData?.paid}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Due </label>
                      <input
                        type="text"
                        className="form-control"
                        name="due"
                        value={editData?.due}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Discount </label>
                      <input
                        type="text"
                        className="form-control"
                        name="discount"
                        value={editData?.discount}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Total </label>
                      <input
                        type="text"
                        className="form-control"
                        name="total"
                        value={editData?.total}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={(e) => editOne(e)}
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Edit Expenses Category */}
      {/* Delete Modal */}
      <div className="modal fade" id="delete-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-body text-center">
                <span className="delete-icon">
                  <i className="ti ti-trash-x" />
                </span>
                <h4>Confirm Deletion</h4>
                <p>You want to delete all the marked items, this cant be undone once you delete.</p>
                <div className="d-flex justify-content-center">
                  <Link to="#" className="btn btn-light me-3" data-bs-dismiss="modal">
                    Cancel
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                    onClick={(e) => deleteOne(e)}
                  >
                    Yes, Delete
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Delete Modal */}
    </div>
  );
};

export default SubCategory;
