import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import {
  departmentName,
  designationName,
  morefilterStaff,
  staffName,
} from "../../../../core/common/selectoption/selectoption";
import { Contract, Shift } from "../../../../core/common/selectoption/selectoption";
import ExcelJs from "exceljs";
import { saveAs } from "file-saver";
import Table from "../../../../core/common/dataTable/index";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { staff } from "../../../../core/data/json/staff";
// import { Table } from "antd";
import { TableData } from "../../../../core/data/interface";
import PredefinedDateRanges from "../../../../core/common/datePicker";
import CommonSelect from "../../../../core/common/commonSelect";
import { all_routes } from "../../../router/all_routes";
import TooltipOption from "../../../../core/common/tooltipOption";
import axios from "axios";
import { initialStaffValues } from "../staffService/staff.Modal";
import { Button } from "antd";
import axiosInstance from "../../../auth/axiosInstance";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
const Staff = () => {
  // console.log(staff);
  const { role } = useSelector((state: any) => state.userSlice);
  const [options, setOptions] = useState<any>([]);

  console.log(role);
  const { schoolId: unusedSchoolId, status, ...excelFormatObj } = initialStaffValues;

  const [data, setData] = useState<any[]>([]);
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const [selectedStaffId, setSelectedStaffId] = useState<String>("");
  const routes = all_routes;
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const fetchOptions = async () => {
    if (!schoolId) return;
    let res = await axiosInstance.get("/department", { params: { schoolId } });
    setOptions((prev: any) => ({
      ...prev,
      departmentOptions: res.data.map((dep: any) => dep.name),
    }));
    res = await axiosInstance.get("/designation", { params: { schoolId } });
    setOptions((prev: any) => ({
      ...prev,
      designationOptions: res.data.map((des: any) => des.name),
    }));
    res = await axiosInstance.get("/role/all", { params: { schoolId } });
    setOptions((prev: any) => ({
      ...prev,
      roleOptions: res.data.map((role: any) => role.name),
    }));
  };
  const staffHeaders: any = Object.keys(excelFormatObj).map((attr: any) => ({
    header: attr,
    key: attr,
    width: 20,
  }));
  const handleImportFile = async (e: any) => {
    const file = e.target.files[0];
    console.log(file);

    const arrayBuffer = await file.arrayBuffer();
    const wb = new ExcelJs.Workbook();
    await wb.xlsx.load(arrayBuffer);
    const ws = wb.worksheets[0];
    let jsonData: any = [];

    const headers: any = [];
    ws.getRow(1).eachCell((cell, colNumber) => {
      headers.push(cell.text); // Assuming first row is the header
    });

    ws.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        // Skip the first row (headers)
        const rowData: any = {};

        row.eachCell((cell, colNumber) => {
          const header = headers[colNumber - 1]; // Get header based on column index
          rowData[header] = cell.text; // Map cell value to the header
        });

        jsonData.push(rowData);
      }
    });
    // console.log("Extracted JSON Data:", jsonData);
    jsonData = jsonData.map((row: any) => ({ ...row, schoolId: schoolId }));
    await axiosInstance.post("/staff/upload", jsonData);
  };
  const downloadFormat = async () => {
    const workBook = new ExcelJs.Workbook();
    const workSheet = workBook.addWorksheet("Sheet 1");
    workSheet.columns = staffHeaders;
    for (let i = 1; i <= 10000; ++i) workSheet.addRow([]);
    const roleCol = workSheet.getColumn("role");
    const depatmentCol = workSheet.getColumn("department");
    const designationCol = workSheet.getColumn("designation");
    const maritalStatusCol = workSheet.getColumn("maritalStatus");
    const genderCol = workSheet.getColumn("gender");
    const contractCol = workSheet.getColumn("contractType");
    const shiftCol = workSheet.getColumn("workShift");

    roleCol.eachCell({ includeEmpty: true }, function (cell, cellNumber) {
      cell.dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: [`"${options.roleOptions.join(", ")}"`],
      };
    });
    depatmentCol.eachCell({ includeEmpty: true }, function (cell, cellNumber) {
      cell.dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: [`"${options.departmentOptions.join(", ")}"`],
      };
    });
    designationCol.eachCell({ includeEmpty: true }, function (cell, cellNumber) {
      cell.dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: [`"${options.designationOptions.join(", ")}"`],
      };
    });
    genderCol.eachCell({ includeEmpty: true }, function (cell, cellNumber) {
      cell.dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: [`"${options.genderOptions.join(", ")}"`],
      };
    });
    maritalStatusCol.eachCell({ includeEmpty: true }, function (cell, cellNumber) {
      cell.dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: [`"${options.maritalOptions.join(", ")}"`],
      };
    });
    contractCol.eachCell({ includeEmpty: true }, function (cell, rowNumber) {
      // console.log("Processing cell:", rowNumber);
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`"${options.contractOptions.join(", ")}"`],
      };
    });
    shiftCol.eachCell({ includeEmpty: true }, function (cell, cellNumber) {
      cell.dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: [`"${options.shiftOptions.join(", ")}"`],
      };
    });

    const buffer = await workBook.xlsx.writeBuffer();
    const blob = new Blob([buffer]);
    saveAs(blob, "Staff.xlsx");
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to={`${routes.staffDetails}/${record.id}`} className="link-primary">
            {record.id}
          </Link>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <Link to={routes.staffDetails + "/" + record.id} className="avatar avatar-md">
            <ImageWithBasePath src={record.img} className="img-fluid rounded-circle" alt="img" />
          </Link>
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to={`${routes.staffDetails}/${record.id}`}>{text}</Link>
            </p>
          </div>
        </div>
      ),
      sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a: TableData, b: TableData) => a.department.length - b.department.length,
    },
    {
      title: "Designation",
      dataIndex: "designation",
      sorter: (a: TableData, b: TableData) => a.designation.length - b.designation.length,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: (a: TableData, b: TableData) => a.phone.length - b.phone.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a: TableData, b: TableData) => a.email.length - b.email.length,
    },
    {
      title: "Date of Join",
      dataIndex: "dateOfJoin",
      sorter: (a: TableData, b: TableData) => a.dateOfJoin.length - b.dateOfJoin.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: string, record: any) => (
        <>
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to={`${routes.staffDetails}/${record.id}`}
                  >
                    <i className="ti ti-menu me-2" />
                    View Staff
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item rounded-1" to={`${routes.editStaff}/${record.id}`}>
                    <i className="ti ti-edit-circle me-2" />
                    Edit
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    onClick={() => setSelectedStaffId(record.id)}
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                  >
                    <i className="ti ti-trash-x me-2" />
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];
  const fetchStaffList = async () => {
    if (!schoolId) return;
    try {
      const response = await axiosInstance.get("/staff/staffList", {
        params: {
          schoolId: schoolId,
        },
      });
      // console.log(response.data);
      const transformedData = response.data.map((item: any, idx: number) => ({
        id: item.id,
        name: `${item.firstName} ${item.lastName}`,
        department: item.department,
        designation: item.designation,
        phone: item.primaryContactNumber,
        email: item.emailAddress,
        userId: item.userId,
        dateOfJoin: new Date(item.dateOfJoining).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
        img: "assets/img/profiles/avatar-27.jpg", // Assuming a default image
        key: item.id,
      }));
      // console.log(transformedData);

      if (transformedData.length > 0) {
        setData(transformedData);
      }
    } catch (error) {
      console.error("Error fetching staff list:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axiosInstance.delete("/staff/delete", {
        params: {
          id: selectedStaffId,
        },
      });

      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchStaffList();
    fetchOptions();

    setOptions((prev: any) => ({ ...prev, genderOptions: ["Male", "Female"] }));
    setOptions((prev: any) => ({ ...prev, maritalOptions: ["Single", "Married"] }));
    setOptions((prev: any) => ({ ...prev, contractOptions: Contract.map((c) => c.value) }));
    setOptions((prev: any) => ({ ...prev, shiftOptions: Shift.map((s) => s.value) }));
  }, [schoolId]);

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Staffs</h3>

              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">HRM</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Staffs
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex gap-4 my-xl-auto right-content align-items-center flex-wrap">
              <div className="mb-2">
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#import-modal"
                >
                  Import
                </Link>
              </div>
              <div className="mb-2">
                <Link to={routes.addStaff} className="btn btn-primary d-flex align-items-center">
                  <i className="ti ti-square-rounded-plus me-2" />
                  Add Staff
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="card ">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Staff List</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div className="dropdown-menu drop-width" ref={dropdownMenuRef}>
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 border-bottom">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Name</label>
                              <CommonSelect className="select" options={staffName} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Department</label>
                              <CommonSelect className="select" options={departmentName} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-0">
                              <label className="form-label">Designation</label>
                              <CommonSelect className="select" options={designationName} />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-0">
                              <label className="form-label">More Filter</label>
                              <CommonSelect className="select" options={morefilterStaff} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <Link to="#" className="btn btn-primary" onClick={handleApplyClick}>
                          Apply
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z{" "}
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Staffs List */}
              <Table columns={columns} dataSource={data} Selection={true} />
              {/* /Staffs List */}
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="delete-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-body text-center">
                <span className="delete-icon">
                  <i className="ti ti-trash-x" />
                </span>
                <h4>Confirm Deletion</h4>
                <p>You want to delete all the marked items, this cant be undone once you delete.</p>
                <div className="d-flex justify-content-center">
                  <Link to="#" className="btn btn-light me-3" data-bs-dismiss="modal">
                    Cancel
                  </Link>
                  <Button onClick={handleDelete} className="btn btn-danger" data-bs-dismiss="modal">
                    Yes, Delete
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* import modal */}
      <div className="modal fade " id="import-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Import </h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <div className="modal-body d-flex gap-5">
              <Link to="#" onClick={() => downloadFormat()} className="btn btn-primary">
                Download Format
              </Link>
              <label className="btn btn-primary">
                {" "}
                <input
                  onChange={(e) => handleImportFile(e)}
                  style={{ display: "none" }}
                  type="file"
                  accept=".xlsx, .xlw, .csv"
                />
                Import Excel File
              </label>
            </div>
            <div className="modal-footer">
              Note: Please download the formatted Excel sheet to ensure your data matches the
              required structure before importing.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staff;
