import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { Link, useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import axiosInstance from "../../../auth/axiosInstance";
import { DatePicker } from "antd";

import Table from "../../../../core/common/dataTable/index";
import CommonSelect from "../../../../core/common/commonSelect";
import Select from "react-select";
import { TableData } from "../../../../core/data/interface";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { FaPlus, FaTrash } from "react-icons/fa";
const StaffDetails = () => {
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const { id } = useParams();
  const navigate = useNavigate();
  // console.log("ssssssssssssssssss");
  const [activeTab, setActiveTab] = useState("basic-details");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [salaryOptions, setSalaryOptions] = useState([]);
  const getPaymentOptions = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/ledger/school/" + schoolId + "/category/payment");
    console.log(res.data);
    setPaymentMethods(res.data.map((p: any) => ({ value: p.id, label: p.name })));
  };
  const getSalaryOptions = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/ledger/school/" + schoolId + "/category/salaryType");
    console.log(res.data);
    setSalaryOptions(res.data.map((p: any) => ({ value: p.id, label: p.name })));
  };
  const [formData, setFormData] = useState({
    id: "",
    userId: 0,
    first_name: "",
    last_name: "",
    fatherName: "",
    motherName: "",
    qualification: "",
    workExperience: "",
    currentAddress: "",
    permanentAddress: "",
    role: "",
    department: "",
    designation: "",
    epfNumber: "",
    basicSalary: "",
    gender: "",
    marital: "",
    contract: "",
    primary_contact: "",
    shift: "",
    route: "",
    vehicleNumber: "",
    pickupPoint: "",
    hostel: "",
    roomno: "",
    bloodGroup: "",
    email: "",
    phone: "",
    dateOfJoining: dayjs(Date.now()),
    dateOfBirth: dayjs(Date.now()),
    workLocation: "",
    medicalLeaves: "",
    casualLeaves: "",
    maternityLeaves: "",
    sickLeaves: "",
    accountName: "",
    accountNumber: "",
    bankName: "",
    ifscCode: "",
    branchName: "",
    facebookURL: "",
    twitterURL: "",
    linkedinURL: "",
    instagramURL: "",
    password: "",
    confirmPassword: "",
    note: "",
    // Add other fields as necessary
  });
  const [leaveOptions, setLeaveOptions] = useState([]);
  const [editLeaveData, setEditLeaveData] = useState<{
    userId: any;
    status: string;
    startDate: Dayjs;
    endDate: Dayjs;
    leaveTypeId: number;
    leaveTypeBalance: number;
    approverId: null;
    reason: string;
    schoolId: number;
    appliedBy: any;
  }>({
    userId: formData.userId,
    appliedBy: formData.first_name + " " + formData.last_name,
    status: "Pending",
    approverId: null,
    startDate: dayjs(Date.now()),
    endDate: dayjs(Date.now()),
    leaveTypeId: 1,
    leaveTypeBalance: 1,
    schoolId: schoolId,
    reason: "",
  });
  const [userLeavesData, setUserLeavesData] = useState<{
    leaveBalances: Array<any>;
    leaveApplications: Array<any>;
  }>({ leaveBalances: [], leaveApplications: [] });

  const [salaryData, setSalaryData] = useState({
    items: [
      {
        title: "",
        amount: 0,
        ledgerId: 0,
      },
    ],
    email: formData.email,
    date: "",
    amount: 0,
    paymentMethodId: 0,
    schoolId: schoolId,
    subTotal: 0,
    discount: 0,
    tax: 0,
  });

  const getUserLeavesData = async () => {
    const res = await axiosInstance.get("/leaveBalance/user/" + formData.userId);
    setUserLeavesData((prev: any) => ({ ...prev, leaveBalances: res.data }));
    setLeaveOptions(
      res.data.map((leave: any) => ({ label: leave.leaveType.type_name, value: leave.leaveTypeId }))
    );
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    { title: "Leave Type", dataIndex: "leaveType" },

    {
      title: "Start Date",
      dataIndex: "startDate",
      render: (text: string) => <>{dayjs(text).format("DD-MMM-YYYY")}</>,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      render: (text: string) => <>{dayjs(text).format("DD-MMM-YYYY")}</>,
    },
    {
      title: "Applied On",
      dataIndex: "createdAt",
      render: (text: string) => <>{dayjs(text).format("DD-MMM-YYYY")}</>,
    },
    {
      title: "No. of Days",
      dataIndex: "noOfDays",
      render: (text: string, record: any) => (
        <>{record.endDate.diff(record.startDate, "day") + 1}</>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: string) => (
        <>
          {text === "Approved" ? (
            <span className="badge badge-soft-success d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          ) : text === "Pending" ? (
            <span className="badge badge-soft-pending d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          ) : (
            <span className="badge badge-soft-danger d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          )}
        </>
      ),
      // sorter: (a: TableData, b: TableData) => a.status.length - b.status.length,
    },
  ];

  const [dataSource, setDataSource] = useState([]);
  const getStaffLeaves = async () => {
    const res = await axiosInstance.get("/leaveApplication/user/" + formData.userId);
    let data = res.data;
    data = data.map((leave: any) => ({
      ...leave,
      leaveType: leave.leaveType.type_name,
      startDate: dayjs(leave.startDate),
      endDate: dayjs(leave.endDate),
      createdAt: dayjs(leave.createdAt),
    }));
    setDataSource(data);
    console.log(res.data);
  };

  const handleAddSalaryType = () => {
    setSalaryData({
      ...salaryData,
      items: [
        ...salaryData.items,
        {
          title: "",
          amount: 0,
          ledgerId: 0,
        },
      ],
    });
  };
  const handlePaySalary = async () => {
    try {
      console.log("teacher emaill", formData.email);
      const data: any = { ...salaryData };
      data.email = formData.email;
      data.staffId = Number(id);
      const res = await axiosInstance.post("/wallet/paySalary", data);
      const paymentMethodOption: any = paymentMethods.find(
        (p: any) => p.value === salaryData.paymentMethodId
      );
      const pdfJsonData: any = {
        name: formData.first_name + " " + formData.last_name,

        invoiceNo: res.data.invoiceNo,
        vchNo: res.data.vchNo,
        date: dayjs(salaryData.date).format("DD-MMM-YYYY"),
        address: formData.permanentAddress,
        email: formData.email,
        mobile: formData.primary_contact,
        items: salaryData.items.map((it) => {
          const ledgerOption: any = salaryOptions.find((f: any) => f.value === it.ledgerId);
          return {
            ledgerName: ledgerOption ? ledgerOption?.label : "Unknown", // Fallback to "Unknown" if no match is found
            amount: it.amount,
          };
        }),
        subTotal: salaryData.subTotal,
        discount: salaryData.discount,
        tax: salaryData.tax,
        amount: salaryData.amount,
        paymentMethodName: paymentMethodOption.label,
        user: { userName: "Staff", id: "Staff", userId: id },
      };
      console.log(pdfJsonData);
      navigate("/accounts/invoice", { state: { pdfJsonData } });
    } catch (err) {
      console.log(err);
    }
  };
  const initData = async () => {
    // console.log("🤣🤣🤣");

    const response = await axiosInstance.get(`/staff/${id}`);
    setEditLeaveData((prev) => ({ ...prev, userId: response.data.userId }));
    setFormData({
      id: response.data.id,
      userId: response.data.userId,
      first_name: response.data.firstName,
      last_name: response.data.lastName,
      fatherName: response.data.fathersName,
      motherName: response.data.mothersName,
      qualification: response.data.qualification,
      workExperience: response.data.workExperience,

      currentAddress: response.data.currentAddress || "",
      permanentAddress: response.data.permanentAddress || "",
      role: response.data.role,
      department: response.data.department,
      designation: response.data.designation,
      epfNumber: response.data.epfNumber || "",
      basicSalary: response.data.basicSalary || "",
      gender: response.data.gender,
      marital: response.data.maritalStatus,
      contract: response.data.contractType || "",
      primary_contact: response.data.primaryContactNumber,
      shift: response.data.workShift || "",
      route: response.data.route || "",
      vehicleNumber: response.data.vehicleNumber || "",
      pickupPoint: response.data.pickupPoint || "",
      hostel: response.data.hostel || "",
      roomno: response.data.roomno || "",
      bloodGroup: response.data.bloodGroup,
      email: response.data.emailAddress,
      phone: response.data.phone || "",
      dateOfBirth: dayjs(new Date(response.data.dateOfBirth).toLocaleDateString()),
      dateOfJoining: dayjs(new Date(response.data.dateOfJoining).toLocaleDateString()),
      workLocation: response.data.workLocation || "",
      medicalLeaves: response.data.medicalLeaves || "",
      casualLeaves: response.data.casualLeaves || "",
      maternityLeaves: response.data.maternityLeaves || "",
      sickLeaves: response.data.sickLeaves || "",
      accountName: response.data.accountName || "",
      accountNumber: response.data.accountNumber || "",
      bankName: response.data.bankName || "",
      ifscCode: response.data.ifscCode || "",
      branchName: response.data.branchName || "",
      facebookURL: response.data.facebookURL || "",
      twitterURL: response.data.twitterURL || "",
      linkedinURL: response.data.linkedinURL || "",
      instagramURL: response.data.instagramURL || "",
      password: response.data.password || "",
      confirmPassword: response.data.confirmPassword || "",
      note: response.data.notes || "",
    });
    console.log("1️⃣1️⃣", dayjs(new Date(response.data.dateOfJoining).toLocaleDateString("en-US")));
    console.log(formData.dateOfJoining);
  };
  const handleApplyLeave = async () => {
    const res = await axiosInstance.post("/leaveApplication", editLeaveData);
    getStaffLeaves();

    console.log(res.data);
  };

  useEffect(() => {
    getUserLeavesData();
    getStaffLeaves();
  }, [formData.userId]);
  useEffect(() => {
    initData();
    getPaymentOptions();
    getSalaryOptions();
  }, [schoolId]);
  useEffect(() => {
    const totalAmount = salaryData.items.reduce((sum, item) => sum + item.amount, 0);
    const total = totalAmount + salaryData.tax - salaryData.discount;
    setSalaryData((prevData) => ({
      ...prevData,
      amount: total,
      subTotal: totalAmount,
    }));
  }, [salaryData.items, salaryData.tax, salaryData.discount]);
  const handleDeleteRow = (index: number) => {
    const newItems = salaryData.items.filter((_, i) => i !== index);
    setSalaryData({ ...salaryData, items: newItems });
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setSalaryData({
      ...salaryData,
      [name]: parseFloat(value),
    });
  };
  const handleItemChange = (
    index: number,
    field: string,
    value: string | number,
    title: string
  ) => {
    const newItems = [...salaryData.items];
    newItems[index] = { ...newItems[index], [field]: value };
    setSalaryData({ ...salaryData, items: newItems });
  };
  const routes = all_routes;
  return (
    <div>
      <>
        <div className="modal fade" id="add_fees_collect">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex align-items-center">
                  <h4 className="modal-title">Pay Salary </h4>
                </div>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div id="modal-datepicker" className="modal-body">
                  <div className="bg-light-300 p-3 pb-0 rounded mb-4">
                    <div className="row align-items-center">
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3">
                          <span className="fs-12 mb-1">Staff ID</span>
                          <p className="text-dark">{id}</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3">
                          <span className="fs-12 mb-1">Staff Name</span>
                          <p className="text-dark">
                            {formData.first_name + " " + formData.last_name}
                          </p>
                        </div>
                      </div>
                      {/* <div className="col-lg-3 col-md-6">
                        <div className="mb-3">
                          <span className="fs-12 mb-1">Class</span>
                          <p className="text-dark">
                            {studentFromStore.classId + " " + studentFromStore.section}
                          </p>
                        </div>
                      </div> */}
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3">
                          <span className="fs-12 mb-1">Total Outstanding</span>
                          <p className="text-dark">2000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">Payment Type</label>
                        <Select
                          className="react-select"
                          options={paymentMethods}
                          onChange={(value: any) => {
                            setSalaryData({
                              ...salaryData,
                              paymentMethodId: value?.value || "",
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">Payment Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={salaryData.date.split("T")[0]}
                          onChange={(e) => {
                            setSalaryData({
                              ...salaryData,
                              date: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">Discount</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Amout"
                          name="discount"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">Tax</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Amout"
                          name="tax"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="invoice-product-table ">
                      <div className="table-responsive invoice-table">
                        <table className="table  ">
                          <thead>
                            <tr>
                              <th>SL No.</th>
                              <th>Category</th>
                              <th>Amount</th>
                              <th>
                                {" "}
                                <Button onClick={handleAddSalaryType}>
                                  <FaPlus />
                                </Button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {salaryData.items.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <Select
                                    className="react-select"
                                    options={salaryOptions}
                                    onChange={(value: any, label: any) => {
                                      handleItemChange(
                                        index,
                                        "ledgerId",
                                        value?.value || "",
                                        label?.label || ""
                                      );
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Amount"
                                    value={item.amount}
                                    onChange={(e) =>
                                      handleItemChange(
                                        index,
                                        "amount",
                                        parseFloat(e.target.value) || 0,
                                        ""
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <Button variant="danger" onClick={() => handleDeleteRow(index)}>
                                    <FaTrash />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="form-label">Total: {salaryData.amount.toString()}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                    Cancel
                  </Link>
                  <Button
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={handlePaySalary}
                  >
                    Pay Salary
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              {/* Page Header */}
              <div className="col-md-12">
                <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
                  <div className="my-auto mb-2">
                    <h3 className="page-title mb-1">Staff Details</h3>
                    <nav>
                      <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <Link to={routes.adminDashboard}>Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to={routes.studentList}>HRM</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                          Staff Details
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="d-flex my-xl-auto right-content align-items-center  flex-wrap">
                    <Link
                      to={`${routes.editStaff}/${id}`}
                      className="btn btn-primary d-flex align-items-center mb-2"
                    >
                      <i className="ti ti-edit-circle me-2" />
                      Edit Staff
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="col-xxl-3 col-lg-4 theiaStickySidebar">
                <div className="stickybar pb-4">
                  <div className="card border-white">
                    <div className="card-header">
                      <div className="d-flex align-items-center  row-gap-3">
                        <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-27.jpg"
                            className="img-fluid"
                            alt="img"
                          />
                        </div>
                        <div>
                          <span className="badge badge-soft-success d-inline-flex align-items-center mb-1">
                            <i className="ti ti-circle-filled fs-5 me-1" />
                            Active
                          </span>
                          <h5 className="mb-1">{formData.first_name + " " + formData.last_name}</h5>
                          <p className="text-primary m-0">AD1256589</p>
                          <p className="p-0">
                            Joined On : {formData.dateOfJoining.format("DD MMM YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <h5 className="mb-3">Basic Information</h5>
                      <dl className="row mb-0">
                        <dt className="col-6 fw-medium text-dark mb-3">Staff ID</dt>
                        <dd className="col-6  mb-3">{formData.id}</dd>
                        <dt className="col-6 fw-medium text-dark mb-3">Gender</dt>
                        <dd className="col-6  mb-3">{formData.gender}</dd>
                        <dt className="col-6 fw-medium text-dark mb-3">Designation</dt>
                        <dd className="col-6  mb-3">{formData.designation}</dd>
                        <dt className="col-6 fw-medium text-dark mb-3">Department</dt>
                        <dd className="col-6  mb-3">{formData.department}</dd>
                        <dt className="col-6 fw-medium text-dark mb-3">Date Of Birth</dt>
                        <dd className="col-6  mb-3">
                          {formData.dateOfBirth.format("DD MMM YYYY")}
                        </dd>
                        <dt className="col-6 fw-medium text-dark mb-3">Blood Group</dt>
                        <dd className="col-6  mb-3">{formData.bloodGroup}</dd>

                        <dt className="col-6 fw-medium text-dark mb-3">Mother tongue</dt>
                        <dd className="col-6  mb-3">English</dd>
                        <dt className="col-6 fw-medium text-dark mb-0">Language</dt>
                        <dd className="col-6 text-dark mb-0">
                          <span className="badge badge-light text-dark me-2">English</span>
                          {/* <span className="badge badge-light text-dark">Spanish</span> */}
                        </dd>
                      </dl>
                    </div>
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#add_fees_collect"
                      className="btn btn-primary btn-sm w-100"
                    >
                      Pay Salary
                    </Link>
                  </div>
                  <div className="card border-white mb-0">
                    <div className="card-body">
                      <h5 className="mb-3">Primary Contact Info</h5>
                      <div className="d-flex align-items-center mb-3">
                        <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                          <i className="ti ti-phone" />
                        </span>
                        <div>
                          <span className="mb-1 fw-medium text-dark ">Phone Number</span>
                          <p>{formData.primary_contact}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                          <i className="ti ti-mail" />
                        </span>
                        <div>
                          <span className="mb-1 fw-medium text-dark ">Email Address</span>
                          <p>{formData.email}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-9 col-lg-8">
                <div className="row">
                  <div className="col-md-12">
                    <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                      <li>
                        <Link
                          to="#"
                          onClick={() => setActiveTab("basic-details")}
                          className={`nav-link ${activeTab === "basic-details" ? "active" : ""}`}
                        >
                          <i className="ti ti-info-square-rounded me-2" />
                          Basic Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={() => setActiveTab("payroll")}
                          className={`nav-link ${activeTab === "payroll" ? "active" : ""}`}
                        >
                          <i className="ti ti-file-dollar me-2" />
                          Payroll
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={() => setActiveTab("leaves")}
                          className={`nav-link ${activeTab === "leaves" ? "active" : ""}`}
                        >
                          <i className="ti ti-calendar-due me-2" />
                          Leaves
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={() => setActiveTab("attendance")}
                          className={`nav-link ${activeTab === "attendance" ? "active" : ""}`}
                        >
                          <i className="ti ti-calendar-due me-2" />
                          Attendance
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {activeTab === "basic-details" && (
                  <div className="row">
                    {/* Address */}
                    <div className="col-xxl-6 d-flex">
                      <div className="card flex-fill">
                        <div className="card-header">
                          <h5>Address</h5>
                        </div>
                        <div className="card-body">
                          <div className="d-flex align-items-center mb-3">
                            <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                              <i className="ti ti-map-pin-up" />
                            </span>
                            <div>
                              <p className="mb-1 fw-medium text-dark ">Current Address</p>
                              <p>{formData.currentAddress}</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                              <i className="ti ti-map-pins" />
                            </span>
                            <div>
                              <p className="mb-1 fw-medium text-dark ">Permanent Address</p>
                              <p>{formData.permanentAddress}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /Address */}
                    {/* Documents */}
                    <div className="col-xxl-6 d-flex">
                      <div className="card flex-fill">
                        <div className="card-header">
                          <h5>Documents</h5>
                        </div>
                        <div className="card-body">
                          <div className="bg-light-300 border rounded d-flex align-items-center justify-content-between mb-3 p-2">
                            <div className="d-flex align-items-center overflow-hidden">
                              <span className="avatar avatar-md bg-white rounded flex-shrink-0 text-default">
                                <i className="ti ti-pdf fs-15" />
                              </span>
                              <div className="ms-2">
                                <p className="text-truncate fw-medium text-dark ">Resume.pdf</p>
                              </div>
                            </div>
                            <Link to="#" className="btn btn-dark btn-icon btn-sm">
                              <i className="ti ti-download" />
                            </Link>
                          </div>
                          <div className="bg-light-300 border rounded d-flex align-items-center justify-content-between p-2">
                            <div className="d-flex align-items-center overflow-hidden">
                              <span className="avatar avatar-md bg-white rounded flex-shrink-0 text-default">
                                <i className="ti ti-pdf fs-15" />
                              </span>
                              <div className="ms-2">
                                <p className="text-truncate fw-medium text-dark ">
                                  Joining Letter.pdf
                                </p>
                              </div>
                            </div>
                            <Link to="#" className="btn btn-dark btn-icon btn-sm">
                              <i className="ti ti-download" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /Documents */}
                    {/* Bank Details */}
                    <div className="col-xxl-12 d-flex">
                      <div className="card flex-fill">
                        <div className="card-header">
                          <h5>Bank Details</h5>
                        </div>
                        <div className="card-body pb-1">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="mb-3">
                                <p className="mb-1 fw-medium text-dark ">Account Name</p>
                                <p>{formData.accountName}</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <p className="mb-1 fw-medium text-dark ">Account Number</p>
                                <p>{formData.accountNumber}</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <p className="mb-1 fw-medium text-dark ">Bank Name</p>
                                <p>{formData.bankName}</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <p className="mb-1 fw-medium text-dark ">Branch</p>
                                <p>{formData.branchName}i</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <p className="mb-1 fw-medium text-dark ">IFSC</p>
                                <p>{formData.ifscCode}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /Bank Details */}
                    {/* Other Info */}
                    <div className="col-xxl-12">
                      <div className="card">
                        <div className="card-header">
                          <h5>Other Info</h5>
                        </div>
                        <div className="card-body">
                          <p>
                            Depending on the specific needs of your organization or system,
                            additional information may be collected or tracked. It's important to
                            ensure that any data collected complies with privacy regulations and
                            policies to protect students' sensitive information.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* /Other Info */}
                  </div>
                )}
                {/* leavess tab */}
                {activeTab === "leaves" && (
                  <div className="row gx-3">
                    {userLeavesData.leaveBalances.length > 0 &&
                      userLeavesData.leaveBalances.map((leave) => (
                        <div className="col-lg-6 col-xxl-3 d-flex">
                          <div className="card flex-fill">
                            <div className="card-body">
                              <h5 className="mb-2">
                                {leave.leaveType.type_name} ({leave.totalLeaves})
                              </h5>
                              <div className="d-flex align-items-center flex-wrap">
                                <p className="border-end pe-2 me-2 mb-0">
                                  Used : {leave.usedLeaves}.
                                </p>
                                <p className="mb-0">Available : {leave.remainingLeaves}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    <div className="card">
                      {/* Leaves List */}
                      <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                        <h4 className="mb-3"> Leaves</h4>
                        <Link
                          to="#"
                          onClick={() =>
                            setEditLeaveData((prev: any) => ({
                              ...prev,
                              appliedBy: formData.first_name + " " + formData.last_name,
                            }))
                          }
                          data-bs-target="#apply_leave"
                          data-bs-toggle="modal"
                          className="btn btn-primary d-inline-flex align-items-center mb-3"
                        >
                          {" "}
                          <i className="ti ti-calendar-event me-2" />
                          Apply Leave
                        </Link>
                      </div>
                      <div className="card-body p-0 py-3">
                        <Table columns={columns} dataSource={dataSource} Selection={false} />
                      </div>
                      {/* Leaves List */}
                      {/* apply leave modal */}
                      <div className="modal fade " id="apply_leave">
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h4 className="modal-title">Apply Leave</h4>
                              <button
                                type="button"
                                className="btn-close custom-btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                <i className="ti ti-x" />
                              </button>
                            </div>
                            <form>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="mb-4">
                                      <label className="form-label">Leave Type</label>

                                      <Select
                                        onChange={(option: any) => {
                                          // console.log("🚀🚀");

                                          setEditLeaveData((prev: any) => ({
                                            ...prev,
                                            leaveTypeId: option.value,
                                            leaveTypeBalance: userLeavesData.leaveBalances.find(
                                              (l) => l.leaveTypeId === option.value
                                            ).remainingLeaves,
                                          }));
                                        }}
                                        // value={editLeaveData.leaveTypeId}
                                        className="react-select"
                                        options={leaveOptions}
                                        // onChange={(e: any) => {}}
                                      />
                                    </div>
                                    <div className="mb-4">
                                      <label className="form-label">Available Leaves</label>
                                      <input
                                        value={editLeaveData.leaveTypeBalance}
                                        disabled
                                        type="number"
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="mb-4">
                                      <label className="form-label">Leave Start Date</label>
                                      <div className="date-pic">
                                        <DatePicker
                                          value={editLeaveData.startDate}
                                          onChange={(date) =>
                                            setEditLeaveData((prev) => ({
                                              ...prev,
                                              startDate: date,
                                            }))
                                          }
                                          className="form-control datetimepicker"
                                          format={{
                                            format: "DD-MM-YYYY",
                                            type: "mask",
                                          }}
                                        />
                                        <span className="cal-icon">
                                          <i className="ti ti-calendar" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="mb-4">
                                      <label className="form-label">Leave End Date</label>
                                      <div className="date-pic">
                                        <DatePicker
                                          value={editLeaveData.endDate}
                                          onChange={(date) =>
                                            setEditLeaveData((prev) => ({
                                              ...prev,
                                              endDate: date,
                                            }))
                                          }
                                          className="form-control datetimepicker"
                                          format={{
                                            format: "DD-MM-YYYY",
                                            type: "mask",
                                          }}
                                        />
                                        <span className="cal-icon">
                                          <i className="ti ti-calendar" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="mb-4">
                                      <label className="form-label">Leave Days</label>
                                      <div className="d-flex align-items-center check-radio-group">
                                        <label className="custom-radio">
                                          <input type="radio" name="radio" />
                                          <span className="checkmark" />
                                          Full day
                                        </label>
                                        <label className="custom-radio">
                                          <input type="radio" name="radio" />
                                          <span className="checkmark" />
                                          Half Day
                                        </label>
                                      </div>
                                    </div>
                                    <div className="mb-4">
                                      <label className="form-label">No of Days</label>
                                      <input
                                        value={
                                          editLeaveData.endDate.diff(
                                            editLeaveData.startDate,
                                            "day"
                                          ) + 1
                                        }
                                        disabled
                                        type="number"
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="mb-4">
                                      <label className="form-label">Reason</label>
                                      <textarea
                                        value={editLeaveData.reason}
                                        onChange={(e) =>
                                          setEditLeaveData((prev) => ({
                                            ...prev,
                                            reason: e.target.value,
                                          }))
                                        }
                                        rows={4}
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="modal-footer">
                                      <Link
                                        to="#"
                                        className="btn btn-light me-2"
                                        data-bs-dismiss="modal"
                                      >
                                        Cancel
                                      </Link>
                                      <Link
                                        to="#"
                                        onClick={() => handleApplyLeave()}
                                        className="btn btn-primary"
                                        data-bs-dismiss="modal"
                                      >
                                        Apply Leave
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* leaves tab */}
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
        {/* Login Details */}
        <div className="modal fade" id="login_detail">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Login Details</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <div className="modal-body">
                <div className="student-detail-info">
                  <span className="student-img">
                    <ImageWithBasePath src="assets/img/teachers/teacher-01.jpg" alt="img" />
                  </span>
                  <div className="name-info">
                    <h6>
                      Teresa <span>III, A</span>
                    </h6>
                  </div>
                </div>
                <div className="table-responsive custom-table no-datatable_length">
                  <table className="table datanew">
                    <thead className="thead-light">
                      <tr>
                        <th>User Type</th>
                        <th>User Name</th>
                        <th>Password </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Teacher</td>
                        <td>teacher20</td>
                        <td>teacher@53</td>
                      </tr>
                      <tr>
                        <td>Parent</td>
                        <td>parent53</td>
                        <td>parent@53</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Login Details */}
      </>
    </div>
  );
};

export default StaffDetails;
