export interface Staff {
  // Staff Details
  firstName: string;
  lastName: string;
  role: string;
  department: string;
  designation: string;
  gender: string;
  primaryContactNumber: string;
  emailAddress: string;
  bloodGroup: string;
  dateOfJoining?: Date; // Optional
  fathersName: string;
  mothersName: string;
  dateOfBirth?: Date; // Optional
  maritalStatus: string;
  languageKnown?: string; // Optional
  currentAddress: string;
  permanentAddress: string;
  qualification: string;
  workExperience: string;
  previousSchool?: string; // Optional
  previousSchoolAddress?: string; // Optional
  previousSchoolPhoneNo?: string; // Optional
  panOrIdNumber?: string; // Optional
  status?: string; // Optional
  notes?: string; // Optional
  schoolId: number;

  // Payroll Details
  epfNo?: string; // Optional
  basicSalary?: string; // Optional
  contractType?: string; // Optional, default "Permanent"
  workShift?: string; // Optional, e.g., "Morning"
  workLocation?: string; // Optional

  // Bank Account Details
  accountName?: string; // Optional
  accountNumber?: string; // Optional
  bankName?: string; // Optional
  ifscCode?: string; // Optional
  branchName?: string; // Optional

  // Transportation Details
  route?: string; // Optional, e.g., "Denver"
  vehicleNumber?: string; // Optional
  pickupPoint?: string; // Optional

  // Hostel Details
  hostel?: string; // Optional
  roomNo?: string; // Optional

  // Social Media Details
  facebook?: string; // Optional
  twitter?: string; // Optional
  linkedin?: string; // Optional
  instagram?: string; // Optional
}

export const initialStaffValues: Staff = {
  // id: 0, // Default value for ID (or you can leave it undefined if not required at initialization)
  // userId: 0,
  firstName: "",
  lastName: "",
  role: "",
  department: "",
  designation: "",
  gender: "",
  primaryContactNumber: "",
  emailAddress: "",
  bloodGroup: "",
  dateOfJoining: undefined, // Optional field, can be left as `undefined` or set to a default date
  fathersName: "",
  mothersName: "",
  dateOfBirth: undefined, // Optional field
  maritalStatus: "",
  languageKnown: "",
  currentAddress: "",
  permanentAddress: "",
  qualification: "",
  workExperience: "",
  previousSchool: "",
  previousSchoolAddress: "",
  previousSchoolPhoneNo: "",
  panOrIdNumber: "",
  status: "Active", // Default status, as per the backend requirement
  notes: "",
  schoolId: 1, // Default to a valid school ID, adjust as necessary
  //now payroll data
  epfNo: "",
  basicSalary: "",
  contractType: "Permanent",
  workShift: "Morning",
  workLocation: "",
  // bank account detail
  accountName: "",
  accountNumber: "",
  bankName: "",
  ifscCode: "",
  branchName: "",
  //transport details
  route: "Denver",
  vehicleNumber: "",
  pickupPoint: "",
  //hostel details
  hostel: "",
  roomNo: "",
  //social media details
  facebook: "",
  twitter: "",
  linkedin: "",
  instagram: "",
};
