import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { Nullable } from "primereact/ts-helpers";
import "bootstrap-daterangepicker/daterangepicker.css";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { all_routes } from "../../router/all_routes";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Application,
  barChartConfig,
  BarChartData,
  donutChartConfig,
  DonutChartData,
  settings,
} from "./admissionDashboard.config";

import {
  getApplications,
  getBarChartData,
  getDonutChartData,
} from "./admissionDashboardService";

const AdmissionDashboard = () => {
  const routes = all_routes;

  const [donutChartData, setDonutChartData] = useState<DonutChartData[]>([]);

  const [barChartData, setBarChartData] = useState<BarChartData[]>([]);

  const [reactApplications, setReactApplications] = useState<Application[]>([]);
  const [error, setError] = useState<Nullable<Error>>(null);
  const [studentDonutChart] = useState<any>({
    chart: {
      height: 90,
      type: "donut",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },

    series: [95, 5],
    labels: ["Completed", "Pending"],
    legend: { show: false },
    colors: ["#1ABE17", "#E82646"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 100,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const fetchDashboardData = async () => {
    try {
      const [donutData, barData, applicationsData] = await Promise.all([
        getDonutChartData(),
        getBarChartData(),
        getApplications(),
      ]);

      setDonutChartData(donutData);
      setBarChartData(barData);
      setReactApplications(applicationsData);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setError(error as Error);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  if (error) {
    return <div>Error fetching dashboard data: {error.message}</div>;
  }

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <>
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Admission Dashboard</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Admission Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-md-12">
                {/* Dashboard Content */}
                <div className="card bg-dark">
                  <div className="overlay-img">
                    <ImageWithBasePath
                      src="assets/img/bg/shape-04.png"
                      alt="img"
                      className="img-fluid shape-01"
                    />
                    <ImageWithBasePath
                      src="assets/img/bg/shape-01.png"
                      alt="img"
                      className="img-fluid shape-02"
                    />
                    <ImageWithBasePath
                      src="assets/img/bg/shape-02.png"
                      alt="img"
                      className="img-fluid shape-03"
                    />
                    <ImageWithBasePath
                      src="assets/img/bg/shape-03.png"
                      alt="img"
                      className="img-fluid shape-04"
                    />
                  </div>
                  <div className="card-body">
                    <div className="d-flex align-items-xl-center justify-content-xl-between flex-xl-row flex-column">
                      <div className="mb-3 mb-xl-0">
                        <div className="d-flex align-items-center flex-wrap mb-2">
                          <h1 className="text-white me-2">
                            Welcome Back, Mr. Hemant
                          </h1>
                        </div>
                        <p className="text-white">Have a Good day at work</p>
                      </div>
                      <p className="text-white custom-text-white">.</p>
                    </div>
                  </div>
                </div>
                {/* /Dashboard Content */}
              </div>
            </div>
            <div className="row">
              {/* Total Students */}
              <div className="col-xxl-4 col-sm-6 d-flex">
                <div className="card flex-fill animate-card border-0">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="avatar avatar-xl bg-danger-transparent me-2 p-1">
                        <ImageWithBasePath
                          src="assets/img/icons/student.svg"
                          alt="img"
                        />
                      </div>
                      <div className="overflow-hidden flex-fill">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="counter">
                            <CountUp end={3654} />
                          </h2>
                          <span className="badge bg-danger">1.2%</span>
                        </div>
                        <p>Total Admission Enquiry</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between border-top mt-3 pt-3">
                      <p className="mb-0">
                        Active :{" "}
                        <span className="text-dark fw-semibold">3643</span>
                      </p>
                      <span className="text-light">|</span>
                      <p>
                        Inactive :{" "}
                        <span className="text-dark fw-semibold">11</span>
                      </p>
                      <p>
                        Rejected :{" "}
                        <span className="text-dark fw-semibold">11</span>
                      </p>
                    </div>
                    <div className="row align-items-center justify-content-between">
                      <div className="col-sm-5">
                        <div
                          id="plan_chart"
                          className="mb-3 mb-sm-0 text-center text-sm-start"
                        ></div>
                        <ReactApexChart
                          id="plan_chart"
                          className="mb-3 mb-sm-0 text-center text-sm-start"
                          options={studentDonutChart}
                          series={studentDonutChart.series}
                          type="donut"
                          height={90}
                        />
                      </div>
                      <div className="col-sm-7">
                        <div className=" text-center text-sm-start">
                          <h4 className="mb-3">Syllabus</h4>
                          <p className="mb-2">
                            <i className="ti ti-circle-filled text-success me-1" />
                            Completed : <span className="fw-semibold">95%</span>
                          </p>
                          <p>
                            <i className="ti ti-circle-filled text-danger me-1" />
                            Pending :<span className="fw-semibold">5%</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-8 col-md-12 d-flex flex-column">
                {/* Quick Links */}
                <div className="card flex-fill">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <h4 className="card-title">Quick Links</h4>
                  </div>
                  <div className="card-body pb-1">
                    <Slider {...settings} className="owl-carousel link-slider">
                      <div className="item">
                        <Link
                          to={routes.classTimetable}
                          className="d-block bg-success-transparent ronded p-2 text-center mb-3 class-hover"
                        >
                          <div className="avatar avatar-lg border p-1 border-success rounded-circle mb-2">
                            <span className="d-inline-flex align-items-center justify-content-center w-100 h-100 bg-success rounded-circle">
                              <i className="ti ti-calendar" />
                            </span>
                          </div>
                          <p className="text-dark">Calendar</p>
                        </Link>
                        <Link
                          to={routes.feesGroup}
                          className="d-block bg-secondary-transparent ronded p-2 text-center mb-3 class-hover"
                        >
                          <div className="avatar avatar-lg border p-1 border-secondary rounded-circle mb-2">
                            <span className="d-inline-flex align-items-center justify-content-center w-100 h-100 bg-secondary rounded-circle">
                              <i className="ti ti-license" />
                            </span>
                          </div>
                          <p className="text-dark">Approved</p>
                        </Link>
                      </div>
                      <div className="item">
                        <Link
                          to={routes.examResult}
                          className="d-block bg-primary-transparent ronded p-2 text-center mb-3 class-hover"
                        >
                          <div className="avatar avatar-lg border p-1 border-primary rounded-circle mb-2">
                            <span className="d-inline-flex align-items-center justify-content-center w-100 h-100 bg-primary rounded-circle">
                              <i className="ti ti-hexagonal-prism" />
                            </span>
                          </div>
                          <p className="text-dark">Pending</p>
                        </Link>
                        <Link
                          to={routes.classHomeWork}
                          className="d-block bg-danger-transparent ronded p-2 text-center mb-3 class-hover"
                        >
                          <div className="avatar avatar-lg border p-1 border-danger rounded-circle mb-2">
                            <span className="d-inline-flex align-items-center justify-content-center w-100 h-100 bg-danger rounded-circle">
                              <i className="ti ti-report-money" />
                            </span>
                          </div>
                          <p className="text-dark">Rejected</p>
                        </Link>
                      </div>
                      <div className="item">
                        <Link
                          to={routes.studentAttendance}
                          className="d-block bg-warning-transparent ronded p-2 text-center mb-3 class-hover"
                        >
                          <div className="avatar avatar-lg border p-1 border-warning rounded-circle mb-2">
                            <span className="d-inline-flex align-items-center justify-content-center w-100 h-100 bg-warning rounded-circle">
                              <i className="ti ti-calendar-share" />
                            </span>
                          </div>
                          <p className="text-dark">All Applications</p>
                        </Link>
                        <Link
                          to={routes.attendanceReport}
                          className="d-block bg-skyblue-transparent ronded p-2 text-center mb-3 class-hover"
                        >
                          <div className="avatar avatar-lg border p-1 border-skyblue rounded-circle mb-2">
                            <span className="d-inline-flex align-items-center justify-content-center w-100 h-100 bg-pending rounded-circle">
                              <i className="ti ti-file-pencil" />
                            </span>
                          </div>
                          <p className="text-dark">Reports</p>
                        </Link>
                      </div>
                    </Slider>
                  </div>
                </div>
                {/* /Quick Links */}
              </div>
              {/* /Total Students */}
              {/* Total Teachers */}
              {/* <div className="col-xxl-12 col-sm-6 d-flex">
                <div className="card flex-fill animate-card border-0">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="avatar avatar-xl me-2 bg-secondary-transparent p-1">
                        <ImageWithBasePath
                          src="assets/img/icons/teacher.svg"
                          alt="img"
                        />
                      </div>
                      <div className="overflow-hidden flex-fill">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="counter">
                            <CountUp end={284} />
                          </h2>
                          <span className="badge bg-pending">1.2%</span>
                        </div>
                        <p>Total Admissions</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between border-top mt-3 pt-3">
                      <p className="mb-0">
                        Approved :{" "}
                        <span className="text-dark fw-semibold">254</span>
                      </p>
                      <span className="text-light">|</span>
                      <p>
                        Pending :{" "}
                        <span className="text-dark fw-semibold">30</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* /Total Teachers */}
            </div>
            <div className="row">
              {/* Attendance */}
              <div className="col-xxl-6 col-xl-6 col-md-12 d-flex flex-column">
                <div className="card">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <h4 className="card-title">Admissions</h4>
                    <div className="dropdown">
                      <Link
                        to="#"
                        className="bg-white dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <i className="ti ti-calendar-due me-1" />
                        Today
                      </Link>
                      <ul className="dropdown-menu mt-2 p-3">
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            This Week
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            Last Week
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            Last 4 weeks
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="list-tab mb-4">
                      <ul className="nav">
                        {donutChartData.map((dChart, index) => (
                          <li key={index}>
                            <Link
                              to="#"
                              className={index === 0 ? "active" : ""}
                              data-bs-toggle="tab"
                              data-bs-target={`#${dChart.name.replace(
                                /\s+/g,
                                "-"
                              )}`}
                            >
                              {dChart.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="tab-content">
                      {donutChartData.map((dChart, index) => (
                        <div
                          className={`tab-pane fade ${
                            index === 0 ? "active show" : ""
                          }`}
                          id={dChart.name.replace(/\s+/g, "-")}
                          key={index}
                        >
                          <div className="row gx-3">
                            {dChart.labels.map((item, index) => (
                              <div className="col-sm-4" key={index}>
                                <div className="card bg-light-300 shadow-none border-0">
                                  <div className="card-body p-3 text-center">
                                    <h5>{dChart.data[index]}</h5>
                                    <p className="fs-12">{item}</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="text-center">
                            <ReactApexChart
                              id="student-chart"
                              className="mb-4"
                              options={{
                                ...donutChartConfig,
                                labels: dChart.labels,
                              }}
                              series={dChart.data}
                              type="donut"
                              height={210}
                            />
                            <Link
                              to={routes.admissionList}
                              className="btn btn-light"
                            >
                              <i className="ti ti-calendar-share me-1" />
                              View All
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* /Attendance */}

              {/* Notice Board */}
              <div className="col-xxl-6 col-md-12 d-flex flex-column">
                <div className="card flex-fill">
                  <div className="card-header  d-flex align-items-center justify-content-between">
                    <h4 className="card-title">Recent Applications</h4>
                    <Link to={routes.admissionList} className="fw-medium">
                      View All
                    </Link>
                  </div>
                  <div className="card-body">
                    <div className="notice-widget">
                      {reactApplications.map((application, index) => (
                        <div
                          className="d-sm-flex align-items-center justify-content-between mb-4"
                          key={index}
                        >
                          <div className="d-flex align-items-center overflow-hidden me-2 mb-2 mb-sm-0">
                            <span
                              className={`bg-${
                                index % 5 === 0
                                  ? "primary"
                                  : index % 5 === 1
                                  ? "success"
                                  : index % 5 === 2
                                  ? "danger"
                                  : index % 5 === 3
                                  ? "skyblue"
                                  : "warning"
                              }-transparent avatar avatar-md me-2 rounded-circle flex-shrink-0`}
                            >
                              <i
                                className={`ti ${
                                  index % 5 === 0
                                    ? "ti-books"
                                    : index % 5 === 1
                                    ? "ti-note"
                                    : index % 5 === 2
                                    ? "ti-bell-check"
                                    : index % 5 === 3
                                    ? "ti-notes"
                                    : "ti-package"
                                } fs-16`}
                              />
                            </span>
                            <div className="overflow-hidden">
                              <h6 className="text-truncate mb-1">
                                {application.name}
                              </h6>
                              <p>
                                <i className="ti ti-calendar me-2" />
                                Requested on : {application.requestedOn}
                              </p>
                            </div>
                          </div>
                          <span className="badge bg-light text-dark">
                            <i className="ti ti-clck me-1" />
                            {application.class}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* /Notice Board */}
            </div>
            <div className="row">
              {/* Fees Collection */}
              <div className="col-xxl-12 col-xl-6 d-flex">
                <div className="card flex-fill">
                  <div className="card-header  d-flex align-items-center justify-content-between">
                    <h4 className="card-title">Admission Trends</h4>
                    <div className="dropdown">
                      <Link
                        to="#"
                        className="bg-white dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <i className="ti ti-calendar  me-2" />
                        Last 8 Quater
                      </Link>
                      <ul className="dropdown-menu mt-2 p-3">
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            This Month
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            This Year
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            Last 12 Quater
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            Last 16 Quater
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-body pb-0">
                    <ReactApexChart
                      id="fees-chart"
                      options={barChartConfig}
                      series={barChartData}
                      type="bar"
                      height={270}
                    />
                  </div>
                </div>
              </div>
              {/* /Fees Collection */}
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default AdmissionDashboard;
