import axios from "axios";
import axiosInstance, { del, get, post } from "../../../auth/axiosInstance";
import dayjs from "dayjs";
import { Student } from "./student.model";

const basepath = "/student";

export const getStudentDetails = async (
  schoolId: number,
  admissionId: number
) => {
  try {
    const response = await axiosInstance.get(
      `${basepath}/${schoolId}/${admissionId}`
    );
    console.log("School ID", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching student details:", error);
  }
};

export const getAllStudents = async () => {
  try {
    const response = await get(`/${basepath}/all`);
    return response.data;
  } catch (error) {
    console.error("Error fetching all students:", error);
    throw error;
  }
};

export const getAllStudentsBySchool = async (schoolId: number) => {
  if (!schoolId) return;
  try {
    const response = await get(`/${basepath}/school/${schoolId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching students by school:", error);
    throw error;
  }
};

export const addStudent = async (studentData: Student) => {
  try {
    const response = await post(`/${basepath}/create`, studentData);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateStudent = async (
  admissionId: string,
  studentData: Student
) => {
  try {
    const response = await axiosInstance.put(
      `${basepath}/update/${admissionId}`,
      studentData
    );
    return response.data;
  } catch (error) {
    console.error("Error updating student:", error);
    throw error;
  }
};

export const deleteStudent = async (schoolId: number, admissionId: string) => {
  try {
    const response = await del(
      `/${basepath}/delete/${schoolId}/${admissionId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting student:", error);
    throw error;
  }
};

export const mapStudentForSubmission = (rawStudent: any) => {
  return {
    firstName: rawStudent.firstName,
    lastName: rawStudent.lastName,
    email: rawStudent.email,
    dateOfBirth: rawStudent.dateOfBirth
      ? dayjs(rawStudent.dateOfBirth, "DD-MM-YYYY").format("DD-MM-YYYY")
      : "",
    contactNumber: rawStudent.contactNumber,
    classId: rawStudent.classId.value ?? rawStudent.classId,
    section: rawStudent.section.value ?? rawStudent.section,
    schoolId: rawStudent.schoolId,
    gender: rawStudent.gender.value ?? rawStudent.gender,
    bloodGroup: rawStudent.bloodGroup.value ?? rawStudent.bloodGroup,
    religion: rawStudent.religion.value ?? rawStudent.religion,
    caste: rawStudent.caste,
    category: rawStudent.category.value ?? rawStudent.category,
    motherTongue: rawStudent.motherTongue.value ?? rawStudent.motherTongue,
    languages: [],
    admissionId: rawStudent.admissionId,
    admissionDate: rawStudent.admissionDate
      ? dayjs(rawStudent.admissionDate, "DD-MM-YYYY").format("DD-MM-YYYY")
      : "",
    academicYear: rawStudent.academicYear.value ?? rawStudent.academicYear,
    status: rawStudent.status.value ?? rawStudent.status,
    rollNumber: rawStudent.rollNumber,
    fatherName: rawStudent.fatherName,
    motherName: rawStudent.motherName,
    fatherEmail: rawStudent.fatherEmail,
    motherEmail: rawStudent.motherEmail,
    fatherContactNumber: rawStudent.fatherContactNumber,
    motherContactNumber: rawStudent.motherContactNumber,
    fatherOccupation: rawStudent.fatherOccupation,
    motherOccupation: rawStudent.motherOccupation,
    guardianName: rawStudent.guardianName,
    guardianContactNumber: rawStudent.guardianContactNumber,
    guardianRelation: rawStudent.guardianRelation,
    guardianEmail: rawStudent.guardianEmail,
    guardianOccupation: rawStudent.guardianOccupation,
    guardianAddress: rawStudent.guardianAddress,
    currentAddress: rawStudent.currentAddress,
    permanentAddress: rawStudent.permanentAddress,
    totalFees: rawStudent.totalFees,
    feesPaid: rawStudent.feesPaid,
    feesDue: rawStudent.feesDue,
    feesDiscount: rawStudent.feesDiscount,
    feeJson: rawStudent.feeJson,
  };
};
