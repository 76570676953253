import { get } from "../../auth/axiosInstance";
import {
  Application,
  BarChartData,
  DonutChartData,
} from "./admissionDashboard.config";

const basepath = "/admissionApplication";

export const getDonutChartData = async (): Promise<DonutChartData[]> => {
  try {
    const response = await get(`${basepath}/donut-chart/1`);
    return response.data;
  } catch (error) {
    console.error("Error fetching donut chart data:", error);
    throw error;
  }
};

export const getBarChartData = async (): Promise<BarChartData[]> => {
  try {
    const response = await get(`${basepath}/bar-chart/1`);
    return response.data;
  } catch (error) {
    console.error("Error fetching bar chart data:", error);
    throw error;
  }
};

export const getApplications = async (): Promise<Application[]> => {
  try {
    const response = await get(`${basepath}/recent-applications/1`);
    return response.data;
  } catch (error) {
    console.error("Error fetching applications:", error);
    throw error;
  }
};
