import { Button } from "antd";
import React from "react";
import * as XLSX from "xlsx";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axiosInstance from "../../feature-module/auth/axiosInstance";
const TooltipOption = (props: any) => {
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const handleDownloadFormat = () => {
    const excelTemplate = props.excelFormat;
    // console.log("🤣", excelTemplate);
    const worksheet = XLSX.utils.json_to_sheet(excelTemplate);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, worksheet, "Sheet1");
    XLSX.writeFile(workBook, "Format.xlsx");
  };
  function excelDateToJSDate(serial: any) {
    if (typeof serial !== "number" || isNaN(serial)) {
      console.error("Invalid Excel serial date:", serial);
      return null; // Return null or a default date
    }

    // Adjust for Excel leap year bug
    const excelEpoch = new Date(Date.UTC(1899, 11, 30)); // December 30, 1899
    const jsDate = new Date(excelEpoch.getTime() + serial * 86400000); // 86400000 ms per day

    return jsDate;
  }
  const handleExportToExcel = async () => {
    const data = props.list;
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Data.xlsx");
  };
  const handleImportData = async (e: any) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const wb = XLSX.read(data);
    const ws = wb.Sheets[wb.SheetNames[0]];
    let jsonData: any = XLSX.utils.sheet_to_json(ws).map((row: any) => ({ ...row, schoolId }));
    // transform status enum
    if (jsonData && jsonData[0].status === "") {
      jsonData = jsonData.map((d: any) => ({
        ...d,
        status: d.status && d.status.trim() ? d.status : "Active",
      }));
    }
    // transform dob and date of joining
    if (jsonData && jsonData[0].dateOfBirth) {
      jsonData = jsonData.map((d: any) => ({
        ...d,
        dateOfBirth: excelDateToJSDate(d.dateOfBirth),
      }));
    }
    if (jsonData && jsonData[0].dateOfJoining) {
      jsonData = jsonData.map((d: any) => ({
        ...d,
        dateOfJoining: excelDateToJSDate(d.dateOfJoining),
      }));
    }
    console.log("🤣🤣", jsonData);

    const url = props.url;
    const res = await axiosInstance.post(url, jsonData);
    console.log(res);
  };
  return (
    <>
      <div className="pe-1 mb-2">
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Refresh</Tooltip>}>
          <Link to="#" className="btn btn-outline-light bg-white btn-icon me-1">
            <i className="ti ti-refresh" />
          </Link>
        </OverlayTrigger>
      </div>
      <div className="pe-1 mb-2">
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Print</Tooltip>}>
          <button type="button" className="btn btn-outline-light bg-white btn-icon me-1">
            <i className="ti ti-printer" />
          </button>
        </OverlayTrigger>
      </div>
      <div className="dropdown me-2 mb-2">
        <Link
          to="#"
          className="dropdown-toggle btn btn-light fw-medium d-inline-flex align-items-center"
          data-bs-toggle="dropdown"
        >
          <i className="ti ti-file-import me-2" />
          Import
        </Link>
        <ul className="dropdown-menu  dropdown-menu-end p-3">
          <li>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#import-modal"
              className="dropdown-item rounded-1"
            >
              <i className="ti ti-file-type-xls me-1" />
              Import as Excel{" "}
            </Link>
          </li>
        </ul>
      </div>
      <div className="dropdown me-2 mb-2">
        <Link
          to="#"
          className="dropdown-toggle btn btn-light fw-medium d-inline-flex align-items-center"
          data-bs-toggle="dropdown"
        >
          <i className="ti ti-file-export me-2" />
          Export
        </Link>
        <ul className="dropdown-menu  dropdown-menu-end p-3">
          {/* <li>
            <Link to="#" className="dropdown-item rounded-1">
              <i className="ti ti-file-type-pdf me-1" />
              Export as PDF
            </Link>
          </li> */}
          <li>
            <Link onClick={() => handleExportToExcel()} to="#" className="dropdown-item rounded-1">
              <i className="ti ti-file-type-xls me-1" />
              Export as Excel{" "}
            </Link>
          </li>
        </ul>
      </div>
      {/* import modal */}
      <div className="modal fade " id="import-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Import </h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <div className="modal-body d-flex gap-5">
              <Link onClick={() => handleDownloadFormat()} to="#" className="btn btn-primary">
                Download Format
              </Link>
              <label className="btn btn-primary">
                {" "}
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept=".xlsx, .xlw, .csv"
                  onChange={(e) => handleImportData(e)}
                />
                Import Excel File
              </label>
            </div>
            <div className="modal-footer">
              Note: Please download the formatted Excel sheet to ensure your data matches the
              required structure before importing.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TooltipOption;
