import axios from "axios";
import axiosInstance from "../axiosInstance";

interface AuthResponse {
  accessToken?: string;
  message?: string;
  user: {
    accessToken: string;
    user: { email: string; id: number };
    message: string;
    email?: string;
    schoolId: number;
    id?: Number;
  };
  // Add other user properties if needed
}
export const authenticate = async (email: String, password: String) => {
  try {
    const response = await axiosInstance.post("/auth/login", JSON.stringify({ email, password }));
    if (!(response.status === 200)) {
      throw new Error("Authentication failed");
    }

    const data: AuthResponse = await response.data;
    if (data.user.email) {
      localStorage.setItem("accessToken", JSON.stringify(data.accessToken || ""));
      localStorage.setItem("userDetails", JSON.stringify(data.user));
      return data.user; // User is authenticated, return the email
    } else {
      return false; // User is not authenticated
    }
  } catch (err) {
    //  console.error(err);
    // setError('Authentication failed. Please try again.');
    return false;
  }
};
