import React, { useEffect, useRef, useState } from "react";
import { classRoom } from "../../core/data/json/class-room";
import Table from "../../core/common/dataTable/index";
import PredefinedDateRanges from "../../core/common/datePicker";
import {
  capacitycount,
  count,
} from "../../core/common/selectoption/selectoption";
import CommonSelect from "../../core/common/commonSelect";
import { TableData } from "../../core/data/interface";
import { Link } from "react-router-dom";
import TooltipOption from "../../core/common/tooltipOption";
import { all_routes } from "../router/all_routes";
import axiosInstance from "../auth/axiosInstance";
import Select from "react-select";

const AdmissionList = () => {
  const routes = all_routes;
  const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const [admissionList, setAdmissionList] = useState([]);
  const [selectedAdmissionRequest, setSelectAdmissionRequest] = useState({
    id: "",
    applicationId: "",
    emailId: "",
    contactNumber: "",
    class: "",
    dob: "",
    name: "",
    parentName: "",
    status: "",
    schoolId: userDetails.schoolId,
  });
  const [admissionRequestDetails, setAdmissionRequestDetails] = useState({
    emailId: "",
    contactNumber: "",
    class: "",
    dob: "",
    name: "",
    parentName: "",
    status: "new",
    schoolId: userDetails.schoolId,
    // Add other necessary fields
  });

  const options = [
    { value: 'new', label: 'new' },
    { value: 'intreview', label: 'intreview' },
    { value: 'accepted', label: 'accepted' },
    { value: 'declined', label: 'declined' }
  ]

  const data = classRoom;
  const columns = [
    {
      title: "Application ID",
      dataIndex: "applicationId",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.applicationId}
          </Link>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },

    {
      title: "Student Name",
      dataIndex: "name",
      sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },
    {
      title: "Parent Email",
      dataIndex: "emailId",
      sorter: (a: TableData, b: TableData) =>
        a.emailId.length - b.emailId.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a: any, b: any) => a.status.length - b.status.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: string, record: any) => (
        <>
          <div className="dropdown">
            <Link
              to="#"
              className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="ti ti-dots-vertical fs-14" />
            </Link>
            <ul className="dropdown-menu dropdown-menu-right p-3">
            
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#edit_class_room"
                  onClick={() => {
                    setSelectAdmissionRequest({
                      id: record.id,
                      emailId: record.emailId,
                      contactNumber: record.contactNumber,
                      class: record.class,
                      dob: record.dob,
                      name: record.name,
                      parentName: record.parentName,
                      status: record.status,
                      applicationId: record.applicationId,
                      schoolId:record.schoolId,
                    });
                  }}
                >
                  <i className="ti ti-edit-circle me-2" />
                  Edit
                </Link>
              </li>
             
            </ul>
          </div>
        </>
      ),
    },
  ];
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };

  const fetchAdmissionList = async () => {
    try {
      const response = await axiosInstance.get("/admissionApplication", {
        params: {
          schoolId: userDetails.schoolId,
        },
      });
      // console.log(response.data);
      const transformedData = response.data.map((item: any, idx: number) => ({
        id: item.id,
        emailId: item.emailId,
        contactNumber: item.contactNumber,
        class: item.class,
        dob: item.dob,
        name: item.name,
        parentName: item.parentName,
        status: item.status,
        key: item.id,
        applicationId: item.applicationId,
      }));
      console.log(transformedData);

      if (transformedData.length > 0) {
        setAdmissionList(transformedData);
      }
    } catch (error) {
      console.error("Error fetching staff list:", error);
    }
  };

  useEffect(() => {
    fetchAdmissionList();
  }, []);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setAdmissionRequestDetails({
      ...admissionRequestDetails,
      [name]: value,
    });
  };

  const handleAddAdmissionRequest = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault(); // Prevent the default link behavior
    try {
      const response = await axiosInstance.post(
        "/admissionApplication",
        admissionRequestDetails
      );
      console.log("Application added successfully:", response.data);
    } catch (error) {
      console.error("Error adding homework:", error);
    }
  };

  const handleEditAdmissionApplication = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault(); // Prevent the default link behavior
    try {
      const response = await axiosInstance.put(
        `/admissionApplication/` + selectedAdmissionRequest.id,
        selectedAdmissionRequest
      );
      console.log("Application edited successfully:", response.data);
    } catch (error) {
      console.error("Error adding homework:", error);
    }
  };

  const handleDeleteHomework = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault(); // Prevent the default link behavior
    try {
      const response = await axiosInstance.delete(
        "/homework/" + selectedAdmissionRequest.id
      );
      console.log("Homework added successfully:", response.data);
    } catch (error) {
      console.error("Error adding homework:", error);
    }
  };

  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Admission Request</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>

                    <li className="breadcrumb-item active" aria-current="page">
                      Admission
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                <TooltipOption />
                <div className="mb-2">
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_class_room"
                  >
                    <i className="ti ti-square-rounded-plus-filled me-2" />
                    Add Admission Request
                  </Link>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Guardians List */}
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                <h4 className="mb-3">Admission Requests</h4>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="input-icon-start mb-3 me-2 position-relative">
                    <PredefinedDateRanges />
                  </div>
                  <div className="dropdown mb-3 me-2">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      <i className="ti ti-filter me-2" />
                      Filter
                    </Link>
                    <div
                      className="dropdown-menu drop-width"
                      ref={dropdownMenuRef}
                    >
                      <form>
                        <div className="d-flex align-items-center border-bottom p-3">
                          <h4>Filter</h4>
                        </div>
                        <div className="p-3 border-bottom pb-0">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Room No</label>
                                <CommonSelect
                                  className="select"
                                  options={count}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Capacity</label>
                                <CommonSelect
                                  className="select"
                                  options={capacitycount}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-3 d-flex align-items-center justify-content-end">
                          <Link to="#" className="btn btn-light me-3">
                            Reset
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={handleApplyClick}
                          >
                            Apply
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="dropdown mb-3">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-sort-ascending-2 me-2" />
                      Sort by A-Z
                    </Link>
                    <ul className="dropdown-menu p-3">
                      <li>
                        <Link to="#" className="dropdown-item rounded-1 active">
                          Ascending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Descending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Viewed
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Added
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-body p-0 py-3">
                {/* Guardians List */}
                <Table
                  columns={columns}
                  dataSource={admissionList}
                  Selection={true}
                />

                {/* /Guardians List */}
              </div>
            </div>
            {/* /Guardians List */}
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
      <div>
        {/* Add Class Room */}
        <div className="modal fade" id="add_class_room">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Admission Request</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Student Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={admissionRequestDetails.name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Student Date of Birth
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="dob"
                          value={admissionRequestDetails.dob}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Class Want to Apply
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="class"
                          value={admissionRequestDetails.class}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Parent Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="parentName"
                          value={admissionRequestDetails.parentName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Parent Email</label>
                        <input
                          type="text"
                          className="form-control"
                          name="emailId"
                          value={admissionRequestDetails.emailId}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Parent Contact Number
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="contactNumber"
                          value={admissionRequestDetails.contactNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={handleAddAdmissionRequest}
                  >
                    Add Admission Request
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Add Class Room */}
        {/* Edit Class Room */}
        <div className="modal fade" id="edit_class_room">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Admission Application Status</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Student Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Name"
                          value={selectedAdmissionRequest.name}
                          readOnly
                          onChange={(e)=>{
                            setSelectAdmissionRequest({...selectedAdmissionRequest,name:e.target.value})
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Date of Birth</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Enter Date of Birth"
                          value={selectedAdmissionRequest.dob.split("T")[0]}
                          readOnly
                          onChange={(e)=>{
                            setSelectAdmissionRequest({...selectedAdmissionRequest,dob:e.target.value})
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Class</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Class"
                          value={selectedAdmissionRequest.class}
                          readOnly
                          onChange={(e)=>{
                            setSelectAdmissionRequest({...selectedAdmissionRequest,class:e.target.value})
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Parent Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Parent Name"
                          value={selectedAdmissionRequest.parentName}
                          readOnly
                          onChange={(e)=>{
                            setSelectAdmissionRequest({...selectedAdmissionRequest,parentName:e.target.value})
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Parent Email</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Class"
                          value={selectedAdmissionRequest.emailId}
                          readOnly
                          onChange={(e)=>{
                            setSelectAdmissionRequest({...selectedAdmissionRequest,emailId:e.target.value})
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Parent Contact Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Parent Name"
                          value={selectedAdmissionRequest.contactNumber}
                          readOnly
                          onChange={(e)=>{
                            setSelectAdmissionRequest({...selectedAdmissionRequest,contactNumber:e.target.value})
                          }}
                        />
                      </div>
                      <div className="mb-3"> <label className="form-label">Parent Contact Number</label>
                        <Select
                                className="react-select"
                                options={options}
                                placeholder="Select"
                                value={ {label:selectedAdmissionRequest.status,value:selectedAdmissionRequest.status} }
                                onChange={(value: any) => {
                                  setSelectAdmissionRequest({ ...selectedAdmissionRequest, status: value?.value || ""});
                                }}
                              />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={handleEditAdmissionApplication}
                  >
                    Save Changes
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Edit Class Room */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirm Deletion</h4>
                  <p>
                    You want to delete all the marked items, this cant be undone
                    once you delete.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      onClick={handleDeleteHomework}
                    >
                      Yes, Delete
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Delete Modal */}
      </div>
    </div>
  );
};

export default AdmissionList;
