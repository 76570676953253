// index.tsx
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { DatatableProps } from "../../data/interface"; // Ensure correct path

// Optional custom styles
const customStyles = `
  

  

  .rowClassName1 td {
    border: 2px solid black; /* black border */
  }
`;

const Datatable: React.FC<DatatableProps> = ({
  columns,
  dataSource,
  Selection,
  notRequirePagination,
  useCustomStyles = false,
  size = "large", // Default value for optional prop
  footer = "",
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [Selections, setSelections] = useState<any>(true);
  const [filteredDataSource, setFilteredDataSource] = useState<any[]>(dataSource);

  const onSelectChange = (newSelectedRowKeys: any[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    const filteredData = dataSource.filter((record) =>
      Object.values(record).some((field) =>
        String(field).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredDataSource(filteredData);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    return setSelections(Selection);
  }, [Selection]);

  useEffect(() => {
    setFilteredDataSource(dataSource);
  }, [dataSource]);

  return (
    <>
      {useCustomStyles && <style>{customStyles}</style>} {/* Conditionally apply styles */}
      <div className="table-top-data d-flex px-3 justify-content-between">
        <div className="page-range"></div>
        <div className="serch-global text-right">
          <input
            type="search"
            className="form-control form-control-sm mb-3 w-auto float-end"
            value={searchText}
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            aria-controls="DataTables_Table_0"
          />
        </div>
      </div>
      {!Selections ? (
        <Table
          size={size}
          className="table datanew dataTable no-footer"
          columns={columns}
          rowClassName="rowClassName1"
          rowHoverable={true}
          footer={footer}
          dataSource={filteredDataSource}
          pagination={
            !notRequirePagination
              ? {
                  locale: { items_per_page: "" },
                  nextIcon: <span>Next</span>,
                  prevIcon: <span>Prev</span>,
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30"],
                }
              : false
          }
        />
      ) : (
        <Table
          className="table datanew dataTable no-footer"
          rowSelection={rowSelection}
          columns={columns}
          rowHoverable={true}
          dataSource={filteredDataSource}
          footer={footer}
          pagination={
            !notRequirePagination
              ? {
                  locale: { items_per_page: "" },
                  nextIcon: <span>Next</span>,
                  prevIcon: <span>Prev</span>,
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30"],
                }
              : false
          }
        />
      )}
    </>
  );
};

export default Datatable;
