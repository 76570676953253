import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import Select from "react-select";
import {
  feeGroup,
  feesTypes,
  leaveType,
  paymentType,
} from "../../../core/common/selectoption/selectoption";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import CommonSelect from "../../../core/common/commonSelect";
import { FaTrash, FaPlus } from "react-icons/fa"; // Import a trash icon from react-icons
import axiosInstance from "../../auth/axiosInstance";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { set } from "react-hook-form";
import { on } from "events";
import { log } from "console";
import AdmitCardPreview from "./student-details/AdmitCard";

const StudentModals = () => {
  const navigate = useNavigate();
  const routes = all_routes;
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${month}-${day}-${year}`;
  const defaultValue = dayjs(formattedDate);
  const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const [categories, setCategories] = useState<{ label: string; value: string }[]>([]);
  const [subCategoriesList, setSubCategoryList] = useState<
    { label: string; value: string; categoryId: string }[]
  >([]);
  const getModalContainer = () => {
    const modalElement = document.getElementById("modal-datepicker");
    return modalElement ? modalElement : document.body; // Fallback to document.body if modalElement is null
  };

  const studentFromStore = useSelector((state: any) => state.studentSlice);

  console.log("1️⃣1️⃣1️⃣12", studentFromStore);

  useEffect(() => {
    if (studentFromStore && studentFromStore.feeJson) {
      console.log("1️⃣1️⃣1️⃣121111", studentFromStore.feeJson);
      for (let i = 0; i < studentFromStore.feeJson.items.length; i++) {
        setCategories([
          ...categories,
          {
            label: studentFromStore.feeJson.items[i].categoryName,
            value: studentFromStore.feeJson.items[i].categoryId,
          },
        ]);
        setSubCategoryList([
          ...subCategoriesList,
          {
            label: studentFromStore.feeJson.items[i].subCategoryName,
            value: studentFromStore.feeJson.items[i].subCategoryId,
            categoryId: studentFromStore.feeJson.items[i].categoryId,
          },
        ]);
      }
    }
  }, []);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [feesOptions, setFeesOptions] = useState([]);
  const getPaymentOptions = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/ledger/school/" + schoolId + "/category/payment");
    console.log(res.data);
    setPaymentMethods(res.data.map((p: any) => ({ value: p.id, label: p.name })));
  };
  const getFeesOptions = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/ledger/school/" + schoolId + "/category/feesType");
    console.log(res.data);
    setFeesOptions(res.data.map((p: any) => ({ value: p.id, label: p.name })));
  };

  const [feeData, setFeeData] = useState({
    id: studentFromStore?.id,
    type: "credit",
    invoiceNo: "",
    description: "",
    feesType: "tuition",
    date: "",
    name: studentFromStore.firstName + " " + studentFromStore.lastName,
    admissionId: studentFromStore?.admissionId,
    class: studentFromStore?.class?.name + " " + studentFromStore?.section,
    customerAddress: "",
    items: [
      {
        title: "",
        amount: 0,
        ledgerId: 0,
        categoryName: "",
        subCategoryName: "",
        categoryId: "",
        subCategoryId: "",
      },
    ],
    subtotal: 0,
    tax: 0,
    amount: 0,
    subTotal: 0,
    discount: 0,
    mobile: studentFromStore.contactNumber,
    email: studentFromStore?.email,
    address: studentFromStore.permanentAddress,
    paymentMethodId: "",
    categoryName: "",
    subCategoryName: "",
    categoryID: "",
    subCategoryID: "",
    userId: userDetails.id,
    schoolId: userDetails.schoolId,
    requestType: "fees",
  });

  const handleAddFeesType = () => {
    setFeeData({
      ...feeData,
      items: [
        ...feeData.items,
        {
          title: "",
          amount: 0,
          ledgerId: 0,
          categoryId: "",
          subCategoryId: "",
          subCategoryName: "",
          categoryName: "",
        },
      ],
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFeeData({
      ...feeData,
      [name]: parseFloat(value),
    });
  };

  const handleDeleteRow = (index: number) => {
    const newItems = feeData.items.filter((_, i) => i !== index);
    setFeeData({ ...feeData, items: newItems });
  };

  const handleItemChange = (
    index: number,
    field: string,
    value: string | number,
    title: string
  ) => {
    const newItems = [...feeData.items];
    newItems[index] = { ...newItems[index], [field]: value };
    if (field == "categoryId") {
      newItems[index] = { ...newItems[index], categoryName: title };
      const subCategory = subCategoriesList.filter((item: any) => item.categoryId === value);
      setSubCategoryList(subCategory);
    }
    if (field == "subCategoryId") {
      newItems[index] = { ...newItems[index], subCategoryName: title };
    }
    setFeeData({ ...feeData, items: newItems });
  };

  useEffect(() => {
    const totalAmount = feeData.items.reduce((sum, item) => sum + item.amount, 0);
    const total = totalAmount + feeData.tax - feeData.discount;
    setFeeData((prevData) => ({
      ...prevData,
      amount: total,
      subTotal: totalAmount,
    }));
  }, [feeData.items, feeData.tax, feeData.discount]);

  const handlePayFees = async () => {
    try {
      console.log(feeData);
      const data: any = { ...feeData };
      data.email = studentFromStore.email;
      const res = await axiosInstance.post("/wallet/submitFees", data);
      const paymentMethodOption: any = paymentMethods.find(
        (p: any) => p.value === feeData.paymentMethodId
      );
      const pdfJsonData: any = {
        name: feeData.name,
        admissionId: feeData.admissionId,
        class: feeData.class,

        invoiceNo: res.data.invoiceNo,
        vchNo: res.data.vchNo,
        date: dayjs(feeData.date).format("DD-MMM-YYYY"),
        address: feeData.address,
        email: feeData.email,
        mobile: feeData.mobile,
        items: feeData.items.map((it) => {
          const ledgerOption: any = feesOptions.find((f: any) => f.value === it.ledgerId);
          return {
            ledgerName: ledgerOption ? ledgerOption?.label : "Unknown", // Fallback to "Unknown" if no match is found
            amount: it.amount,
          };
        }),
        subTotal: feeData.subTotal,
        discount: feeData.discount,
        tax: feeData.tax,
        amount: feeData.amount,
        paymentMethodName: paymentMethodOption.label,
      };
      navigate("/accounts/invoice", { state: { pdfJsonData } });
    } catch (error) {
      console.error("Error adding homework:", error);
    }
  };

  useEffect(() => {
    getPaymentOptions();
    getFeesOptions();
  }, [schoolId]);

  return (
    <>
      {/* Add Fees Collect */}
      <div className="modal fade" id="add_fees_collect">
        <div className="modal-dialog modal-dialog-centered  modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex align-items-center">
                <h4 className="modal-title">Collect Fees </h4>
              </div>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div id="modal-datepicker" className="modal-body">
                <div className="bg-light-300 p-3 pb-0 rounded mb-4">
                  <div className="row align-items-center">
                    <div className="col-lg-3 col-md-6">
                      <div className="mb-3">
                        <span className="fs-12 mb-1">Admission ID</span>
                        <p className="text-dark">{studentFromStore.admissionId}</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="mb-3">
                        <span className="fs-12 mb-1">Student Name</span>
                        <p className="text-dark">
                          {studentFromStore.firstName + " " + studentFromStore.lastName}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="mb-3">
                        <span className="fs-12 mb-1">Class</span>
                        <p className="text-dark">
                          {studentFromStore.classId + " " + studentFromStore.section}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="mb-3">
                        <span className="fs-12 mb-1">Total Outstanding</span>
                        <p className="text-dark">2000</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label className="form-label">Payment Type</label>
                      <Select
                        className="react-select"
                        options={paymentMethods}
                        onChange={(value: any) => {
                          setFeeData({
                            ...feeData,
                            paymentMethodId: value?.value || "",
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label className="form-label">Collection Date</label>
                      <input
                        type="date"
                        className="form-control"
                        value={feeData.date.split("T")[0]}
                        onChange={(e) => {
                          setFeeData({
                            ...feeData,
                            date: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label className="form-label">Discount</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Amout"
                        name="discount"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label className="form-label">Tax</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Amout"
                        name="tax"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="invoice-product-table ">
                    <div className="table-responsive invoice-table">
                      <table className="table  ">
                        <thead>
                          <tr>
                            <th>SL No.</th>
                            <th>Category</th>
                            <th>Amount</th>
                            <th>
                              {" "}
                              <Button onClick={handleAddFeesType}>
                                <FaPlus />
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {feeData.items.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <Select
                                  className="react-select"
                                  options={feesOptions}
                                  onChange={(value: any, label: any) => {
                                    handleItemChange(
                                      index,
                                      "ledgerId",
                                      value?.value || "",
                                      label?.label || ""
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Amount"
                                  value={item.amount}
                                  onChange={(e) =>
                                    handleItemChange(
                                      index,
                                      "amount",
                                      parseFloat(e.target.value) || 0,
                                      ""
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Button variant="danger" onClick={() => handleDeleteRow(index)}>
                                  <FaTrash />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label className="form-label">Total: {feeData.amount.toString()}</label>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-0">
                      <label className="form-label">Notes</label>
                      <textarea
                        rows={4}
                        className="form-control"
                        placeholder="Add Notes"
                        defaultValue={""}
                        onChange={(e) => {
                          setFeeData({
                            ...feeData,
                            description: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                  Cancel
                </Link>
                <Button className="btn btn-primary" data-bs-dismiss="modal" onClick={handlePayFees}>
                  Pay Fees
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Add Fees Collect */}
      {/* Delete Modal */}
      <div className="modal fade" id="delete-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-body text-center">
                <span className="delete-icon">
                  <i className="ti ti-trash-x" />
                </span>
                <h4>Confirm Deletion</h4>
                <p>You want to delete all the marked items, this cant be undone once you delete.</p>
                <div className="d-flex justify-content-center">
                  <Link to="#" className="btn btn-light me-3" data-bs-dismiss="modal">
                    Cancel
                  </Link>
                  <Link to="#" className="btn btn-danger" data-bs-dismiss="modal">
                    Yes, Delete
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Delete Modal */}

      <>
        {/* Login Details */}
        <div className="modal fade" id="login_detail">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Login Details</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <div className="modal-body">
                <div className="student-detail-info">
                  <span className="student-img">
                    <ImageWithBasePath src="assets/img/students/student-01.jpg" alt="Img" />
                  </span>
                  <div className="name-info">
                    <h6>
                      Janet <span>III, A</span>
                    </h6>
                  </div>
                </div>
                <div className="table-responsive custom-table no-datatable_length">
                  <table className="table datanew">
                    <thead className="thead-light">
                      <tr>
                        <th>User Type</th>
                        <th>User Name</th>
                        <th>Password </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Parent</td>
                        <td>parent53</td>
                        <td>parent@53</td>
                      </tr>
                      <tr>
                        <td>Student</td>
                        <td>student20</td>
                        <td>stdt@53</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Login Details */}
      </>
      <>
        {/* Apply Leave */}
        <div className="modal fade" id="apply_leave">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Apply Leave</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Leave Date</label>
                        <div className="date-pic">
                          <DatePicker
                            className="form-control datetimepicker"
                            format={{
                              format: "DD-MM-YYYY",
                              type: "mask",
                            }}
                            getPopupContainer={getModalContainer}
                            defaultValue={defaultValue}
                            placeholder="16 May 2024"
                          />
                          <span className="cal-icon">
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Leave Type</label>
                        <CommonSelect
                          className="select"
                          options={leaveType}
                          defaultValue={leaveType[0]}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Leave From date</label>
                        <div className="date-pic">
                          <DatePicker
                            className="form-control datetimepicker"
                            format={{
                              format: "DD-MM-YYYY",
                              type: "mask",
                            }}
                            getPopupContainer={getModalContainer}
                            defaultValue={defaultValue}
                            placeholder="16 May 2024"
                          />
                          <span className="cal-icon">
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Leave to Date</label>
                        <div className="date-pic">
                          <DatePicker
                            className="form-control datetimepicker"
                            format={{
                              format: "DD-MM-YYYY",
                              type: "mask",
                            }}
                            getPopupContainer={getModalContainer}
                            defaultValue={defaultValue}
                            placeholder="16 May 2024"
                          />
                          <span className="cal-icon">
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Leave Days</label>
                        <div className="d-flex align-items-center check-radio-group">
                          <label className="custom-radio">
                            <input type="radio" name="radio" defaultChecked />
                            <span className="checkmark" />
                            Full day
                          </label>
                          <label className="custom-radio">
                            <input type="radio" name="radio" />
                            <span className="checkmark" />
                            First Half
                          </label>
                          <label className="custom-radio">
                            <input type="radio" name="radio" />
                            <span className="checkmark" />
                            Second Half
                          </label>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">No of Days</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="mb-0">
                        <label className="form-label">Reason</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                    Cancel
                  </Link>
                  <Link to="#" data-bs-dismiss="modal" className="btn btn-primary">
                    Apply Leave
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Apply Leave */}
        {/* Generate Admit Card Modal */}
      </>
    </>
  );
};

export default StudentModals;
