import React, { useRef, useState, useEffect } from "react";
import { classhomework } from "../../../core/data/json/class_home_work";
import Table from "../../../core/common/dataTable/index";
import {
  classSection,
  classSylabus,
  language,
  weak,
} from "../../../core/common/selectoption/selectoption";

import { TableData } from "../../../core/data/interface";
import CommonSelect from "../../../core/common/commonSelect";
import PredefinedDateRanges from "../../../core/common/datePicker";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { all_routes } from "../../router/all_routes";
import TooltipOption from "../../../core/common/tooltipOption";
import axiosInstance from "../../auth/axiosInstance";
import Select from "react-select";
import { values } from "../../../style/icon/fontawesome/js/v4-shims";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const ClassHomeWork = () => {
  const routes = all_routes;
  const role = useSelector((state: any) => state.userSlice.role);
  console.log("🛒🛒", role);
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const data = classhomework;
  const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const [homeWorkList, setHomeworkList] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [subjectOptions, setSubjectOption] = useState<any>([]);
  const [selectedHomeWork, setSelectHomeWork] = useState({
    id: "",
    classId: null,
    className: "",
    subjectId: null,
    subjectName: "",
    schoolId: userDetails.schoolId,
    title: "",
    homeworkDate: "",
    submissionDate: "",
    description: "",
    createdBy: 1,
    createdByName: "",
  });
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.class.length - b.class.length,
    },

    {
      title: "Class",
      dataIndex: "className",
      sorter: (a: TableData, b: TableData) => a.className.length - b.className.length,
    },

    {
      title: "Subject",
      dataIndex: "subjectName",
      sorter: (a: TableData, b: TableData) => a.subjectName.length - b.subjectName.length,
    },
    {
      title: "Homework Date",
      dataIndex: "homeworkDate",

      render: (text: string) => <>{dayjs(text).format("DD/MMM/YYYY")}</>,
      sorter: (a: TableData, b: TableData) => a.homeworkDate.length - b.homeworkDate.length,
    },
    {
      title: "Submission Date",
      dataIndex: "submissionDate",
      render: (text: string) => <>{dayjs(text).format("DD/MMM/YYYY")}</>,
      sorter: (a: TableData, b: TableData) => a.submissionDate.length - b.submissionDate.length,
    },
    {
      title: "CreatedBy",
      dataIndex: "createdByName",
      sorter: (a: TableData, b: TableData) => a.createdByName.length - b.createdByName.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (text: string, record: any) => (
        <>
          <div className="dropdown">
            <Link
              to="#"
              className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="ti ti-dots-vertical fs-14" />
            </Link>
            <ul className="dropdown-menu dropdown-menu-right p-3">
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#view_home_work"
                  onClick={() => {
                    setSelectHomeWork({
                      id: record.id,
                      schoolId: record.schoolId,
                      title: record.title,
                      description: record.description,
                      classId: record.classid,
                      subjectId: record.subjectId,
                      homeworkDate: new Date(record.homeworkDate).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      }),
                      className: record.className,
                      subjectName: record.subjectName,
                      createdByName: record.createdByName,
                      createdBy: record.createdBy,
                      submissionDate: new Date(record.submissionDate).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      }),
                    });
                    console.log("🤣🤣🤣", record.staffId);
                  }}
                >
                  <i className="ti ti-menu me-2" />
                  View
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#edit_home_work"
                  onClick={() => {
                    setSelectHomeWork({
                      id: record.id,
                      schoolId: record.schoolId,
                      title: record.title,
                      description: record.description,
                      classId: record.classid,
                      subjectId: record.subjectId,
                      homeworkDate: record.homeworkDate.split("T")[0],
                      className: record.className,
                      subjectName: record.subjectName,
                      createdByName: record.createdByName,
                      createdBy: record.createdBy,
                      submissionDate: record.submissionDate.split("T")[0],
                    });
                  }}
                >
                  <i className="ti ti-edit-circle me-2" />
                  Edit
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item rounded-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#delete-modal"
                  onClick={() => {
                    setSelectHomeWork({
                      id: record.id,
                      schoolId: record.schoolId,
                      title: record.title,
                      description: record.description,
                      classId: record.classId,
                      subjectId: record.subjectId,
                      homeworkDate: record.homeworkDate,
                      className: record.className,
                      subjectName: record.subjectName,
                      createdByName: record.createdByName,
                      createdBy: record.createdBy,
                      submissionDate: record.submissionDate,
                    });
                  }}
                >
                  <i className="ti ti-trash-x me-2" />
                  Delete
                </Link>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];

  const fetchhomeWorkList = async () => {
    try {
      const response = await axiosInstance.get("/homework", {
        params: {
          schoolId: userDetails.schoolId,
        },
      });
      // console.log(response.data);
      const transformedData = response.data.map((item: any, idx: number) => ({
        id: item.id,
        classId: item.classId,
        subjectId: item.subjectId,
        homeworkDate: item.homeworkDate,
        subjectName: item.subjectName,
        className: item.className,
        createdBy: item.createdBy,
        createdByName: item.createdByName,
        submissionDate: item.submissionDate,
        title: item.title,
        description: item.description,
        key: item.id,
      }));
      console.log(transformedData);

      if (transformedData.length > 0) {
        setHomeworkList(transformedData);
      }
    } catch (error) {
      console.error("Error fetching staff list:", error);
    }
  };

  const fetchSchoolClassList = async () => {
    try {
      const response = await axiosInstance.get("/class/school/" + schoolId);
      console.log(response.data);
      const transformedData = response.data.map((item: any, idx: number) => ({
        value: item.id,
        label: item.name + " " + item.section,
        subjects: item.subjects.map((sb: any) => ({
          value: sb.id,
          label: sb.subjectName,
        })),
      }));
      console.log(transformedData);

      if (transformedData.length > 0) {
        setClassOptions(transformedData);
      }
    } catch (error) {
      console.error("Error fetching class list:", error);
    }
  };

  const fetchSchoolSubjectList = async () => {
    try {
      const response = await axiosInstance.get(`/class/schoolSubjects/${userDetails.schoolId}`);
      console.log(response.data);
      const transformedData = response.data.reduce((acc: any, item: any) => {
        if (!acc[item.classId]) {
          acc[item.classId] = {};
        }
        acc[item.classId] = item.subjects;
        return acc;
      }, {});
      console.log(transformedData);
      setSubjectOption(transformedData);
    } catch (error) {
      console.error("Error fetching staff list:", error);
    }
  };

  const [homeworkDetails, setHomeworkDetails] = useState({
    title: "",
    description: "",
    submissionDate: "",
    homeworkDate: "",
    createdBy: userDetails.id,
    createdByName: userDetails.email,
    classId: null,
    className: "",
    subjectId: null,
    subjectName: "",
    subject: "",
    schoolId: schoolId || userDetails.schoolId,
    id: null,
    // Add other necessary fields
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setHomeworkDetails({
      ...homeworkDetails,
      [name]: value,
    });
  };

  const handleAddHomework = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault(); // Prevent the default link behavior
    try {
      const response = await axiosInstance.post("/homework", homeworkDetails);
      console.log("Homework added successfully:", response.data);
      fetchhomeWorkList();
    } catch (error) {
      console.error("Error adding homework:", error);
    }
  };

  const handleEditHomework = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault(); // Prevent the default link behavior
    try {
      const response = await axiosInstance.put(
        `/homework/` + selectedHomeWork.id,
        selectedHomeWork
      );
      console.log("Homework edited successfully:", response.data);
      fetchhomeWorkList();
    } catch (error) {
      console.error("Error adding homework:", error);
    }
  };

  const handleDeleteHomework = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault(); // Prevent the default link behavior
    try {
      const response = await axiosInstance.delete("/homework/" + selectedHomeWork.id);
      console.log("Homework added successfully:", response.data);
      fetchhomeWorkList();
    } catch (error) {
      console.error("Error adding homework:", error);
    }
  };
  useEffect(() => {
    fetchhomeWorkList();
    fetchSchoolClassList();
    fetchSchoolSubjectList();
  }, []);
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Class Work</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">Academic </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Class Work
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                <TooltipOption />
                {role && role !== "Student" && (
                  <div className="mb-2">
                    <Link
                      to="#"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#add_home_work"
                    >
                      <i className="ti ti-square-rounded-plus-filled me-2" />
                      Add Home Work
                    </Link>
                  </div>
                )}
              </div>
            </div>
            {/* /Page Header */}
            {/* Guardians List */}
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                <h4 className="mb-3">Class Home Work</h4>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="input-icon-start mb-3 me-2 position-relative">
                    <PredefinedDateRanges />
                  </div>
                  <div className="dropdown mb-3 me-2">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      <i className="ti ti-filter me-2" />
                      Filter
                    </Link>
                    <div className="dropdown-menu drop-width" ref={dropdownMenuRef}>
                      <form>
                        <div className="d-flex align-items-center border-bottom p-3">
                          <h4>Filter</h4>
                        </div>
                        <div className="p-3 border-bottom pb-0">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Subject</label>
                                <CommonSelect className="select" options={language} />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Class</label>

                                <CommonSelect className="select" options={classSylabus} />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Section</label>

                                <CommonSelect className="select" options={classSection} />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Date</label>
                                <CommonSelect className="select" options={weak} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-3 d-flex align-items-center justify-content-end">
                          <Link to="#" className="btn btn-light me-3">
                            Reset
                          </Link>
                          <Link to="#" className="btn btn-primary" onClick={handleApplyClick}>
                            Apply
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="dropdown mb-3">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-sort-ascending-2 me-2" />
                      Sort by A-Z
                    </Link>
                    <ul className="dropdown-menu p-3">
                      <li>
                        <Link to="#" className="dropdown-item rounded-1 active">
                          Ascending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Descending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Viewed
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Added
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-body p-0 py-3">
                {/* Guardians List */}
                <Table columns={columns} dataSource={homeWorkList} Selection={true} />

                {/* /Guardians List */}
              </div>
            </div>
            {/* /Guardians List */}
          </div>
        </div>
        {/* /Page Wrapper */}
        {/* Add Home Work */}
        <div className="modal fade" id="add_home_work">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Home Work</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Class</label>

                        <Select
                          className="react-select"
                          options={classOptions}
                          placeholder="Select"
                          onChange={(value: any) => {
                            setHomeworkDetails({
                              ...homeworkDetails,
                              classId: value?.value || "",
                              className: value?.label || "",
                            });
                            const selectedClass: any = classOptions.find(
                              (c: any) => c.value === value?.value
                            );
                            console.log("new subs", selectedClass.subjects);

                            setSubjectOption(selectedClass.subjects);
                          }}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Subject</label>

                            <Select
                              className="react-select"
                              options={subjectOptions}
                              placeholder="Select"
                              onChange={(value: any) => {
                                setHomeworkDetails({
                                  ...homeworkDetails,
                                  subjectId: value?.value || "",
                                  subjectName: value?.label || "",
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Homework Date</label>
                            <input
                              type="date"
                              className="form-control"
                              name="homeworkDate"
                              value={homeworkDetails.homeworkDate}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Submission Date</label>
                            <input
                              type="date"
                              className="form-control"
                              name="submissionDate"
                              value={homeworkDetails.submissionDate}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Title</label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          value={homeworkDetails.title}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <textarea
                          className="form-control"
                          rows={6}
                          defaultValue={""}
                          name="description"
                          value={homeworkDetails.description}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={handleAddHomework}
                  >
                    Add Homework
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Add Home Work */}
        {/* View Home Work */}
        <div className="modal fade" id="view_home_work">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">View Home Work</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Class</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Class"
                          value={selectedHomeWork.className}
                          readOnly
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Subject</label>

                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Subject"
                              value={selectedHomeWork.subjectName}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Homework Date</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="10 May 2024"
                              value={selectedHomeWork.homeworkDate}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Submission Date</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="12 May 2024"
                              value={selectedHomeWork.submissionDate}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Title</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Placeholders"
                          value={selectedHomeWork.title}
                          readOnly
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <textarea
                          className="form-control"
                          placeholder="Add Comment"
                          rows={4}
                          value={selectedHomeWork.description}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                    Cancel
                  </Link>
                  <Link to="#" className="btn btn-primary" data-bs-dismiss="modal">
                    Save Changes
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Edit Home Work */}
        {/* Edit Home Work */}
        <div className="modal fade" id="edit_home_work">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Home Work</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Class</label>
                        <Select
                          className="react-select"
                          options={classOptions}
                          placeholder="Select"
                          value={{
                            label: selectedHomeWork.className,
                            value: selectedHomeWork.classId,
                          }}
                          onChange={(value: any) => {
                            setSelectHomeWork({
                              ...selectedHomeWork,
                              classId: value?.value || "",
                              className: value?.label || "",
                            });
                            const selectedClass: any = classOptions.find(
                              (c: any) => c.value === value?.value
                            );
                            console.log("new subs", selectedClass.subjects);

                            setSubjectOption(selectedClass.subjects);
                          }}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Subject</label>

                            <Select
                              className="react-select"
                              options={subjectOptions}
                              placeholder="Select"
                              value={{
                                label: selectedHomeWork.subjectName,
                                value: selectedHomeWork.subjectId,
                              }}
                              onChange={(value: any) => {
                                setSelectHomeWork({
                                  ...selectedHomeWork,
                                  subjectId: value?.value || "",
                                  subjectName: value?.label || "",
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Homework Date</label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="10 May 2024"
                              value={selectedHomeWork.homeworkDate}
                              onChange={(e) => {
                                setSelectHomeWork({
                                  ...selectedHomeWork,
                                  homeworkDate: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Submission Date</label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="12 May 2024"
                              value={selectedHomeWork.submissionDate}
                              onChange={(e) => {
                                setSelectHomeWork({
                                  ...selectedHomeWork,
                                  submissionDate: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Title</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Placeholders"
                          value={selectedHomeWork.title}
                          onChange={(e) => {
                            setSelectHomeWork({ ...selectedHomeWork, title: e.target.value });
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <textarea
                          className="form-control"
                          placeholder="Add Comment"
                          rows={4}
                          value={selectedHomeWork.description}
                          onChange={(e) => {
                            setSelectHomeWork({ ...selectedHomeWork, description: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={handleEditHomework}
                  >
                    Save Changes
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Edit Home Work */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirm Deletion</h4>
                  <p>
                    You want to delete all the marked items, this cant be undone once you delete.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link to="#" className="btn btn-light me-3" data-bs-dismiss="modal">
                      Cancel
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      onClick={handleDeleteHomework}
                    >
                      Yes, Delete
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Delete Modal */}
      </>
    </div>
  );
};

export default ClassHomeWork;
