export interface Student {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  contactNumber: string;
  classId: number;
  section: string;
  schoolId: number;
  gender: string;
  bloodGroup: string;
  religion: string;
  caste: string;
  category: string;
  motherTongue: string;
  languages: string[];
  admissionId: string;
  admissionDate: string;
  academicYear: string;
  status: string;
  rollNumber: string;
  fatherName: string;
  motherName: string;
  fatherEmail: string;
  motherEmail: string;
  fatherContactNumber: string;
  motherContactNumber: string;
  fatherOccupation: string;
  motherOccupation: string;
  guardianName: string;
  guardianContactNumber: string;
  guardianRelation: string;
  guardianEmail: string;
  guardianOccupation: string;
  guardianAddress: string;
  currentAddress: string;
  permanentAddress: string;
  totalFees: string;
  feesPaid: string;
  feesDue: string;
  feesDiscount: string;
  feeJson: {
    items: {
      description: string;
      amount: number;
      categoryId: string;
      subCategoryId: string;
      subCategoryName: string;
      categoryName: string;
    }[];
  };
}
export const initialStudentValues: Student = {
  firstName: "",
  lastName: "",
  email: "",
  dateOfBirth: "",
  contactNumber: "",
  classId: 1,
  section: "",
  schoolId: 1,
  gender: "",
  bloodGroup: "",
  religion: "",
  category: "",
  caste: "",
  motherTongue: "",
  languages: [],
  admissionId: "",
  admissionDate: "",
  academicYear: "",
  status: "",
  rollNumber: "",
  fatherName: "",
  motherName: "",
  fatherEmail: "",
  motherEmail: "",
  fatherContactNumber: "",
  motherContactNumber: "",
  fatherOccupation: "",
  motherOccupation: "",
  guardianName: "",
  guardianContactNumber: "",
  guardianRelation: "",
  guardianEmail: "",
  guardianOccupation: "",
  guardianAddress: "",
  currentAddress: "",
  permanentAddress: "",
  feeJson: {
    items: [
      {
        description: "",
        amount: 0,
        categoryName: "",
        categoryId: "",
        subCategoryName: "",
        subCategoryId: "",
      },
    ],
  },
  totalFees: "",
  feesPaid: "",
  feesDue: "",
  feesDiscount: "",
};
