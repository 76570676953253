import React, { useEffect, useRef } from "react";
// import { classes } from "../../../core/data/json/classes";
import Table from "../../../core/common/dataTable/index";
import PredefinedDateRanges from "../../../core/common/datePicker";
import Select from "react-select";
import {
  activeList,
  classSection,
  classSylabus,
  school,
} from "../../../core/common/selectoption/selectoption";
import CommonSelect from "../../../core/common/commonSelect";
import { TableData } from "../../../core/data/interface";
import { Link } from "react-router-dom";
import TooltipOption from "../../../core/common/tooltipOption";
import { all_routes } from "../../router/all_routes";
import { Button } from "antd";
import axiosInstance from "../../auth/axiosInstance";
import { useSelector } from "react-redux";
import ExcelJs from "exceljs";
import { saveAs } from "file-saver";
const Classes = () => {
  const routes = all_routes;
  const { schoolId } = useSelector((state: any) => state.userSlice);

  console.log(schoolId);
  const [teacherList, setTeacherList] = React.useState<any>([]);
  const [classList, setClassList] = React.useState<any>([]);
  const dataForExcel = classList.map((cl: any) => ({
    name: cl.name,
    section: cl.section,
    noOfStudents: cl.noOfStudents,
    noOfSubjects: cl.noOfSubjects,
    classFees: cl.classFees,
    classMonitor: cl.classMonitor,
  }));
  const [selectedClassId, setSelectedClassId] = React.useState<any>(null);
  const bulkUploadUrlBasePath = "/class/upload";
  const sectionOptions = [
    {
      value: "A",
      label: "A",
    },
    {
      value: "B",
      label: "B",
    },
    {
      value: "C",
      label: "C",
    },
    {
      value: "D",
      label: "D",
    },
  ];
  const excelFormat = [
    {
      name: "",
      section: "",
      noOfStudents: "",
      noOfSubjects: "",
      classMonitor: "",
      classFees: "",
    },
  ];
  const [subjectOptions, setSubjectOptions] = React.useState<any>([]);

  const getSubjectListData = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get(`/schoolSubjects?schoolId=${schoolId}`);
    const list = res.data.map((sub: any) => {
      return {
        value: sub.id,
        label: sub.name,
      };
    });
    setSubjectOptions(list);
  };

  // const handleExportToExcel = async () => {
  //   const data = classList.map((cl: any) => ({
  //     name: cl.name,
  //     section: cl.section,
  //     noOfStudents: cl.numberOfStudents,
  //     noOfSubjects: cl.numberOfSubjects,
  //     classFees: cl.classFees,
  //     classMonitor: cl.classMonitor,
  //   }));
  //   const worksheet = XLSX.utils.json_to_sheet(data);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "Classes.xlsx");
  // };
  const getTeacherListData = async () => {
    if (!schoolId) return;
    const response = await axiosInstance.get("/staff/teachers", {
      params: { schoolId },
    });
    let teacherList = response.data;
    teacherList = teacherList.map((item: any) => {
      return {
        label: item.firstName + " " + item.lastName,
        value: item.id,
      };
    });
    setTeacherList(teacherList);
    console.log(response.data);
  };
  const getClassListData = async () => {
    if (!schoolId) return;
    const response = await axiosInstance.get("/class/school/" + schoolId);
    setClassList(response.data);
  };

  const [formData, setFormData] = React.useState<any>({
    name: "",
    section: "",
    noOfStudents: "",
    noOfSubjects: "",
    subjects: [],
    staffId: "",
    classFees: 0,
    clasMonitor: "",
    schoolId: schoolId,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubjectChange = (index: number, selectedOption: any) => {
    const newSubjects = [...formData.subjects];
    newSubjects[index].subjectId = selectedOption.value;
    setFormData({ ...formData, subjects: newSubjects });
  };

  const handleTeacherChange = (index: number, selectedOption: any) => {
    const newSubjects = [...formData.subjects];
    newSubjects[index].staffId = selectedOption.value;
    setFormData({ ...formData, subjects: newSubjects });
  };

  const classDataSource = classList.map((item: any) => {
    return {
      id: item.id,
      class: item.name,
      section: item.section,
      noOfStudents: item.noOfStudents,
      noOfSubjects: item.noOfSubjects,
      subjects: item.subjects,
      classFees: item.classFees,
      classMonitor: item.classMonitor,
      staffId: item.staffId,
      key: item.id,
    };
  });

  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const classHeaders = [
    { header: "name", key: "name" },
    { header: "section", key: "section" },
    { header: "noOfStudents", key: "noOfStudents", width: 32 },
    { header: "noOfSubjects", key: "noOfSubjects", width: 32 },
    { header: "classFees", key: "classFees", width: 20 },
    { header: "classMonitor", key: "classMonitor", width: 32 },
  ];
  const handleImportFile = async (e: any) => {
    const file = e.target.files[0];
    // console.log(file);

    const arrayBuffer = await file.arrayBuffer();
    const wb = new ExcelJs.Workbook();
    await wb.xlsx.load(arrayBuffer);
    const ws = wb.worksheets[0];
    let jsonData: any = [];

    const headers: any = [];
    ws.getRow(1).eachCell((cell, colNumber) => {
      headers.push(cell.text); // Assuming first row is the header
    });

    ws.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        // Skip the first row (headers)
        const rowData: any = {};

        row.eachCell((cell, colNumber) => {
          const header = headers[colNumber - 1]; // Get header based on column index
          rowData[header] = cell.text; // Map cell value to the header
        });

        jsonData.push(rowData);
      }
    });
    // console.log("Extracted JSON Data:", jsonData);
    jsonData = jsonData.map((row: any) => ({ ...row, schoolId: schoolId }));
    await axiosInstance.post("/class/upload", jsonData);
  };
  const downloadFormat = async () => {
    const workBook = new ExcelJs.Workbook();
    const workSheet = workBook.addWorksheet("Sheet 1");
    workSheet.columns = classHeaders;

    const s = 1;
    const e = 10000;
    for (let i = s; i <= e; ++i) {
      const cellId = "B" + i.toString();
      // console.log(cellId);

      workSheet.getCell(cellId).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: ['"A,B,C"'],
      };
    }
    const buffer = await workBook.xlsx.writeBuffer();
    const blob = new Blob([buffer]);
    saveAs(blob, "example.xlsx");
  };

  const handleAddClass = async () => {
    await axiosInstance.post("/class/create", {
      ...formData,
      noOfStudents: parseInt(formData.noOfStudents),
      noOfSubjects: parseInt(formData.noOfSubjects),
      schoolId: schoolId,
      staffId: parseInt(formData.staffId),
    });
    getClassListData();
  };

  const handleEditClass = async () => {
    console.log(formData);
    await axiosInstance.put(`/class/update?id=${selectedClassId}`, {
      ...formData,
    });
    getClassListData();
  };

  const handleDeleteClass = async () => {
    await axiosInstance.delete(`/class/delete?id=${selectedClassId}`);
    getClassListData();
  };
  const route = all_routes;
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link
            to={`/academic/class-details/${record.id}`}
            className="link-primary"
          >
            {record.id}
          </Link>
        </>
      ),
    },

    {
      title: "Class",
      dataIndex: "class",
      sorter: (a: TableData, b: TableData) => a.class.length - b.class.length,
    },
    {
      title: "Section",
      dataIndex: "section",
      sorter: (a: TableData, b: TableData) =>
        a.section.length - b.section.length,
    },
    {
      title: "No. of Students",
      dataIndex: "noOfStudents",
      sorter: (a: TableData, b: TableData) =>
        a.noOfStudents.length - b.noOfStudents.length,
    },
    {
      title: "No. of Subjects",
      dataIndex: "noOfSubjects",
      sorter: (a: TableData, b: TableData) =>
        a.noOfSubjects.length - b.noOfSubjects.length,
    },
    {
      title: "Class Fees",
      dataIndex: "classFees",
    },
    {
      title: "Class Monitor",
      dataIndex: "classMonitor",
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (text: string, record: any) => (
        <>
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    onClick={() => {
                      setSelectedClassId(record.id);
                      console.log("record is", record);

                      setFormData({
                        name: record.class,
                        section: record.section,
                        noOfStudents: record.noOfStudents,
                        noOfSubjects: record.noOfSubjects,
                        classFees: record.classFees,
                        classMonitor: record.classMonitor,
                        staffId: record.staffId,
                        subjects: record.subjects,
                        schoolId: schoolId,
                      });
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#edit_class"
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Edit
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    onClick={() => setSelectedClassId(record.id)}
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                  >
                    <i className="ti ti-trash-x me-2" />
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];
  const defaultStaffOption = teacherList.find(
    (t: any) => t.value === formData.staffId
  );
  useEffect(() => {
    getClassListData();
    getTeacherListData();
    getSubjectListData();
  }, [schoolId]);

  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Classes List</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={route.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Classes </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Classes
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex  gap-4 my-xl-auto right-content align-items-center flex-wrap">
              {/* <TooltipOption
                list={dataForExcel}
                excelFormat={excelFormat}
                url={bulkUploadUrlBasePath}
              /> */}
              <div className="mb-2 ">
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#import-modal"
                >
                  Import
                </Link>
              </div>

              <div className="mb-2">
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_class"
                >
                  <i className="ti ti-square-rounded-plus-filled me-2" />
                  Add Class
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Guardians List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Classes List</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div
                    className="dropdown-menu drop-width"
                    ref={dropdownMenuRef}
                  >
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 border-bottom pb-0">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Class</label>
                              <CommonSelect
                                className="select"
                                options={classSylabus}
                                defaultValue={classSylabus[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Section</label>
                              <CommonSelect
                                className="select"
                                options={classSection}
                                defaultValue={classSection[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Status</label>
                              <CommonSelect
                                className="select"
                                options={activeList}
                                defaultValue={activeList[0]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={handleApplyClick}
                        >
                          Apply
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Guardians List */}
              <Table
                columns={columns}
                dataSource={classDataSource}
                Selection={true}
              />
              {/* /Guardians List */}
            </div>
          </div>
          {/* /Guardians List */}
        </div>
      </div>
      ;{/* /Page Wrapper */}
      <>
        {/* Add Classes */}
        <div className="modal fade" id="add_class">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Class</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Class Name</label>
                        <input
                          name="name"
                          onChange={handleChange}
                          value={formData.name}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Section</label>
                        <Select
                          name="section"
                          className="select"
                          options={classSection}
                          onChange={(value) => {
                            setFormData({
                              ...formData,
                              section: value?.value || "A",
                            });
                          }}
                          defaultValue={classSection[0]}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Class Teacher</label>
                        <Select
                          name="staffId"
                          className="select"
                          options={teacherList}
                          onChange={(value) => {
                            setFormData({
                              ...formData,
                              staffId: value?.value || "1",
                            });
                          }}
                          defaultValue={teacherList[0]}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">No of Students</label>
                        <input
                          name="noOfStudents"
                          onChange={handleChange}
                          value={formData.noOfStudents}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      {/* <div className="mb-3">
                        <label className="form-label">Class Fees</label>
                        <input
                          name="classFees"
                          onChange={handleChange}
                          value={formData.classFees}
                          type="text"
                          className="form-control"
                        />
                      </div> */}
                      <div className="mb-3">
                        <label className="form-label">Class Monitor</label>
                        <input
                          name="classMonitor"
                          onChange={handleChange}
                          value={formData.classMonitor}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="mb-3 ">
                        <label className="form-label">No of Subjects</label>
                        <input
                          name="noOfSubjects"
                          onChange={handleChange}
                          value={formData.noOfSubjects}
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <Button
                        onClick={() => {
                          const newSub = {
                            subjectId: subjectOptions[0].value,
                            teacherId: teacherList[0].value,
                          };
                          setFormData({
                            ...formData,
                            subjects: [newSub, ...formData.subjects],
                          });
                        }}
                      >
                        Add{" "}
                      </Button>
                      {formData.subjects &&
                        formData.subjects.map((sub: any, index: number) => (
                          <div className="mb-3 d-flex align-content-center gap-3 justify-content-between">
                            <div className="w-50">
                              <label className="form-label">Subject </label>
                              <Select
                                className="select"
                                name="subjectName"
                                onChange={(value) =>
                                  handleSubjectChange(index, value)
                                }
                                options={subjectOptions}
                                value={subjectOptions.find(
                                  (subject: any) =>
                                    subject.value === sub.subjectId
                                )}
                              />
                            </div>
                            <div className="w-40">
                              <label className="form-label">Teacher</label>
                              <Select
                                className="select"
                                name="subjectTeacherName"
                                onChange={(value) =>
                                  handleTeacherChange(index, value)
                                }
                                options={teacherList}
                                value={teacherList.find(
                                  (teacher: any) =>
                                    teacher.value === sub.teacherId
                                )}
                              />
                            </div>
                            <div className="w-10">
                              <Button
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    subjects: formData.subjects.filter(
                                      (item: any, i: number) => i !== index
                                    ),
                                  });
                                }}
                              >
                                -
                              </Button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Button
                    onClick={handleAddClass}
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Add Class
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Add Classes */}
        {/* Edit Classes */}
        <div className="modal fade" id="edit_class">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Class</h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Class Name</label>
                        <input
                          name="name"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          placeholder="Enter Class Name"
                          value={formData.name}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Section</label>
                        <Select
                          name="section"
                          onChange={(value) => {
                            setFormData({
                              ...formData,
                              section: value?.value || "A",
                            });
                          }}
                          className="select"
                          options={sectionOptions}
                          value={{
                            value: formData.section,
                            label: formData.section,
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Class Teacher</label>
                        <Select
                          name="staffId"
                          className="react-select"
                          options={teacherList}
                          onChange={(value) => {
                            setFormData({
                              ...formData,
                              staffId: value?.value || "1",
                            });
                            // console.log(value);
                          }}
                          value={defaultStaffOption}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">No of Students</label>
                        <input
                          name="noOfStudents"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          placeholder="Enter no of Students"
                          value={formData.noOfStudents}
                        />
                      </div>
                      {/* <div className="mb-3">
                        <label className="form-label">Class Fees</label>
                        <input
                          name="classFees"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          placeholder="Enter class Fees"
                          value={formData.classFees}
                        />
                      </div> */}
                      <div className="mb-3">
                        <label className="form-label">Class Monitor</label>
                        <input
                          name="classMonitor"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          placeholder="Enter class monitor"
                          value={formData.classMonitor}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">No of Subjects</label>
                        <input
                          name="noOfSubjects"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          placeholder="Enter no of Subjects"
                          value={formData.noOfSubjects}
                        />
                      </div>
                      <Button
                        onClick={() => {
                          const newSub = {
                            subjectId: subjectOptions[0].value,
                            teacherId: teacherList[0].value,
                          };
                          setFormData({
                            ...formData,
                            subjects: [...formData.subjects, newSub],
                          });
                        }}
                      >
                        {" "}
                        Add
                      </Button>
                      {formData.subjects &&
                        formData.subjects.length > 0 &&
                        formData.subjects.map((subject: any, index: number) => (
                          <div
                            className="mb-3 d-flex gap-3 justify-content-between"
                            key={index}
                          >
                            <div className="w-50">
                              <label className="form-label">Subject </label>
                              <Select
                                className="select"
                                name="subjectName"
                                value={subjectOptions.find(
                                  (sub: any) => sub.value === subject.subjectId
                                )}
                                options={subjectOptions}
                                onChange={(value) =>
                                  handleSubjectChange(index, value)
                                }
                              />
                            </div>

                            <div className="w-40">
                              <label className="form-label">Teacher</label>
                              <Select
                                className="select"
                                name="subjectTeacherName"
                                value={teacherList.find(
                                  (teacher: any) =>
                                    teacher.value == subject.staffId
                                )}
                                onChange={(value) =>
                                  handleTeacherChange(index, value)
                                }
                                options={teacherList}
                              />
                            </div>
                            <div className="w-10">
                              <Button
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    subjects: formData.subjects.filter(
                                      (item: any, i: number) => i !== index
                                    ),
                                  });
                                }}
                              >
                                {" "}
                                -{" "}
                              </Button>
                            </div>
                          </div>
                        ))}
                      {}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Button
                    onClick={handleEditClass}
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Save Changes
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Edit Classes */}
        {/* Delete Modal */}
        <div className="modal fade" id="delete-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form>
                <div className="modal-body text-center">
                  <span className="delete-icon">
                    <i className="ti ti-trash-x" />
                  </span>
                  <h4>Confirm Deletion</h4>
                  <p>
                    You want to delete all the marked items, this cant be undone
                    once you delete.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link
                      to="#"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Button
                      onClick={handleDeleteClass}
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Yes, Delete
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Delete Modal */}
        {/* View Classes */}
        <div className="modal fade" id="view_class">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex align-items-center">
                  <h4 className="modal-title">Class Details</h4>
                  <span className="badge badge-soft-success ms-2">
                    <i className="ti ti-circle-filled me-1 fs-5" />
                    Active
                  </span>
                </div>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>Class Name</p>
                        <span>III</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>Section</p>
                        <span>A</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>No of Subjects</p>
                        <span>05</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>No of Students</p>
                        <span>25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /View Classes */}
        {/* import modal */}
        <div className="modal fade " id="import-modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Import </h4>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <div className="modal-body d-flex gap-5">
                <Link
                  to="#"
                  onClick={() => downloadFormat()}
                  className="btn btn-primary"
                >
                  Download Format
                </Link>
                <label className="btn btn-primary">
                  {" "}
                  <input
                    onChange={(e) => handleImportFile(e)}
                    style={{ display: "none" }}
                    type="file"
                    accept=".xlsx, .xlw, .csv"
                  />
                  Import Excel File
                </label>
              </div>
              <div className="modal-footer">
                Note: Please download the formatted Excel sheet to ensure your
                data matches the required structure before importing.
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Classes;
