// src/axiosInstance.ts
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("accessToken"); // Get the access token from localStorage
    if (token) {
      config.headers["Authorization"] = `Bearer ${JSON.parse(token ?? "")}`; // Set the token in the Authorization header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
const createInterceptor = (logout: () => void) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.log("error", error);
      const { response } = error;
      const { status } = response;

      // Handle 403 Forbidden
      if (status === 403) {
        logout(); // Log the user out
        if (window.location.pathname !== "/login") {
          window.location.href = "/login"; // Redirect to login page
        }
      }

      // Handle 401 Unauthorized
      if (status === 401) {
        try {
          const refreshResponse = await axiosInstance.get("/auth/refresh", {
            withCredentials: true,
          });

          if (refreshResponse.statusText === "OK") {
            localStorage.setItem(
              "accessToken",
              JSON.stringify(refreshResponse.data.accessToken || "")
            );
            // Retry the original request
            return axiosInstance(error.config);
          }
        } catch (refreshError) {
          logout(); // Log the user out if refresh fails
          if (window.location.pathname !== "/login") {
            window.location.href = "/login"; // Redirect to login page
          } // Redirect to login page
        }
      }

      return Promise.reject(error);
    }
  );
};

const appendBaseUrl = (url: string) =>
  `${axiosInstance.defaults.baseURL}${url}`;

export const get = (url: string, config = {}) => {
  return axiosInstance.get(appendBaseUrl(url), config);
};

export const post = (url: string, data: any, config = {}) => {
  return axiosInstance.post(appendBaseUrl(url), data, config);
};

export const put = (url: string, data: any, config = {}) => {
  return axiosInstance.put(appendBaseUrl(url), data, config);
};

export const patch = (url: string, data: any, config = {}) => {
  return axiosInstance.patch(appendBaseUrl(url), data, config);
};

export const del = (url: string, config = {}) => {
  return axiosInstance.delete(appendBaseUrl(url), config);
};

export const setupAxiosInterceptors = (logout: () => void): void => {
  createInterceptor(logout);
};

export default axiosInstance;
