import React, { useState, useEffect } from "react";
import axiosInstance from "../../auth/axiosInstance";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import dayjs, { Dayjs } from "dayjs";

import Table from "../../../core/common/dataTable/index";
import { DatePicker } from "antd";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
// Define the type for a transaction
interface Transaction {
  createdAt: string; // ISO date string
  amount: number;
  date: any; // Positive for income, negative for expense
  view: any;
  dataType: any;
}

interface AccountsChartProps {
  transactions: Transaction[];
  // Array of transactions
}

function formatIndianNumber(number: any) {
  const parts = number.toString().split(".");
  const integerPart = parts[0];
  const decimalPart = parts[1] ? "." + parts[1] : "";

  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);

  const formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  return otherDigits
    ? formattedOtherDigits + "," + lastThreeDigits + decimalPart
    : lastThreeDigits + decimalPart;
}
const AccountsDashboardModal = () => {
  const [view, setView] = useState<"monthly" | "daily" | "yearly" | "transaction view">("daily");

  interface AccountsChartProps {
    transactions: { date: string; amount: number }[];
    view: "daily" | "monthly" | "yearly" | "transaction view"; // Period grouping

    dataType: any;
  }
  const colors: any = {
    income: "#28a745",
    expense: "#dc3545",
    asset: "#007bff",
    liability: "#ffc107",
  };
  const AccountsChart: React.FC<AccountsChartProps> = ({ transactions, view }) => {
    const formatData = () => {
      if (!transactions || transactions.length === 0) {
        return { categories: [], values: [] };
      }

      if (view === "transaction view") {
        // No grouping, display each transaction separately
        const categories = transactions.map(({ date }) => dayjs(date).format("DD-MMM-YYYY"));
        const values = transactions.map(({ amount }) => amount);

        return { categories, values };
      }

      // Group transactions for other views
      const data: Record<string, number> = {};

      transactions.forEach(({ date, amount }) => {
        let period: string;

        if (view === "daily") {
          period = dayjs(date).format("DD-MMM-YYYY");
        } else if (view === "yearly") {
          period = dayjs(date).format("YYYY");
        } else {
          period = dayjs(date).format("MMM-YYYY");
        }

        if (!data[period]) data[period] = 0;
        data[period] += amount;
      });

      const periods = Object.keys(data).sort();
      return {
        categories: periods,
        values: periods.map((period) => data[period]),
      };
    };

    const { categories, values } = formatData();

    const chartOptions = {
      chart: {
        id: "accounts-chart",
        toolbar: { show: true },
        sparkLine: {
          enabled: true,
        },
      },
      colors: ["#3D5EE1"],
      stroke: {
        curve: "smooth" as "smooth",
        width: 2,
      },
      markers: {
        size: 4,
        hover: { size: 5 },
      },
      xaxis: {
        categories,
        title: {
          text:
            view === "transaction view"
              ? "Transaction Date"
              : view === "monthly"
              ? "Month"
              : view === "daily"
              ? "Date"
              : "Year",
        },
      },
      yaxis: {
        title: { text: "Amount" },
        labels: {
          formatter: (value: number) => `₹${value}`,
        },
      },
      tooltip: {
        y: {
          formatter: (value: number) => `₹${value.toLocaleString()}`,
        },
      },
    };

    const chartSeries = [
      {
        name: view === "transaction view" ? "Transaction Amounts" : "Amount",
        data: values,
      },
    ];

    return (
      // <div className="chart-container">
      //   {transactions.length === 0 ? (
      //     <div className="empty-graph-message">No transactions to display</div>
      //   ) : (
      //     <Chart options={chartOptions} series={chartSeries} type="line" height={350} />
      //   )}
      // </div>
      <Chart options={chartOptions} series={chartSeries} type="area" height={350} />
    );
  };
  const [journalList, setJournalList] = useState<
    {
      date: any;
      name: any;
      categoryName: any;
      subcategoryName: any;
      amount: any;
      type: any;
      incomeOpening: any;
      assetOpening: any;
      expenseOpening: any;
      liabilityOpening: any;
    }[]
  >([
    {
      date: "",
      name: "",
      categoryName: "",
      subcategoryName: "",
      amount: "",
      type: "",
      incomeOpening: 0,
      assetOpening: 0,
      expenseOpening: 0,
      liabilityOpening: 0,
    },
  ]);

  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [viewMode, setViewMode] = useState<"income" | "expenditure">("income"); // Default view mode
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const [tableData, setTableData] = useState<any[]>([]);
  const [rangeDates, setRangeDates] = useState<{ startDate: Dayjs; endDate: Dayjs }>({
    startDate: dayjs("2025-01-01"),
    endDate: dayjs(Date.now()),
  });
  const [accounts, setAccounts] = useState<any>([]);
  // Fetch data based on view mode
  const getAudits = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/schoolAccountRoutes/school/" + schoolId + "/stats", {
      params: { startDate: rangeDates.startDate, endDate: rangeDates.endDate },
    });
    setAccounts(res.data.accounts);
    setJournalList(
      res.data.transactions.map((d: any) => ({
        date: dayjs(d.date).format("YYYY-MM-DD").toString(),
        amount: d.amount,
        type: d.type,
        assetOpening: d.assetOpening,
        expenseOpening: d.expenseOpening,
        incomeOpening: d.incomeOpening,
        liabilityOpening: d.liabilityOpening,
      }))
    );
  };
  const calculateRowSpan = (data: any, key: any) => {
    const rowSpanMap: { [key: number]: number } = {};
    let lastValue: string | null = null;
    let count = 0;

    data.forEach((item: any, index: any) => {
      if (item[key] === lastValue) {
        count++;
      } else {
        if (count > 0) {
          // Set rowSpan for the previous group
          rowSpanMap[index - count - 1] = count + 1;
          for (let i = 1; i <= count; i++) {
            rowSpanMap[index - count - 1 + i] = 0;
          }
        }
        count = 0;
      }
      lastValue = item[key];
    });

    // Handle the last group
    if (count > 0) {
      rowSpanMap[data.length - count - 1] = count + 1;
      for (let i = 1; i <= count; i++) {
        rowSpanMap[data.length - count - 1 + i] = 0;
      }
    }

    return rowSpanMap;
  };

  // Generate rowSpan map for "date"
  const rowSpanMap = calculateRowSpan(journalList, "date");

  const journalColmuns = [
    {
      title: "Date",
      dataIndex: "date",
      render: (value: any, _: any, index: any) => ({
        children: dayjs(value).format("DD-MMM-YYYY"),
        props: { rowSpan: rowSpanMap[index] },
      }),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      render: (text: string, record: any) => (
        <>
          {
            <span
              className={`${record?.type === "credit" ? "text-success" : "text-danger"} fw-bold`}
            >
              ₹ {formatIndianNumber(text)}
            </span>
          }
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
    },
  ];
  // const getData = async () => {
  //   if (!schoolId) return;
  //   try {
  //     const res = await axiosInstance.get(`/category/school/${schoolId}/grouped`);
  //     setTableData(res.data[viewMode]);
  //   } catch (err) {
  //     console.error("Error fetching data:", err);
  //   }
  // };

  useEffect(() => {
    // getData();
    getAudits();
  }, [viewMode, schoolId]);

  const toggleRow = (id: number) => {
    setExpandedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-mb-flex d-block align-items-center justify-content-between mb-3">
          <div className="my-auto mb-2">
            <h3 className="page-title mb-1">Accounts Dashboard</h3>
            <nav>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <a href="#">Dashboard</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">HRM</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Accounts Dashboard
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className=" row d-flex gap-1 ">
          <div className="col-md-6  card p-4">
            <div className=" d-flex gap-4 align-items-center  justify-content-center">
              <h4 className="form-title">Date:</h4>
              <DatePicker
                value={rangeDates.startDate} // Pass the Dayjs object directly
                onChange={(date: Dayjs | null) => {
                  if (date) {
                    setRangeDates((prev) => ({ ...prev, startDate: date }));
                  }
                }}
              />
              {" - "}
              <DatePicker
                value={rangeDates.endDate} // Pass the Dayjs object directly
                onChange={(date: Dayjs | null) => {
                  if (date) {
                    setRangeDates((prev) => ({ ...prev, endDate: date }));
                  }
                }}
              />
              <button onClick={() => getAudits()} className="btn btn-primary">
                Apply
              </button>
            </div>
          </div>
          <div className="col-md-5 card p-4">
            <div className="card-header d-flex flex-column justify-content-between">
              <h4 className="title">Cash-In-Hand/Bank Accounts</h4>
              <span>{`${rangeDates.startDate.format("DD-MMM-YYYY")} to ${rangeDates.endDate.format(
                "DD-MMM-YYYY"
              )}`}</span>
              <div className="d-flex justify-content-between ">
                <span>Particulars</span>
                <span>Closing Balance</span>
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between ">
                <span>Cash-In-Hand</span>
                <span>₹10,000</span>
              </div>
              <div className="d-flex justify-content-between ">
                <span>Bank Balance</span>
                <span>₹24,32,000</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4 mb-3">
          {/* income */}
          <div className="col-md-6">
            <div className="card">
              <div className=" card-header d-flex justify-content-between">
                <div>
                  <h4 className="form-title">Income Graph </h4>
                  <div>{`${rangeDates.startDate.format(
                    "DD-MMM-YYYY"
                  )} to ${rangeDates.endDate.format("DD-MMM-YYYY")}`}</div>
                  <span className="text-primary fw-bold">
                    Income Till Now:{" ₹"}
                    {journalList && journalList.length > 0 ? journalList[0].incomeOpening : 0}
                  </span>
                </div>
                <div className="dropdown mb-4">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    id="viewSelector"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {view.charAt(0).toUpperCase() + view.slice(1)}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="viewSelector">
                    <li>
                      <button className="dropdown-item" onClick={() => setView("daily")}>
                        Daily
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={() => setView("monthly")}>
                        Monthly
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={() => setView("yearly")}>
                        Yearly
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={() => setView("transaction view")}>
                        Transactions Wise
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-body">
                {accounts && accounts?.incomes && (
                  <AccountsChart dataType={"income"} view={view} transactions={accounts?.incomes} />
                )}
              </div>
              <div className="card-footer">
                {" "}
                <span className="text-primary fw-bold">
                  Final Income:{"  ₹"}
                  {journalList && journalList.length > 0 && accounts && accounts.incomes
                    ? journalList[0].incomeOpening +
                      accounts.incomes.reduce((acc: any, l: any) => acc + l.amount, 0)
                    : 0}
                </span>
              </div>
            </div>
          </div>
          {/* expense */}
          <div className="col-md-6">
            <div className="card">
              <div className=" card-header d-flex justify-content-between">
                <div>
                  <h4 className="form-title">Expenses Graph </h4>
                  <div>{`${rangeDates.startDate.format(
                    "DD-MMM-YYYY"
                  )} to ${rangeDates.endDate.format("DD-MMM-YYYY")}`}</div>
                  <span className="fw-bold text-primary">
                    Expenses Till Now:{"  ₹"}
                    {journalList && journalList.length > 0 ? journalList[0].expenseOpening : 0}
                  </span>
                </div>
                <div className="dropdown mb-4">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    id="viewSelector"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {view.charAt(0).toUpperCase() + view.slice(1)}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="viewSelector">
                    <li>
                      <button className="dropdown-item" onClick={() => setView("daily")}>
                        Daily
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={() => setView("monthly")}>
                        Monthly
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={() => setView("yearly")}>
                        Yearly
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={() => setView("transaction view")}>
                        Transactions Wise
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-body">
                {accounts && accounts?.expenditures && (
                  <AccountsChart
                    dataType={"expense"}
                    view={view}
                    transactions={accounts?.expenditures}
                  />
                )}
              </div>
              <div className="card-footer">
                {" "}
                <span className="text-primary fw-bold">
                  Final Expenses:{"  ₹"}
                  {journalList && journalList.length > 0 && accounts && accounts.expenditures
                    ? journalList[0].expenseOpening +
                      accounts.expenditures.reduce((acc: any, l: any) => acc + l.amount, 0)
                    : 0}
                </span>
              </div>
            </div>
          </div>
          {/* asset */}
          <div className="col-md-6">
            <div className="card">
              <div className=" card-header d-flex justify-content-between">
                <div>
                  <h4 className="form-title">Assets Graph </h4>
                  <div>{`${rangeDates.startDate.format(
                    "DD-MMM-YYYY"
                  )} to ${rangeDates.endDate.format("DD-MMM-YYYY")}`}</div>
                  <span className="text-primary fw-bold">
                    Opening Balance:{" ₹"}
                    {journalList && journalList.length > 0 ? journalList[0].assetOpening : 0}
                  </span>
                </div>
                <div className="dropdown mb-4">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    id="viewSelector"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {view.charAt(0).toUpperCase() + view.slice(1)}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="viewSelector">
                    <li>
                      <button className="dropdown-item" onClick={() => setView("daily")}>
                        Daily
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={() => setView("monthly")}>
                        Monthly
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={() => setView("yearly")}>
                        Yearly
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={() => setView("transaction view")}>
                        Transactions Wise
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-body">
                {accounts && accounts?.assets && (
                  <AccountsChart dataType={"asset"} view={view} transactions={accounts?.assets} />
                )}
              </div>
              <div className="card-footer">
                {" "}
                <span className="text-primary fw-bold">
                  Closing Balance:{"  ₹"}
                  {journalList && journalList.length > 0 && accounts && accounts.assets
                    ? journalList[0].assetOpening +
                      accounts.assets.reduce((acc: any, l: any) => acc + l.amount, 0)
                    : 0}
                </span>
              </div>
            </div>
          </div>
          {/* liability */}
          <div className="col-md-6">
            <div className="card">
              <div className=" card-header d-flex justify-content-between">
                <div>
                  <h4 className="form-title">Liabilities Graph </h4>
                  <div>{`${rangeDates.startDate.format(
                    "DD-MMM-YYYY"
                  )} to ${rangeDates.endDate.format("DD-MMM-YYYY")}`}</div>
                  <span className="text-primary fw-bold">
                    Opening Balance:{"  ₹"}
                    {journalList && journalList.length > 0 ? journalList[0].liabilityOpening : 0}
                  </span>
                </div>
                <div className="dropdown mb-4">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    id="viewSelector"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {view.charAt(0).toUpperCase() + view.slice(1)}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="viewSelector">
                    <li>
                      <button className="dropdown-item" onClick={() => setView("daily")}>
                        Daily
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={() => setView("monthly")}>
                        Monthly
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={() => setView("yearly")}>
                        Yearly
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={() => setView("transaction view")}>
                        Transactions Wise
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-body">
                {accounts && accounts?.liabilities && (
                  <AccountsChart
                    dataType={"lability"}
                    view={view}
                    transactions={accounts?.liabilities}
                  />
                )}
              </div>
              <div className="card-footer">
                {" "}
                <span className="text-primary fw-bold">
                  Closing Balance:{"  ₹"}
                  {journalList && journalList.length > 0 && accounts && accounts.liabilities
                    ? journalList[0].liabilityOpening +
                      accounts.liabilities.reduce((acc: any, l: any) => acc + l.amount, 0)
                    : 0}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center flex-column ">
              <h4 className="form-title">Transactions List</h4>
              <div>{`${rangeDates.startDate.format("DD-MMM-YYYY")} to ${rangeDates.endDate.format(
                "DD-MMM-YYYY"
              )}`}</div>
            </div>
            <div className="card-body">
              <Table
                size={"small"}
                dataSource={journalList}
                columns={journalColmuns}
                useCustomStyles={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountsDashboardModal;
