import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mobileSidebar: false,
  miniSidebar: false,
  expandMenu: false,
  sidebarData: [],
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setMobileSidebar: (state, { payload }) => {
      state.mobileSidebar = payload;
    },
    setMiniSidebar: (state, { payload }) => {
      state.miniSidebar = payload;
    },
    toggleMiniSidebar: (state) => {
      state.miniSidebar = !state.miniSidebar;
    },
    setExpandMenu: (state, { payload }) => {
      state.expandMenu = payload;
    },
    setSidebarData: (state, { payload }) => {
      state.sidebarData = payload;
    },
  },
});

export const {
  setMobileSidebar,
  setMiniSidebar,
  setExpandMenu,
  toggleMiniSidebar,
  setSidebarData,
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
