import React, { useEffect } from "react";
import {
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { authRoutes, publicRoutes } from "./router.link";
import Feature from "../feature";
import AuthFeature from "../authFeature";
import Login from "../auth/login/login";
import PrivateRoute from "./privateRoute";
import { SidebarData } from "../../core/data/json/sidebarData";
import { AuthProvider } from "../auth/authProvider";
import AdminDashboard from "../mainMenu/adminDashboard";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../auth/axiosInstance";
import { userSliceActions } from "../../core/data/redux/userSlice";

const ALLRoutes: React.FC = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route element={<Feature />}>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              key={idx}
              element={<PrivateRoute element={route.element} />}
            />
          ))}
        </Route>

        <Route element={<AuthFeature />}>
          {authRoutes.map((route, idx) => (
            <Route
              path={route.path}
              key={idx}
              element={<PrivateRoute element={route.element} />}
            />
            // <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>
      </Routes>
    </AuthProvider>
  );

  /*return (
    <>
     <AuthProvider>
      <Routes>
        <Route path="/"  element={<Login/>} />
        <Route element={<Feature />}>
          {publicRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>

        <Route element={<AuthFeature />}>
          {authRoutes.map((route, idx) => (
             <Route path={route.path} element={<PrivateRoute element={route.element} isAuthenticated={isAuthenticated} />} />
           // <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>
      </Routes>
      </AuthProvider>
    </>
  );
  */
};

export default ALLRoutes;
