import React from "react";
import { useSelector } from "react-redux";

interface AdmitCardProps {
  id: number;
  name: string;
  class_name: string;
  section: string;
  roll_number: string;
  exam_time: string;
  exam_name: string;
}

const AdmitCardPreview: React.FC<AdmitCardProps> = ({
  id,
  name,
  class_name,
  section,
  roll_number,
  exam_time,
  exam_name,
}) => {
  const { schoolName, schoolLocation } = useSelector((state: any) => state.userSlice);
  return (
    <>
      <div
        id={`admit_card${roll_number}`}
        className="row  p-2"
        style={{ border: "3px solid black", position: "absolute", left: "-9999px" }}
      >
        {/* upper div */}
        <div className=" row">
          <div className="col-md-1 d-flex justify-content-center align-items-center">LOGO</div>
          <div className="col-md-10 d-flex flex-column align-items-center">
            <div className="" style={{ color: "black" }}>
              <h4 className="form-title">{schoolName}</h4>
            </div>
            <div className="">{schoolLocation}</div>
            <div className="mt-3">
              <h5
                className="form-title  px-2 py-1"
                style={{ backgroundColor: "black", color: "white", borderRadius: "20px" }}
              >
                Admit Card
              </h5>
            </div>
          </div>
          <div className="col-md-1 d-flex justify-content-center align-items-center">LOGO</div>
        </div>
        {/* uperr-midlle divs */}
        <div className="row mb-2 mt-2 d-flex justify-content-center">{"End Term Exam 2024-25"}</div>
        <div className="row mb-2 mt-2"></div>
        {/* middle div */}
        <div className="row mb-3 mt-5">
          <div className="col-md-10">
            <div className="row mb-2 mt-2  d-flex justify-content-start">
              <div className="">Student Name:{name}</div>
            </div>
            <div className="mb-2  d-flex justify-content-start gap-3">
              <div className="">Clas: {class_name}</div>
              <div className="">Section: {section}</div>
            </div>
            <div className="mb-2  d-flex justify-content-start">
              <div className="">Roll No.: {roll_number}</div>
            </div>
            <div className="mb-2  d-flex justify-content-start">
              <div className="">DOB.: {"22-Aug-2003"}</div>
            </div>
          </div>
          <div
            className="col-md-2 d-flex justify-content-center align-items-center "
            style={{ border: "1px solid black" }}
          >
            Photo
          </div>
        </div>
        {/* lower div */}
        <div className="d-flex justify-content-between mt-5">
          <div className="d-flex flex-column align-items-center">
            <div>______________________</div>
            <div>Invigilator Signature</div>
          </div>

          <div className="d-flex flex-column align-items-center">
            <div>______________________</div>
            <div>Principla Signature</div>
          </div>
          <div className="d-flex flex-column align-items-center">
            <div>______________________</div>
            <div>Student Signature</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdmitCardPreview;
