import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Table from "../../core/common/dataTable/index";
// import { departments } from "../../../core/data/json/departments";
import { useSelector } from "react-redux";
import { all_routes } from "../router/all_routes";
import axiosInstance from "../auth/axiosInstance";
import CommonSelect from "../../core/common/commonSelect";
import PredefinedDateRanges from "../../core/common/datePicker";
import {
  activeList,
  category2,
  departmentSelect,
  school,
} from "../../core/common/selectoption/selectoption";
import { TableData } from "../../core/data/interface";
import { Button, DatePicker } from "antd";
import dayjs from "dayjs";

const Assets = () => {
  const routes = all_routes;
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const [activeDue, setActiveDue] = useState("dues");
  console.log("💥💥", schoolId);
  const [vendorOptions, setVendorOptions] = useState<any>([]);
  const getVendorOptions = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/vendor/school/" + schoolId);
    const data = res.data.map((v: any) => ({ value: v.id, label: v.name }));
    setVendorOptions(data);
  };

  const [duesList, setDuesList] = useState<any>([]);

  const getDues = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get(`/asset/school/${schoolId}/dues`);
    setDuesList(res.data);
  };
  const getRecivables = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get(`/asset/school/${schoolId}/receivables`);
    setDuesList(res.data);
  };
  const duesColumn = [
    {
      title: "Asset Name",
      dataIndex: "asset_name",
    },
    {
      title: "Asset Desc",
      dataIndex: "asset_desc",
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
    },
    {
      title: "Vendor Contact No.",
      dataIndex: "vendor_phone",
    },
    {
      title: "Vendor Address",
      dataIndex: "vendor_address",
    },
    {
      title: `Amount ${activeDue === "dues" ? "Paid" : "Recieved"}`,
      dataIndex: `${activeDue === "dues" ? "amt_paid" : "amt_recieved"}`,
    },
    {
      title: "Amount to Be Paid(Due)",
      dataIndex: "due_amount",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: string, record: any) => (
        <>
          <Link
            onClick={() => setAssetData(record)}
            data-bs-toggle="modal"
            data-bs-target="#pay_dues"
            to="#"
            className="btn btn-primary"
          >
            {activeDue === "dues" ? "Pay" : "Collect"}
          </Link>
        </>
      ),
    },
  ];
  const [paymentOptions, setPaymentOptions] = useState<any>([]);
  const getPaymentOptions = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/paymentMethod/school/" + schoolId);
    const data = res.data.map((p: any) => ({
      value: p.id,
      label: p.methodName,
      balance: p.currentBalance,
    }));
    setPaymentOptions(data);
  };
  const [assetsList, setAssetsList] = React.useState([]);
  const [assetData, setAssetData] = useState<any>({
    assetData: {
      title: "",
      description: "",
      category: "",
      value: "",
      purchaseDate: dayjs(new Date()),
      schoolId,
      additionalAmount: 0,
      sellingPrice: 0,
      amountRecieved: 0,
      paymentMethodId: 1,
    },
  });
  const assetOptions = assetsList.map((asset: any) => ({
    id: asset.id,
    assetValue: asset.value,
    value: asset.id,
    label: asset.title,
  }));
  console.log(assetData);

  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };

  const getAllAssets = async () => {
    if (!schoolId) return;

    const res = await axiosInstance.get(`/asset/school/${schoolId}`);
    const data = res.data;
    setAssetsList(data);
  };
  const handlePayVendor = async () => {
    const res = await axiosInstance.post("/asset/pay-additional-amount", assetData);
    getAllAssets();
    getPaymentOptions();
    getVendorOptions();
  };
  const handleReciveFromVendor = async () => {
    // console.log(assetData);

    const res = await axiosInstance.post("/asset/receive-partial-payment", assetData);
    getAllAssets();
    getPaymentOptions();
    getVendorOptions();
  };
  const handlePurchaseAsset = async () => {
    // console.log(assetData);

    const res = await axiosInstance.post("/asset/purchase-asset", assetData);
    getAllAssets();
    getPaymentOptions();
    getVendorOptions();
  };

  const handleSellAsset = async () => {
    // console.log(assetData);

    const res = await axiosInstance.post("/asset/sell-asset", assetData);
    getAllAssets();
    getVendorOptions();
  };

  useEffect(() => {
    getAllAssets();
    getVendorOptions();
    getPaymentOptions();
    // getDues();
    // getRecivables();
  }, [schoolId]);
  // const columns = [
  //   {
  //     title: "ID",
  //     dataIndex: "id",
  //     render: (text: string, record: any, index: number) => (
  //       <>
  //         <Link to="#" className="link-primary">
  //           {record.id}
  //         </Link>
  //       </>
  //     ),
  //     sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
  //   },
  //   {
  //     title: "Name",
  //     dataIndex: "title",
  //     // sorter: (a: TableData, b: TableData) => a.department.length - b.department.length,
  //   },
  //   {
  //     title: "Description",
  //     dataIndex: "description",
  //     // sorter: (a: TableData, b: TableData) => a.department.length - b.department.length,
  //   },
  //   {
  //     title: "Dealing Person",
  //     dataIndex: "dealingPerson",
  //     // sorter: (a: TableData, b: TableData) => a.department.length - b.department.length,
  //   },
  //   {
  //     title: "Purchase Date",
  //     dataIndex: "purchaseDate",
  //     // sorter: (a: TableData, b: TableData) => a.department.length - b.department.length,
  //   },
  //   {
  //     title: "Category",
  //     dataIndex: "category",
  //     // sorter: (a: TableData, b: TableData) => a.department.length - b.department.length,
  //   },
  //   {
  //     title: "Value",
  //     dataIndex: "value",
  //     // sorter: (a: TableData, b: TableData) => a.department.length - b.department.length,
  //   },
  //   {
  //     title: "Status",
  //     dataIndex: "status",
  //     // sorter: (a: TableData, b: TableData) => a.department.length - b.department.length,
  //   },

  //   // {
  //   //   title: "Action",
  //   //   dataIndex: "action",
  //   //   render: (text: string, record: any) => (
  //   //     <>
  //   //       <div className="d-flex align-items-center">
  //   //         <div className="dropdown">
  //   //           <Link
  //   //             to="#"
  //   //             className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
  //   //             data-bs-toggle="dropdown"
  //   //             aria-expanded="false"
  //   //           >
  //   //             <i className="ti ti-dots-vertical fs-14" />
  //   //           </Link>
  //   //           <ul className="dropdown-menu dropdown-menu-right p-3">
  //   //             <li>
  //   //               <Link
  //   //                 onClick={() => {
  //   //                   setEditVendorData(record);
  //   //                 }}
  //   //                 className="dropdown-item rounded-1"
  //   //                 to="#"
  //   //                 data-bs-toggle="modal"
  //   //                 data-bs-target="#edit_vendor"
  //   //               >
  //   //                 <i className="ti ti-edit-circle me-2" />
  //   //                 Edit
  //   //               </Link>
  //   //             </li>
  //   //             <li>
  //   //               <Link
  //   //                 onClick={() => setEditVendorData(record)}
  //   //                 className="dropdown-item rounded-1"
  //   //                 to="#"
  //   //                 data-bs-toggle="modal"
  //   //                 data-bs-target="#delete-modal"
  //   //               >
  //   //                 <i className="ti ti-trash-x me-2" />
  //   //                 Delete
  //   //               </Link>
  //   //             </li>
  //   //           </ul>
  //   //         </div>
  //   //       </div>
  //   //     </>
  //   //   ),
  //   // },
  // ];

  ///////////////////////////////////////////////////////////////new asset variables/////////////////////////////////////////
  ////////////////////////////////////////
  /////////////////////////
  ////////////////////
  const [view, setView] = useState("list");
  const [activeLedger, setActiveLedger] = useState<any>({});

  const getLedgerTransactions = async () => {
    if (!schoolId || !activeLedger?.id) return;
    const res = await axiosInstance.get(
      "/schoolAccountRoutes/school/" + schoolId + "/ledger/" + activeLedger?.name
    );
    setActiveLedger((prev: any) => ({ ...prev, transactions: res.data }));
  };
  useEffect(() => {
    if (view === "single") getLedgerTransactions();
  }, [view]);
  const ledgerColms = [
    {
      title: "Description",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text: string) => <>{dayjs(text).format("DD-MMM-YYYY")}</>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text: string) => (
        <>
          {
            <span className={`badge ${text === "credit" ? "badge-success" : "badge-danger"}`}>
              {text}
            </span>
          }
        </>
      ),
    },
    {
      title: "Invoice No",
      dataIndex: "invoiceNo",
    },
    {
      title: "Vch. No",
      dataIndex: "vchNo",
    },
  ];
  const [editData, setEditData] = useState<any>();
  const [ledgerList, setLedgerList] = useState<any>();
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setEditData((prev: any) => ({ ...prev, [name]: value }));
  };
  const [expenseList, setexpenseList] = useState([]);

  const getAll = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/ledger/school/" + schoolId);

    setLedgerList(res.data.filter((d: any) => d.ledgerType === "asset"));
  };
  const editOne = async (e: any) => {
    e.preventDefault();
    const { id, ...data } = editData;
    await axiosInstance.patch("/ledger/" + editData.id, data);
    getAll();
  };
  const deleteOne = async (e: any) => {
    e.preventDefault();
    await axiosInstance.delete("/ledger/" + editData.id);
    getAll();
  };
  const addOne = async (e: any) => {
    e.preventDefault();
    // console.log(editData);

    await axiosInstance.post("/ledger", editData);
    getAll();
  };
  console.log("🤣🤣", schoolId);

  const ledgerCategoryOptions = [
    { label: "Select", value: "Select" },
    { label: "Payment Method", value: "payment" },
    { label: "Fees Type", value: "feesType" },
    { label: "Salary Type", value: "salaryType" },
    { label: "Other", value: "other" },
  ];
  const defaultCatOption =
    ledgerCategoryOptions.find((option: any) => option.value === editData?.categoryType) || null;

  useEffect(() => {
    getAll();
  }, [schoolId]);
  useEffect(() => {
    getAll();
  }, [schoolId]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: any, record: any) => (
        <Link
          to="#"
          onClick={() => {
            setView("single");
            setActiveLedger(record);
          }}
          className="link-primary"
        >
          {text}
        </Link>
      ),
      sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },
    {
      title: "Ledger",
      dataIndex: "name",
      sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },

    {
      title: "Ledger Type",
      dataIndex: "ledgerType",
      // sorter: (a: TableData, b: TableData) => a.description.length - b.description.length,
    },
    {
      title: "Category Type",
      dataIndex: "categoryType",
      // sorter: (a: TableData, b: TableData) => a.description.length - b.description.length,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      // sorter: (a: TableData, b: TableData) => a.description.length - b.description.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <>
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_ledger"
                    onClick={() => {
                      setEditData(record);
                      console.log(editData);
                    }}
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Edit
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                    onClick={() => {
                      setEditData(record);
                    }}
                  >
                    <i className="ti ti-trash-x me-2" />
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];
  return (
    // <div>
    //   <div className="page-wrapper">
    //     <div className="content">
    //       {/* Page Header */}
    //       <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
    //         <div className="my-auto mb-2">
    //           <h3 className="page-title mb-1">Assets</h3>
    //           <nav>
    //             <ol className="breadcrumb mb-0">
    //               <li className="breadcrumb-item">
    //                 <Link to={routes.adminDashboard}>Dashboard</Link>
    //               </li>
    //               <li className="breadcrumb-item">
    //                 <Link to="#">HRM</Link>
    //               </li>
    //               <li className="breadcrumb-item active" aria-current="page">
    //                 Assets
    //               </li>
    //             </ol>
    //           </nav>
    //         </div>
    //         <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
    //           <div className="dropdown">
    //             <button
    //               className="btn btn-primary dropdown-toggle"
    //               type="button"
    //               id="actionDropdown"
    //               data-bs-toggle="dropdown"
    //               aria-expanded="false"
    //             >
    //               Actions
    //             </button>
    //             <ul className="dropdown-menu" aria-labelledby="actionDropdown">
    //               <li>
    //                 <Link
    //                   to="#"
    //                   onClick={() => {
    //                     setActiveDue("recieve");
    //                     getRecivables();
    //                     // setAssetData((prev: any) => {});
    //                   }}
    //                   className="dropdown-item d-flex align-items-center"
    //                   data-bs-toggle="modal"
    //                   data-bs-target="#clear_dues"
    //                 >
    //                   <i className="fas fa-hand-holding-usd me-2"></i>
    //                   Collect Balance
    //                 </Link>
    //               </li>
    //               <li>
    //                 <Link
    //                   to="#"
    //                   onClick={() => {
    //                     setActiveDue("dues");
    //                     getDues();
    //                     setAssetData({});
    //                   }}
    //                   className="dropdown-item d-flex align-items-center"
    //                   data-bs-toggle="modal"
    //                   data-bs-target="#clear_dues"
    //                 >
    //                   <i className="fas fa-clipboard-check me-2"></i>
    //                   Clear Dues
    //                 </Link>
    //               </li>
    //               <li>
    //                 <Link
    //                   to="#"
    //                   className="dropdown-item d-flex align-items-center"
    //                   data-bs-toggle="modal"
    //                   data-bs-target="#add_department"
    //                 >
    //                   <i className="fas fa-shopping-bag me-2"></i>
    //                   Purchase Asset
    //                 </Link>
    //               </li>
    //               <li>
    //                 {" "}
    //                 <Link
    //                   to="#"
    //                   onClick={() => setAssetData({})}
    //                   className="dropdown-item  d-flex align-items-center"
    //                   data-bs-toggle="modal"
    //                   data-bs-target="#sell_asset"
    //                 >
    //                   <i className="fas fa-coins me-2"></i> Sell Asset
    //                 </Link>
    //               </li>
    //             </ul>
    //           </div>
    //           <div>
    //             <button onClick={()=>addOne()}>Add Asset</button>
    //           </div>
    //         </div>
    //       </div>
    //       {/* /Page Header */}
    //       {/* Students List */}
    //       <div className="card">
    //         <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
    //           <h4 className="mb-3">Assets List</h4>
    //           <div className="d-flex align-items-center flex-wrap">
    //             <div className="input-icon-start mb-3 me-2 position-relative">
    //               <PredefinedDateRanges />
    //             </div>
    //             <div className="dropdown mb-3 me-2">
    //               <Link
    //                 to="#"
    //                 className="btn btn-outline-light bg-white dropdown-toggle"
    //                 data-bs-toggle="dropdown"
    //                 data-bs-auto-close="outside"
    //               >
    //                 <i className="ti ti-filter me-2" />
    //                 Filter
    //               </Link>
    //               <div className="dropdown-menu drop-width" ref={dropdownMenuRef}>
    //                 <form>
    //                   <div className="d-flex align-items-center border-bottom p-3">
    //                     <h4>Filter</h4>
    //                   </div>
    //                   <div className="p-3 border-bottom">
    //                     <div className="row">
    //                       <div className="col-md-12">
    //                         <div className="mb-3">
    //                           <label className="form-label">Assets</label>
    //                           <CommonSelect className="select" options={departmentSelect} />
    //                         </div>
    //                       </div>
    //                       <div className="col-md-12">
    //                         <div className="mb-0">
    //                           <label className="form-label">Status</label>

    //                           <CommonSelect className="select" options={activeList} />
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="p-3 d-flex align-items-center justify-content-end">
    //                     <Link to="#" className="btn btn-light me-3">
    //                       Reset
    //                     </Link>
    //                     <Link to="#" className="btn btn-primary" onClick={handleApplyClick}>
    //                       Apply
    //                     </Link>
    //                   </div>
    //                 </form>
    //               </div>
    //             </div>
    //             <div className="dropdown mb-3">
    //               <Link
    //                 to="#"
    //                 className="btn btn-outline-light bg-white dropdown-toggle"
    //                 data-bs-toggle="dropdown"
    //               >
    //                 <i className="ti ti-sort-ascending-2 me-2" />
    //                 Sort by A-Z{" "}
    //               </Link>
    //               <ul className="dropdown-menu p-3">
    //                 <li>
    //                   <Link to="#" className="dropdown-item rounded-1 active">
    //                     Ascending
    //                   </Link>
    //                 </li>
    //                 <li>
    //                   <Link to="#" className="dropdown-item rounded-1">
    //                     Descending
    //                   </Link>
    //                 </li>
    //                 <li>
    //                   <Link to="#" className="dropdown-item rounded-1">
    //                     Recently Viewed
    //                   </Link>
    //                 </li>
    //                 <li>
    //                   <Link to="#" className="dropdown-item rounded-1">
    //                     Recently Added
    //                   </Link>
    //                 </li>
    //               </ul>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="card-body p-0 py-3">
    //           {/* Student List */}
    //           <Table columns={columns} dataSource={assetsList} Selection={true} />
    //           {/* /Student List */}
    //         </div>
    //       </div>
    //       {/* /Students List */}
    //     </div>
    //   </div>
    //   <>
    //     {/* Purchase asset */}
    //     <div className="modal fade" id="add_department">
    //       <div className="modal-dialog modal-dialog-centered  modal-xl">
    //         <div className="modal-content">
    //           <div className="modal-header">
    //             <h4 className="modal-title">Purchase Asset</h4>
    //             <button
    //               type="button"
    //               className="btn-close custom-btn-close"
    //               data-bs-dismiss="modal"
    //               aria-label="Close"
    //             >
    //               <i className="ti ti-x" />
    //             </button>
    //           </div>
    //           <form>
    //             <div className="modal-body">
    //               <div className="row">
    //                 {/* Asset Details */}
    //                 <div className="col-md-4">
    //                   <h5 className="form-label mb-4">Asset Details</h5>
    //                   <div className="mb-3">
    //                     <label className="form-label">Asset Name</label>
    //                     <input
    //                       value={assetData?.assetData?.title}
    //                       onChange={(e: any) =>
    //                         setAssetData((prev: any) => ({
    //                           ...prev,
    //                           assetData: { ...prev.assetData, title: e.target.value },
    //                         }))
    //                       }
    //                       type="text"
    //                       className="form-control"
    //                     />
    //                   </div>
    //                   <div className="mb-3">
    //                     <label className="form-label">Asset Description</label>
    //                     <input
    //                       value={assetData?.assetData?.description}
    //                       onChange={(e: any) =>
    //                         setAssetData((prev: any) => ({
    //                           ...prev,
    //                           assetData: { ...prev.assetData, description: e.target.value },
    //                         }))
    //                       }
    //                       type="text"
    //                       className="form-control"
    //                     />
    //                   </div>
    //                   <div className="mb-3">
    //                     <label className="form-label">Purchase Date</label>
    //                     <DatePicker
    //                       format={{ format: "DD-MMM-YYYY", type: "mask" }}
    //                       className="form-control datetimepicker"
    //                       onChange={(date) =>
    //                         setAssetData((prev: any) => ({
    //                           ...prev,
    //                           assetData: { ...prev.assetData, purchaseDate: date },
    //                         }))
    //                       }
    //                     />
    //                   </div>
    //                   <div className="mb-3">
    //                     <label className="form-label"> Category</label>
    //                     <input
    //                       className="form-control"
    //                       type="text"
    //                       value={assetData?.assetData?.category}
    //                       onChange={(e: any) =>
    //                         setAssetData((prev: any) => ({
    //                           ...prev,
    //                           assetData: { ...prev.assetData, category: e.target.value },
    //                         }))
    //                       }
    //                     />
    //                   </div>
    //                   {/*  */}
    //                   <div className="mb-3">
    //                     <label className="form-label">Price</label>
    //                     <input
    //                       className="form-control"
    //                       type="text"
    //                       value={assetData?.assetData?.value}
    //                       onChange={(e: any) =>
    //                         setAssetData((prev: any) => ({
    //                           ...prev,
    //                           assetData: { ...prev.assetData, value: e.target.value },
    //                         }))
    //                       }
    //                     />
    //                   </div>
    //                   {/*  */}
    //                 </div>
    //                 {/*  Vendor Details*/}
    //                 <div className="col-md-4">
    //                   <h5 className="form-label mb-4">Vendor Details</h5>
    //                   <div className="mb-3">
    //                     <label className="form-label">Purchased From: </label>
    //                     <Select
    //                       options={vendorOptions}
    //                       onChange={(obj: any) =>
    //                         setAssetData((prev: any) => ({
    //                           ...prev,
    //                           vendorDetails: {
    //                             id: obj.value,
    //                             name: obj.label,
    //                           },
    //                         }))
    //                       }
    //                     />
    //                   </div>
    //                   <div className="mb-3">
    //                     <label className="form-label">Amount Paid:</label>
    //                     <input
    //                       className="form-control"
    //                       value={assetData?.assetData?.amountPaid}
    //                       onChange={(e: any) =>
    //                         setAssetData((prev: any) => ({ ...prev, amountPaid: e.target.value }))
    //                       }
    //                     />
    //                   </div>
    //                 </div>

    //                 {/* other details */}

    //                 {/* Payment Details */}
    //                 <div className="col-md-4">
    //                   <h5 className="form-label mb-4">Payment Details</h5>
    //                   <div className="mb-3">
    //                     <label className="form-label">Payment Method </label>
    //                     <Select
    //                       options={paymentOptions}
    //                       onChange={(obj: any) =>
    //                         setAssetData((prev: any) => ({
    //                           ...prev,
    //                           paymentMethodDetails: {
    //                             paymentMethodId: obj.value,
    //                             methodName: obj.label,
    //                             balance: obj.balance,
    //                           },
    //                         }))
    //                       }
    //                     />
    //                     <div className="input-group mb-3">
    //                       <span className="input-group-text form-label" id="basic-addon1">
    //                         Balance
    //                       </span>
    //                       <input
    //                         disabled
    //                         value={assetData?.paymentMethodDetails?.balance}
    //                         type="text"
    //                         className="form-control"
    //                         aria-label="Asset Name"
    //                         aria-describedby="basic-addon1"
    //                       />
    //                     </div>
    //                   </div>
    //                 </div>
    //                 {/* other details */}
    //               </div>
    //             </div>
    //             <div className="modal-footer">
    //               <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
    //                 Cancel
    //               </Link>
    //               <Link
    //                 onClick={() => handlePurchaseAsset()}
    //                 to="#"
    //                 className="btn btn-primary"
    //                 data-bs-dismiss="modal"
    //               >
    //                 Purchase Asset
    //               </Link>
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //     </div>
    //     {/* clear dues */}
    //     <div className="modal fade" id="clear_dues">
    //       <div className="modal-dialog modal-dialog-centered  modal-fullscreen">
    //         <div className="modal-content">
    //           <div className="modal-header">
    //             <h4 className="modal-title">{activeDue === "dues" ? "Dues" : "Collect Balance"}</h4>
    //             <button
    //               type="button"
    //               className="btn-close custom-btn-close"
    //               data-bs-dismiss="modal"
    //               aria-label="Close"
    //             >
    //               <i className="ti ti-x" />
    //             </button>
    //           </div>
    //           <div className="mt-4">
    //             {duesList && duesList.length > 0 ? (
    //               <Table columns={duesColumn} dataSource={duesList} />
    //             ) : (
    //               <div>No Dues </div>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     {/* pay dues/recive balance */}
    //     <div className="modal fade" id="pay_dues">
    //       <div className="modal-dialog modal-dialog-centered">
    //         <div className="modal-content">
    //           <div className="modal-header">
    //             <h4 className="modal-title">
    //               {" "}
    //               {activeDue === "dues" ? "Pay Dues" : "Collect Balance"}
    //             </h4>
    //             <button
    //               type="button"
    //               className="btn-close custom-btn-close"
    //               data-bs-dismiss="modal"
    //               aria-label="Close"
    //             >
    //               <i className="ti ti-x" />
    //             </button>
    //           </div>
    //           <div className="mt-4 modal-body ">
    //             <div className="row">
    //               <div className=" col-md-6">
    //                 <div className="mb-1">Asset ID:{assetData.asset_id}</div>
    //                 <div className="mb-1">Asset Name:{assetData.asset_name}</div>
    //                 <div className="mb-1">Asset Description:{assetData.asset_desc}</div>
    //               </div>
    //               <div className="col-md-6">
    //                 <div className="mb-1">Vendor ID:{assetData.vendor_id}</div>
    //                 <div className="mb-1">Vendor Name:{assetData.vendor_name}</div>
    //                 <div className="mb-1">Vendor Contact No.:{assetData.vendor_phone}</div>
    //                 <div className="mb-3">Vendor Address.:{assetData.vendor_address}</div>
    //               </div>
    //             </div>
    //             <div className="row">
    //               <div className="col-md-6">
    //                 <div className="mb-1 input-group ">
    //                   <span className="form-label input-group-text">
    //                     Amt {activeDue === "dues" ? "Paid" : "Recieved"}
    //                   </span>
    //                   <input
    //                     disabled
    //                     className="form-control"
    //                     value={activeDue === "dues" ? assetData?.amt_paid : assetData?.amt_recieved}
    //                   />
    //                 </div>
    //               </div>
    //               <div className="col-md-6">
    //                 <div className="mb-1 input-group ">
    //                   <span className="form-label input-group-text">Amt Due</span>
    //                   <input disabled className="form-control" value={assetData?.due_amount} />
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="row mt-3">
    //               <h5 className=" form-title mb-3">
    //                 {activeDue === "dues" ? "Select Payment Method" : "Select Recieving Method"}
    //               </h5>
    //               <div className="col-md-6">
    //                 <Select
    //                   options={paymentOptions}
    //                   onChange={(obj: any) =>
    //                     setAssetData((prev: any) => ({
    //                       ...prev,
    //                       paymentMethodId: obj.value,
    //                       paymentMethodBalance: obj.balance,
    //                     }))
    //                   }
    //                   defaultValue={{}}
    //                 />
    //               </div>
    //               <div className="col-md-6">
    //                 <div className="mb-1 input-group ">
    //                   <span className="form-label input-group-text">Balance</span>
    //                   <input
    //                     disabled
    //                     className="form-control"
    //                     value={assetData?.paymentMethodBalance}
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="row mt-3">
    //               <h5 className="form-title mb-3">
    //                 Amount to {activeDue === "dues" ? "Pay" : "Recieve"}
    //               </h5>
    //               <div className="col-md-8 mb-3">
    //                 <input
    //                   className="form-control"
    //                   type="number"
    //                   value={assetData?.additionalAmount}
    //                   onChange={(e: any) =>
    //                     setAssetData((prev: any) => ({ ...prev, additionalAmount: e.target.value }))
    //                   }
    //                 />
    //               </div>
    //               <div className="col-md-4" data-bs-dismiss="modal">
    //                 <Link
    //                   onClick={() =>
    //                     activeDue === "dues" ? handlePayVendor() : handleReciveFromVendor()
    //                   }
    //                   to="#"
    //                   className="btn btn-primary "
    //                 >
    //                   {" "}
    //                   {activeDue === "dues" ? "Pay" : "Recieve"}{" "}
    //                   <i className="fas fa-credit-card ms-2" />
    //                 </Link>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     {/* sell asset */}
    //     <div className="modal fade" id="sell_asset">
    //       <div className="modal-dialog modal-dialog-centered modal-xl">
    //         <div className="modal-content">
    //           <div className="modal-header">
    //             <h4 className="modal-title">Sell Asset</h4>
    //             <button
    //               type="button"
    //               className="btn-close custom-btn-close"
    //               data-bs-dismiss="modal"
    //               aria-label="Close"
    //             >
    //               <i className="ti ti-x" />
    //             </button>
    //           </div>
    //           <div className="modal-body">
    //             <div className="row">
    //               <div className="col-md-4">
    //                 <h5 className="form-title mb-3">Asset Details</h5>
    //                 <label className="form-label">Select Asset</label>
    //                 <Select
    //                   className="mb-3"
    //                   options={assetOptions}
    //                   onChange={(obj: any) =>
    //                     setAssetData((prev: any) => ({
    //                       ...prev,
    //                       assetId: obj.id,
    //                       assetValue: obj.assetValue,
    //                     }))
    //                   }
    //                 />
    //                 <div className=" input-group mb-3">
    //                   <span className=" form-label input-group-text">Purchased At</span>
    //                   <input disabled className="form-control" value={assetData?.assetValue} />
    //                 </div>
    //                 <div className=" input-group mb-3">
    //                   <span className=" form-label input-group-text">Selling At</span>
    //                   <input
    //                     type="number"
    //                     className=" form-control"
    //                     value={assetData?.sellingPrice}
    //                     onChange={(e: any) =>
    //                       setAssetData((prev: any) => ({ ...prev, sellingPrice: e.target.value }))
    //                     }
    //                   />
    //                 </div>
    //               </div>
    //               <div className="col-md-4">
    //                 <h5 className="form-title mb-3">Vendor Details</h5>
    //                 <label className="form-label mb-1">Select Vendor {"(Selling To)"}</label>
    //                 <Select
    //                   className="mb-3"
    //                   options={vendorOptions}
    //                   onChange={(obj: any) =>
    //                     setAssetData((prev: any) => ({ ...prev, vendorId: obj.value }))
    //                   }
    //                 />
    //                 <div className="input-group">
    //                   <span className="form-label input-group-text">Amt. Recieved</span>
    //                   <input
    //                     className="form-control"
    //                     type="number"
    //                     value={assetData.amountRecieved}
    //                     onChange={(e: any) =>
    //                       setAssetData((prev: any) => ({ ...prev, amountReceived: e.target.value }))
    //                     }
    //                   />
    //                 </div>
    //               </div>
    //               <div className="col-md-4">
    //                 <h5 className="form-title mb-3">Payment Details</h5>
    //                 <label className="form-label mb-1">Payment Done In </label>
    //                 <Select
    //                   options={paymentOptions}
    //                   onChange={(obj: any) =>
    //                     setAssetData((prev: any) => ({ ...prev, paymentMethodId: obj.value }))
    //                   }
    //                   defaultMenuIsOpen={false}
    //                 />
    //               </div>
    //             </div>
    //           </div>
    //           <div className="modal-footer">
    //             <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
    //               Cancel
    //             </Link>
    //             <Link
    //               onClick={() => handleSellAsset()}
    //               to="#"
    //               className="btn btn-primary"
    //               data-bs-dismiss="modal"
    //             >
    //               Sell Asset
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     {/* collect balance */}
    //     <div className="modal fade" id="collect_balance">
    //       <div className="modal-dialog modal-dialog-centered modal-fullscreen">
    //         <div className="modal-content">
    //           <div className="modal-header">
    //             <h4 className="modal-title">Collect Balance</h4>
    //             <button
    //               type="button"
    //               className="btn-close custom-btn-close"
    //               data-bs-dismiss="modal"
    //               aria-label="Close"
    //             >
    //               <i className="ti ti-x" />
    //             </button>
    //           </div>
    //           <div className="modal-body">
    //             {duesList && duesList.length > 0 ? (
    //               <Table Selection={false} columns={duesColumn} dataSource={duesList} />
    //             ) : (
    //               <div>No Outstanding Balance</div>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </>
    // </div>
    <div>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1"> Ledgers (Assets)</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Finance &amp; Accounts</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Categories
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              {/* <TooltipOption /> */}
              <div className="mb-2 d-flex gap-2">
                <Link
                  to="#"
                  className="btn btn-primary d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#journal-modal"
                >
                  <i className="ti ti-clipboard-check" />
                  Journal Entry
                </Link>
                <Link
                  to="#"
                  className="btn btn-primary d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#add_ledger"
                  onClick={() => {
                    setEditData({
                      name: "",
                      ledgerType: "asset",
                      categoryType: "",
                      balance: "",
                      schoolId,
                    });
                  }}
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Add Asset
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {view === "list" && (
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                <h4 className="mb-3"> Ledger (Assets) List</h4>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="input-icon-start mb-3 me-2 position-relative">
                    <PredefinedDateRanges />
                  </div>
                  <div className="dropdown mb-3 me-2">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      <i className="ti ti-filter me-2" />
                      Filter
                    </Link>
                    <div className="dropdown-menu drop-width">
                      <form>
                        <div className="d-flex align-items-center border-bottom p-3">
                          <h4>Filter</h4>
                        </div>
                        <div className="p-3 pb-0 border-bottom">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Assets</label>
                                <CommonSelect
                                  className="select"
                                  options={category2}
                                  defaultValue={category2[0]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-3 d-flex align-items-center justify-content-end">
                          <Link to="#" className="btn btn-light me-3">
                            Reset
                          </Link>
                          <button type="submit" className="btn btn-primary">
                            Apply
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="dropdown mb-3">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-sort-ascending-2 me-2" />
                      Sort by A-Z
                    </Link>
                    <ul className="dropdown-menu p-3">
                      <li>
                        <Link to="#" className="dropdown-item rounded-1 active">
                          Ascending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Descending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Viewed
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Added
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {ledgerList && ledgerList.length > 0 && (
                <div className="card-body p-0 py-3">
                  {/* Expenses Category List */}
                  <Table dataSource={ledgerList} columns={columns} Selection={false} />
                  {/* /Expenses Category List */}
                </div>
              )}
            </div>
          )}
          {view === "single" && (
            <div className="card">
              <div className="card-header">
                <button className="btn btn-primary " onClick={() => setView("list")}>
                  <i className="fas fa-chevron-left me-2" />
                  Back
                </button>
              </div>
              <div className="card-body">
                <h4 className="form-title mb-3">Ledger Details</h4>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <span className="form-label me-1">Ledger Name:</span>
                    {activeLedger?.name}
                  </div>
                  <div className="col-md-3">
                    <span className="form-label me-1">Ledger Type:</span>
                    {activeLedger?.ledgerType}
                  </div>
                  <div className="col-md-3">
                    <span className="form-label me-1">Category Type:</span>
                    {activeLedger?.categoryType}
                  </div>
                  <div className="col-md-3">
                    <span className="form-label me-1">Balance:</span>
                    {activeLedger?.balance}
                  </div>
                </div>
                <div className="row">
                  <h4 className="form-title mb-3">Transactions</h4>
                  {activeLedger.transactions && (
                    <Table
                      dataSource={activeLedger.transactions}
                      columns={ledgerColms}
                      Selection={false}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* Add Ledger*/}
      <div className="modal fade" id="add_ledger">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Asset</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Asset Name </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={editData?.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Category Type</label>
                      <Select
                        name="categoryType"
                        onChange={(obj: any) =>
                          setEditData((prev: any) => ({ ...prev, categoryType: obj.value }))
                        }
                        options={ledgerCategoryOptions}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <label className="form-label">Ledger Type</label>

                        <Select
                          isDisabled={true}
                          value={{ label: "Asset", value: "asset" }}
                          name="ledgerType"
                        />
                      </div>
                      {(editData?.ledgerType === "asset" ||
                        editData?.ledgerType === "liability") && (
                        <div className="col-md-6">
                          <label htmlFor="" className="form-label">
                            Opening Balance
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="balance"
                            value={editData?.balance}
                            onChange={handleInputChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                  Cancel
                </Link>
                <button
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                  onClick={(e) => addOne(e)}
                >
                  Add Asset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Add Ledger */}
      {/* Edit Ledger */}
      <div className="modal fade" id="edit_ledger">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Income</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Asset Name </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={editData?.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Category Type</label>
                      <Select
                        name="categoryType"
                        className="react-select"
                        onChange={(obj: any) =>
                          setEditData((prev: any) => ({ ...prev, categoryType: obj.value }))
                        }
                        options={ledgerCategoryOptions}
                        value={defaultCatOption}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <label className="form-label">Ledger Type</label>

                        <Select
                          name="ledgerType"
                          className="react-select"
                          isDisabled={true}
                          value={{ label: "Asset", value: "asset" }}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="" className="form-label">
                          Opening Balance
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="balance"
                          value={editData?.balance}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link to="#" className="btn btn-light me-2" data-bs-dismiss="modal">
                  Cancel
                </Link>
                <button
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                  onClick={(e) => editOne(e)}
                >
                  Edit Asset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Edit Expenses Category */}
      {/* Delete Modal */}
      <div className="modal fade" id="delete-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-body text-center">
                <span className="delete-icon">
                  <i className="ti ti-trash-x" />
                </span>
                <h4>Confirm Deletion</h4>
                <p>You want to delete all the marked items, this cant be undone once you delete.</p>
                <div className="d-flex justify-content-center">
                  <Link to="#" className="btn btn-light me-3" data-bs-dismiss="modal">
                    Cancel
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                    onClick={(e) => deleteOne(e)}
                  >
                    Yes, Delete
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* /Delete Modal */}
      {/* journal modal */}
      {/* <div className="modal fade" id="journal-modal">
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Journal Modal</h4>
            <span>{dayjs(Date.now()).format("DD-MMM-YYYY").toString()}</span>

            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <div className="modal-body">
            <Table
              dataSource={journalData}
              Selection={false}
              columns={jounralColms}
              footer={footer}
            />
          </div>
          <div className="modal-footer">
            <button
              data-bs-dismiss="modal"
              onClick={(e) => handleSubmit(e)}
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div> */}
      {/* journal modal */}
    </div>
  );
};

export default Assets;
