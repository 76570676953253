export const settings = {
  dots: false,
  autoplay: false,
  arrows: false,
  slidesToShow: 2,
  margin: 24,
  speed: 500,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 776,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 567,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
export const donutChartConfig: ApexCharts.ApexOptions = {
  chart: {
    height: 218,
    width: 218,
    type: "donut",
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: true,
  },
  colors: ["#3D5EE1", "#6FCCD8", "#800080"],
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 180,
        },
      },
    },
  ],
};

export const barChartConfig: ApexCharts.ApexOptions = {
  chart: {
    height: 275,
    type: "bar",
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: true,
    horizontalAlign: "left",
    position: "top",
    fontSize: "14px",
    labels: {
      colors: "#5D6369",
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "50%",
      borderRadius: 10,
    },
  },
  colors: ["#3D5EE1", "#E9EDF4"],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  grid: {
    padding: {
      left: -8,
    },
  },
  xaxis: {
    categories: [
      "Q1: 2023",
      "Q1: 2023",
      "Q1: 2023",
      "Q1: 2023",
      "Q1: 2023",
      "uQ1: 2023l",
      "Q1: 2023",
      "Q1: 2023",
      "Q1: 2023",
    ],
  },
  yaxis: {
    tickAmount: 3,
    labels: {
      offsetX: -15,
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (val: any) {
        return val;
      },
    },
  },
};

export type DonutChartData = {
  name: string;
  labels: string[];
  data: number[];
};

export type BarChartData = {
  name: string;
  data: number[];
};

export type Application = {
  name: string;
  requestedOn: string;
  class: string;
};
