import React, { useEffect, useRef, useState } from "react";
import { staffAttendance } from "../../../core/data/json/staff-attendance";
import { TableData } from "../../../core/data/interface";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import Table from "../../../core/common/dataTable/index";
import PredefinedDateRanges from "../../../core/common/datePicker";
import CommonSelect from "../../../core/common/commonSelect";
import {
  departmentName,
  designationName,
  studentName,
  teacherId,
} from "../../../core/common/selectoption/selectoption";
import { all_routes } from "../../router/all_routes";
import TooltipOption from "../../../core/common/tooltipOption";
import { useSelector } from "react-redux";
import { Button, DatePicker } from "antd";
import dayjs from "dayjs";
import axiosInstance from "../../auth/axiosInstance";

const StaffAttendance = () => {
  const routes = all_routes;
  const data = staffAttendance;
  const { schoolId } = useSelector((state: any) => state.userSlice);

  const today = dayjs().format("DD-MM-YYYY");
  const [date, setDate] = useState<string>(today);
  const [absenties, setAbsenties] = useState<string[]>([]);
  const [staffList, setStaffList] = useState([]);

  const [attendance, setAttendance] = useState<any[]>([]);
  const [newAbsenties, setNewAbsenties] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState(
    data.map(() => "Present") // Default to 'Present' for each row
  );
  const getStaffList = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/staff/staffList", { params: { schoolId } });
    let data = res.data;
    data = data.map((d: any) => ({ ...d, name: d.firstName + " " + d.lastName }));
    setStaffList(data);
    setAttendance(data);
  };
  const handleGetAttendace = async () => {
    let absenties: any[] = [];
    let res: any;
    try {
      res = await axiosInstance.get("/staffAttendance/byDate", {
        params: {
          date: dayjs(date, "DD-MM-YYYY").format("YYYY-MM-DD"),

          schoolId: schoolId,
        },
      });

      absenties = res.data.absenties;
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        absenties = [];
        return;
      } else {
        console.error(err);
        return;
      }
    }

    setAbsenties(absenties);
    setNewAbsenties(absenties); // Initialize newAbsenties with fetched absenties
    setAttendance((prevstaff = []) => {
      const staffList = prevstaff.map((s: any) => {
        const absent = absenties.includes(s.id.toString());
        return {
          ...s,
          absent,
        };
      });
      console.log(staffList);

      return staffList;
    });
  };

  const handleAttendanceChange = (staffId: string, isAbsent: boolean) => {
    setAttendance((prevAttendance) =>
      prevAttendance.map((student: any) =>
        student.id === staffId ? { ...student, absent: isAbsent } : student
      )
    );
    const updatedAbsenties = attendance
      .filter((staff: any) => (staff.id === staffId ? isAbsent : staff.absent))
      .map((student: any) => student.id.toString());
    setNewAbsenties(updatedAbsenties);
  };

  const handleUploadAttendance = async () => {
    if (!schoolId) return;
    // Implement the logic to upload the new attendance data
    console.log("Uploading new attendance data:", newAbsenties);
    const res = await axiosInstance.put("/staffAttendance/updateByDate", {
      date: dayjs(date, "DD-MM-YYYY").format("YYYY-MM-DD"),
      schoolId: schoolId,
      absenties: newAbsenties,
    });
    console.log(res);
  };
  const attendanceColumns = [
    {
      title: "ID",
      dataIndex: "id",
      sorter: (a: TableData, b: TableData) => a.RollNo.length - b.RollNo.length,
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <p className="text-dark mb-0">{record.id}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">{text}</Link>
            </p>
          </div>
        </div>
      ),
      sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },
    {
      title: "Designation",
      dataIndex: "designation",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <p className="text-dark mb-0">{record.designation}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Department",
      dataIndex: "department",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <p className="text-dark mb-0">{record.department}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Contact No.",
      dataIndex: "primaryContactNumber",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <p className="text-dark mb-0">{record.primaryContactNumber}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Attendance",
      dataIndex: "attendance",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center check-radio-group flex-nowrap">
          <label className="custom-radio">
            <input
              type="radio"
              name={`student${record.id}`}
              checked={record.absent === false}
              onChange={() => handleAttendanceChange(record.id, false)}
            />
            <span className="checkmark checkmark-present" />
            Present
          </label>
          <label className="custom-radio">
            <input
              type="radio"
              name={`student${record.id}`}
              checked={record.absent === true}
              onChange={() => handleAttendanceChange(record.id, true)}
            />
            <span className="checkmark checkmark-absent" />
            Absent
          </label>
        </div>
      ),
      sorter: (a: TableData, b: TableData) => a.attendance.length - b.attendance.length,
    },
  ];

  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };

  // Handle state change for each row
  const handleOptionChange = (index: any, value: any) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = value;
    setSelectedOptions(newSelectedOptions);
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text: string, record: any, index: number) => (
        <>
          <Link to="#" className="link-primary">
            {record.id}
          </Link>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <Link to="#" className="avatar avatar-md">
            <ImageWithBasePath src={record.img} className="img-fluid rounded-circle" alt="img" />
          </Link>
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">{text}</Link>
            </p>
          </div>
        </div>
      ),
      sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a: TableData, b: TableData) => a.department.length - b.department.length,
    },
    {
      title: "Designation",
      dataIndex: "designation",
      sorter: (a: TableData, b: TableData) => a.designation.length - b.designation.length,
    },
    {
      title: "Attendance",
      dataIndex: "attendance",
      render: (text: string, record: any, index: any) => (
        <div className="d-flex align-items-center check-radio-group flex-nowrap">
          <label className="custom-radio">
            <input
              type="radio"
              name={`attendance-${index}`}
              value="Present"
              checked={selectedOptions[index] === "Present"}
              onChange={() => handleOptionChange(index, "Present")}
            />
            <span className="checkmark" />
            Present
          </label>
          <label className="custom-radio">
            <input
              type="radio"
              name={`attendance-${index}`}
              value="Late"
              checked={selectedOptions[index] === "Late"}
              onChange={() => handleOptionChange(index, "Late")}
            />
            <span className="checkmark" />
            Late
          </label>
          <label className="custom-radio">
            <input
              type="radio"
              name={`attendance-${index}`}
              value="Absent"
              checked={selectedOptions[index] === "Absent"}
              onChange={() => handleOptionChange(index, "Absent")}
            />
            <span className="checkmark" />
            Absent
          </label>
          <label className="custom-radio">
            <input
              type="radio"
              name={`attendance-${index}`}
              value="Holiday"
              checked={selectedOptions[index] === "Holiday"}
              onChange={() => handleOptionChange(index, "Holiday")}
            />
            <span className="checkmark" />
            Holiday
          </label>
          <label className="custom-radio">
            <input
              type="radio"
              name={`attendance-${index}`}
              value="Halfday"
              checked={selectedOptions[index] === "Halfday"}
              onChange={() => handleOptionChange(index, "Halfday")}
            />
            <span className="checkmark" />
            Halfday
          </label>
        </div>
      ),
      sorter: (a: TableData, b: TableData) => a.attendance.length - b.attendance.length,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      render: (text: string, record: any) => (
        <input
          type="text"
          className="form-control"
          placeholder="Enter Name"
          style={{ minWidth: "200px", width: "200px" }}
        />
      ),
      sorter: (a: TableData, b: TableData) => a.notes.length - b.notes.length,
    },
  ];

  useEffect(() => {
    handleGetAttendace();
    getStaffList();
  }, [schoolId]);
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Staff Attendance</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Report</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Staff Attendance
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
            </div>
          </div>
          {/* /Page Header */}
          {/* Attendance Staff List */}
          <div className="card flex-fill mb-3">
            <div className="card-header">
              <h4 className="mb-3">Attendence</h4>
              <div className="d-flex gap-3">
                <DatePicker
                  onChange={(date: any, dateString: string | string[]) => {
                    if (typeof dateString === "string") {
                      setDate(dateString);
                    }
                  }}
                  value={dayjs(date, "DD-MM-YYYY")}
                  format={"DD-MM-YYYY"}
                />
                <Link to="#" className="btn btn-primary" onClick={handleGetAttendace}>
                  Get Attendance
                </Link>
                <Link
                  to="#"
                  className="btn btn-primary"
                  style={{ backgroundColor: "green", color: "white", border: "none" }}
                  onClick={handleUploadAttendance}
                >
                  Upload Attendance
                </Link>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              <Table
                columns={attendanceColumns}
                dataSource={attendance}
                Selection={false}
                notRequirePagination={true}
              />
            </div>
          </div>
          {/* / Staff AttendanceStudent List */}
        </div>
      </div>
    </div>
  );
};

export default StaffAttendance;
