import React, { useEffect, useState } from "react";
import CommonSelect from "../../../../core/common/commonSelect";
import {
  bloodGroup,
  Contract,
  gender,
  Hostel,
  Marital,
  PickupPoint,
  roomno,
  route,
  Shift,
  staffDepartment,
  staffrole,
  VehicleNumber,
} from "../../../../core/common/selectoption/selectoption";
import { DatePicker, DatePickerProps } from "antd";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { TagsInput } from "react-tag-input-component";
import axios from "axios";
import Select from "react-select";
import axiosInstance from "../../../auth/axiosInstance";
import { useSelector } from "react-redux";

const AddStaff = () => {
  const [owner, setOwner] = useState<string[]>([]);
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const [departmentOptions, setDepartmentOptions] = useState([{ label: "Select", value: "" }]);
  const [designationOptions, setDesignationOptions] = useState([{ label: "Select", value: "" }]);
  const [roleOptions, setRoleOptions] = useState([{ label: "Select", value: "" }]);
  const getLeaveTypes = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/leaveType/all", { params: { schoolId } });
    setFormData((prev) => ({
      ...prev,
      leaves: res.data.map((l: any) => ({ id: l.id, value: 0, type_name: l.type_name })),
    }));
  };
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    fatherName: "",
    motherName: "",
    qualification: "",
    workExperience: "",
    address: "",
    permanentAddress: "",
    role: "",
    department: "",
    designation: "",
    epfNumber: "",
    basicSalary: "",
    gender: "",
    marital: "",
    contract: "",
    primary_contact: "",
    shift: "",
    route: "",
    vehicleNumber: "",
    pickupPoint: "",
    hostel: "",
    roomno: "",
    bloodGroup: "",
    email: "",
    phone: "",
    dateOfJoining: dayjs(Date.now()),
    dateOfBirth: dayjs(Date.now()),
    workLocation: "",
    leaves: [{ id: 1, value: 1 }],
    maternityLeaves: "",
    sickLeaves: "",
    accountName: "",
    accountNumber: "",
    bankName: "",
    ifscCode: "",
    branchName: "",
    facebookURL: "",
    twitterURL: "",
    linkediinURL: "",
    instagramURL: "",
    password: "",
    confirmPassword: "",
    note: "",
    schoolId: schoolId,
    // Add other fields as necessary
  });
  const routes = all_routes;
  const navigate = useNavigate();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/staff/add", formData);
      if (response.status === 201) {
        // Redirect to the staff list page or show a success message
        navigate(routes.staff);
      }
    } catch (error) {
      console.error("Error adding staff:", error);
    }
  };

  const handleDOBChange: DatePickerProps["onChange"] = (date, dateString) => {
    setFormData({ ...formData, dateOfBirth: date ? dayjs(date) : dayjs() });
  };
  const handleDOJChange: DatePickerProps["onChange"] = (date, dateString) => {
    setFormData({ ...formData, dateOfJoining: date ? dayjs(date) : dayjs() });
  };
  const getDepartments = async () => {
    if (!schoolId) return;
    try {
      const response = await axiosInstance.get(`/department?schoolId=${schoolId}`);
      if (response.status === 200) {
        const options = response.data.map((dep: any) => {
          return {
            label: dep.name,
            value: dep.name,
          };
        });
        setDepartmentOptions(options);
      }
    } catch (error) {
      console.error("Error getting departments:", error);
    }
  };

  const getDesignations = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/designation", { params: { schoolId } });
    const data = res.data.map((des: any) => ({ label: des.name, value: des.name }));
    setDesignationOptions(data);
  };
  const getRoles = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/role/all", { params: { schoolId } });
    const data = res.data.map((des: any) => ({ label: des.name, value: des.name }));
    setRoleOptions(data);
  };
  const handleLeaveChange = async (e: any) => {};
  useEffect(() => {
    getDepartments();
    getDesignations();
    getLeaveTypes();
    getRoles();
  }, [schoolId]);
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content content-two">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="mb-1">Add Staff</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">HRM</li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Add Staff
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-md-12">
                <form onSubmit={handleSubmit}>
                  {/* Personal Information */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-info-square-rounded fs-16" />
                        </span>
                        <h4 className="text-dark">Personal Information</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="add-section">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                              <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                                <i className="ti ti-photo-plus fs-16" />
                              </div>
                              <div className="profile-upload">
                                <div className="profile-uploader d-flex align-items-center">
                                  <div className="drag-upload-btn mb-3">
                                    Upload
                                    <input
                                      type="file"
                                      className="form-control image-sign"
                                      multiple
                                    />
                                  </div>
                                  <Link to="#" className="btn btn-primary mb-3">
                                    Remove
                                  </Link>
                                </div>
                                <p className="fs-12">Upload image size 4MB, Format JPG, PNG, SVG</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row row-cols-xxl-5 row-cols-md-6">
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Role</label>
                              <Select
                                className="react-select"
                                options={roleOptions}
                                placeholder="Select"
                                onChange={(value) => {
                                  setFormData({ ...formData, role: value?.value || "" });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Department</label>
                              <Select
                                className="react-select"
                                options={departmentOptions}
                                placeholder="Select"
                                onChange={(value) => {
                                  setFormData({ ...formData, department: value?.value || "" });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Designation</label>
                              <Select
                                className="react-select"
                                options={designationOptions}
                                placeholder="Select"
                                onChange={(value) => {
                                  setFormData({ ...formData, designation: value?.value || "" });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Gender</label>
                              <Select
                                className="react-select"
                                options={gender}
                                placeholder="Select"
                                onChange={(value) => {
                                  setFormData({ ...formData, gender: value?.value || "" });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Primary Contact Number</label>
                              <input
                                type="text"
                                className="form-control"
                                name="primary_contact"
                                value={formData.primary_contact}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Email Address</label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Blood Group</label>
                              <input
                                type="text"
                                className="form-control"
                                name="bloodGroup"
                                value={formData.bloodGroup}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Marital Status</label>
                              <Select
                                className="react-select"
                                options={Marital}
                                placeholder="Select"
                                onChange={(value) => {
                                  setFormData({ ...formData, marital: value?.value || "" });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Father’s Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="fatherName"
                                value={formData.fatherName}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Mother’s Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="motherName"
                                value={formData.motherName}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Date of Birth</label>
                              <div className="input-icon position-relative">
                                <DatePicker
                                  value={formData.dateOfBirth}
                                  onChange={(date) =>
                                    setFormData({ ...formData, dateOfBirth: date })
                                  }
                                  className="form-control datetimepicker"
                                  format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask",
                                  }}
                                  placeholder="Select Date"
                                />
                                <span className="input-icon-addon">
                                  <i className="ti ti-calendar" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Date of Joining</label>
                              <div className="input-icon position-relative">
                                <span className="input-icon-addon">
                                  <i className="ti ti-calendar" />
                                </span>
                                <DatePicker
                                  // value={formData.dateOfJoining ? new Date(formData.dateOfJoining): Date.now()}
                                  value={formData.dateOfJoining}
                                  onChange={(date) =>
                                    setFormData({ ...formData, dateOfJoining: date })
                                  }
                                  className="form-control datetimepicker"
                                  format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask",
                                  }}
                                  placeholder="Select Date"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Language Known</label>
                              <TagsInput value={owner} onChange={setOwner} />
                            </div>
                          </div>
                          <div className="col-xxl-4 col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Qualification</label>
                              <input
                                type="text"
                                className="form-control"
                                name="qualification"
                                value={formData.qualification}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-4 col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Work Experience</label>
                              <input
                                type="text"
                                className="form-control"
                                name="workExperience"
                                value={formData.workExperience}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-4  col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Note</label>
                              <input
                                type="text"
                                className="form-control"
                                name="note"
                                value={formData.note}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-3  col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Current Address</label>
                              <input
                                type="text"
                                className="form-control"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-3  col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Permanent Address</label>
                              <input
                                type="text"
                                className="form-control"
                                name="permanentAddress"
                                value={formData.permanentAddress}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Personal Information */}
                  {/* Payroll */}
                  <div className="card ">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-user-shield fs-16" />
                        </span>
                        <h4 className="text-dark">Payroll</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">EPF No</label>
                            <input
                              type="text"
                              className="form-control"
                              name="epfNumber"
                              value={formData.epfNumber}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Basic Salary</label>
                            <input
                              type="text"
                              className="form-control"
                              name="basicSalary"
                              value={formData.basicSalary}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Contract Type</label>
                            <Select
                              className="react-select"
                              options={Contract}
                              placeholder="Select"
                              onChange={(value) => {
                                setFormData({ ...formData, contract: value?.value || "" });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Work Shift</label>
                            <Select
                              className="react-select"
                              options={Shift}
                              placeholder="Select"
                              onChange={(value) => {
                                setFormData({ ...formData, shift: value?.value || "" });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Work Location</label>
                            <input
                              type="text"
                              className="form-control"
                              name="workLocation"
                              value={formData.workLocation}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Payroll */}
                  {/* Leaves */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-users fs-16" />
                        </span>
                        <h4 className="text-dark">Leaves</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        {formData.leaves.length > 0 &&
                          formData.leaves.map((leaveType: any) => (
                            <div className="col-lg-3 col-md-6">
                              <div className="mb-3">
                                <label className="form-label">{leaveType.type_name}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="medicalLeaves"
                                  value={
                                    formData.leaves.find((i: any) => i.id === leaveType.id)
                                      ?.value || ""
                                  }
                                  onChange={(e) => {
                                    const updatedValue = e.target.value; // New value from the input
                                    setFormData((prev: any) => {
                                      const updatedLeaves = prev.leaves.map(
                                        (leave: any) =>
                                          leave.id === leaveType.id
                                            ? { ...leave, value: updatedValue } // Update the matching object
                                            : leave // Keep other objects unchanged
                                      );
                                      return { ...prev, leaves: updatedLeaves }; // Return updated state
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  {/* /Leaves */}
                  {/* Bank Details */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-users fs-16" />
                        </span>
                        <h4 className="text-map">Bank Account Detail</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Account Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="accountName"
                              value={formData.accountName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Account Number</label>
                            <input
                              type="text"
                              className="form-control"
                              name="accountNumber"
                              value={formData.accountNumber}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Bank Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="bankName"
                              value={formData.bankName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">IFSC Code</label>
                            <input
                              type="text"
                              className="form-control"
                              name="ifscCode"
                              value={formData.ifscCode}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Branch Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="branchName"
                              value={formData.branchName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Bank Details */}
                  {/* Transport Information */}
                  <div className="card">
                    <div className="card-header bg-light d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-bus-stop fs-16" />
                        </span>
                        <h4 className="text-dark">Transport Information</h4>
                      </div>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" />
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Route</label>
                            <Select
                              className="react-select"
                              options={route}
                              placeholder="Select"
                              onChange={(value) => {
                                setFormData({ ...formData, route: value?.value || "" });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Vehicle Number</label>
                            <Select
                              className="react-select"
                              options={VehicleNumber}
                              placeholder="Select"
                              onChange={(value) => {
                                setFormData({ ...formData, vehicleNumber: value?.value || "" });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Pickup Point</label>
                            <Select
                              className="react-select"
                              options={PickupPoint}
                              placeholder="Select"
                              onChange={(value) => {
                                setFormData({ ...formData, pickupPoint: value?.value || "" });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Transport Information */}
                  {/* Hostel Information */}
                  <div className="card">
                    <div className="card-header bg-light d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-building-fortress fs-16" />
                        </span>
                        <h4 className="text-dark">Hostel Information</h4>
                      </div>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" />
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Hostel</label>
                            <Select
                              className="react-select"
                              options={Hostel}
                              placeholder="Select"
                              onChange={(value) => {
                                setFormData({ ...formData, hostel: value?.value || "" });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Room No</label>
                            <Select
                              className="react-select"
                              options={roomno}
                              placeholder="Select"
                              onChange={(value) => {
                                setFormData({ ...formData, roomno: value?.value || "" });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Hostel Information */}
                  {/* Social Media Links */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-building fs-16" />
                        </span>
                        <h4 className="text-dark">Social Media Links</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Facebook URL</label>
                            <input
                              type="text"
                              className="form-control"
                              name="facebookURL"
                              value={formData.facebookURL}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Twitter URL</label>
                            <input
                              type="text"
                              className="form-control"
                              name="twitterURL"
                              value={formData.twitterURL}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Linkediin URL</label>
                            <input
                              type="text"
                              className="form-control"
                              name="linkediinURL"
                              value={formData.linkediinURL}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Instagram URL</label>
                            <input
                              type="text"
                              className="form-control"
                              name="instagramURL"
                              value={formData.instagramURL}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Social Media Links */}
                  {/* Documents */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-file fs-16" />
                        </span>
                        <h4 className="text-dark">Documents</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-2">
                            <div className="mb-3">
                              <label className="form-label">Upload Resume</label>
                              <p>Upload image size of 4MB, Accepted Format PDF</p>
                            </div>
                            <div className="d-flex align-items-center flex-wrap">
                              <div className="btn btn-primary drag-upload-btn mb-2 me-2">
                                <i className="ti ti-file-upload me-1" />
                                Change
                                <input type="file" className="form-control image_sign" multiple />
                              </div>
                              <p className="mb-2">Resume.pdf</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-2">
                            <div className="mb-3">
                              <label className="form-label">Upload Joining Letter</label>
                              <p>Upload image size of 4MB, Accepted Format PDF</p>
                            </div>
                            <div className="d-flex align-items-center flex-wrap">
                              <div className="btn btn-primary drag-upload-btn mb-2">
                                <i className="ti ti-file-upload me-1" />
                                Upload Document
                                <input type="file" className="form-control image_sign" multiple />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Documents */}
                  {/* Password */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-file fs-16" />
                        </span>
                        <h4 className="text-dark">Password</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">New Password</label>
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Confirm Password</label>
                            <input type="password" className="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Password */}
                  <div className="text-end">
                    <button type="button" className="btn btn-light me-3">
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Add Staff
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
    </div>
  );
};

export default AddStaff;
