import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Student } from "../../../feature-module/peoples/students/studentService/student.model";
import { initialStudentValues } from "../../../feature-module/peoples/students/studentService/student.model";

const studentSlice = createSlice({
  name: "student",
  initialState: initialStudentValues,
  reducers: {
    setStudent(state, action: PayloadAction<Student>) {
      return action.payload;
    },
    updateStudent(state, action: PayloadAction<Partial<Student>>) {
      return { ...state, ...action.payload };
    },
    resetStudent() {
      return initialStudentValues;
    },
  },
});
export const { setStudent, updateStudent, resetStudent } = studentSlice.actions;
export default studentSlice.reducer;
