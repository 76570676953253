import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../feature-module/auth/axiosInstance";

interface ClassSelectOption {
  value: number;
  label: string;
  section: string;
}

interface MetaDataState {
  classOptions: ClassSelectOption[];
}

const initialState: MetaDataState = {
  classOptions: [
    {
      value: Number(""),
      label: "Select Class",
      section: "",
    },
  ],
};

const basePath = "/metaData";

const metaDataSlice = createSlice({
  name: "metaData",
  initialState,
  reducers: {
    setClassOptions(state, action: PayloadAction<ClassSelectOption[]>) {
      state.classOptions = action.payload;
    },
  },
});

export const fetchMetaData = createAsyncThunk(
  "metaData/fetchMetaData",
  async (schoolId: number, { dispatch }) => {
    try {
      const response = await axiosInstance.get(`${basePath}/${schoolId}`);
      const data = await response.data;
      const formattedClassOptions = data.map(
        (item: { id: string; name: string; section: string }) => ({
          value: item.id,
          label: item.name + " " + item.section,
          section: item.section,
        })
      );
      if (formattedClassOptions.length === 0) {
        formattedClassOptions.push({
          value: "",
          label: "Select Class",
          section: "",
        });
      }
      dispatch(setClassOptions(formattedClassOptions));
    } catch (error) {
      console.error("Failed to fetch meta data", error);
    }
  }
);
export const { setClassOptions } = metaDataSlice.actions;
export default metaDataSlice.reducer;
