import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  role: null,
  permissions: [],
  schoolId: undefined,
  firstName: undefined,
  lastName: undefined,
  kycStatus: false,
  schoolName: "",
  schoolLocation: "",
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setRole: (state, { payload }) => {
      state.role = payload;
    },
    setPermissions: (state, { payload }) => {
      state.permissions = payload;
    },
    setSchoolId: (state, { payload }) => {
      state.schoolId = payload;
    },
    setKYCStatus: (state, { payload }) => {
      state.kycStatus = payload;
    },
    setSchoolDetails: (state, { payload }) => {
      state.schoolName = payload.schoolName;
      state.schoolLocation = payload.schoolLocation;
    },
    setUserDetails: (state, { payload }) => {
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
    },
  },
});

export const userSliceActions = userSlice.actions;

export default userSlice.reducer;
