import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { TableData } from "../../../../core/data/interface";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import StudentModals from "../studentModals";
import Table from "../../../../core/common/dataTable/index";
import PredefinedDateRanges from "../../../../core/common/datePicker";
import ExcelJS from "exceljs";
import {
  academicYear,
  allClass,
  allSection,
  cast,
  gender,
  mothertongue,
  names,
  religion,
  status,
} from "../../../../core/common/selectoption/selectoption";
import CommonSelect from "../../../../core/common/commonSelect";
import TooltipOption from "../../../../core/common/tooltipOption";
import {
  deleteStudent,
  getAllStudentsBySchool,
  getStudentDetails,
} from "../studentService/studentService";

import { initialStudentValues } from "../studentService/student.model";
import { useDispatch, useSelector } from "react-redux";
import { setStudent } from "../../../../core/data/redux/studentSlice";
import { fetchMetaData } from "../../../../core/data/redux/metaDataSlice";
import { AppDispatch } from "../../../../core/data/redux/store";
import Select from "react-select";
import axiosInstance from "../../../auth/axiosInstance";
import { saveAs } from "file-saver";

const StudentList = () => {
  const [students, setStudents] = React.useState<TableData[]>([]);

  const routes = all_routes;
  const [options, setOptions] = useState<any>([]);
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const [selectedClassId, setSelectedClassId] = useState<any>(0);
  // console.log(selectedClassId);

  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const [classSelectOptions, setClassSelectOptions] = useState<any>([]);
  const fetchOptions = async () => {
    if (!schoolId) return;
    let res = await axiosInstance.get("/class/school/" + schoolId);
    setClassSelectOptions(
      res.data.map((cl: any) => ({
        label: cl.name + " " + cl.section,
        value: cl.id,
        section: cl.section,
      }))
    );
  };
  const {
    admissionDate,
    classId,
    schoolId: unusedSchoolId,
    section,
    guardianName,
    permanentAddress,

    ...excelFormatObject
  } = initialStudentValues;

  const studentHeaders = Object.keys(excelFormatObject).map((colm) => ({
    header: colm,
    key: colm,
    width: 20,
  }));
  // console.log(studentHeaders);

  const downloadFormat = async () => {
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet("Sheet 1");
    ws.columns = studentHeaders;
    for (let i = 1; i <= 10000; ++i) ws.addRow([]);
    const genderCol = ws.getColumn("gender");
    const religionCol = ws.getColumn("religion");
    const categoryCol = ws.getColumn("category");
    const academicYearCol = ws.getColumn("academicYear");
    const statusCol = ws.getColumn("status");
    const motherTongueCol = ws.getColumn("motherTongue");
    genderCol.eachCell({ includeEmpty: true }, function (cell, rowNumber) {
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`"${options.genderOptions.join(", ")}"`],
      };
    });
    religionCol.eachCell({ includeEmpty: true }, function (cell, rowNumber) {
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`"${options.religionOptions.join(", ")}"`],
      };
    });
    categoryCol.eachCell({ includeEmpty: true }, function (cell, rowNumber) {
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`"${options.categoryOptions.join(", ")}"`],
      };
    });
    academicYearCol.eachCell(
      { includeEmpty: true },
      function (cell, rowNumber) {
        cell.dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: [`"${options.academicYearOptions.join(", ")}"`],
        };
      }
    );
    statusCol.eachCell({ includeEmpty: true }, function (cell, rowNumber) {
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`"${options.statusOptions.join(", ")}"`],
      };
    });
    motherTongueCol.eachCell(
      { includeEmpty: true },
      function (cell, rowNumber) {
        cell.dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: [`"${options.motherTongueOptions.join(", ")}"`],
        };
      }
    );
    const buffer = await wb.xlsx.writeBuffer();
    const blob = new Blob([buffer]);
    saveAs(
      blob,
      `Class ${
        classSelectOptions.find((cl: any) => cl.value === selectedClassId).label
      }.xlsx`
    );
  };
  const handleImportFile = async (e: any) => {
    const file = e.target.files[0];
    console.log(file);

    const arrayBuffer = await file.arrayBuffer();
    const wb = new ExcelJS.Workbook();
    await wb.xlsx.load(arrayBuffer);
    const ws = wb.worksheets[0];
    let jsonData: any = [];

    const headers: any = [];
    ws.getRow(1).eachCell((cell, colNumber) => {
      headers.push(cell.text); // Assuming first row is the header
    });

    ws.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        // Skip the first row (headers)
        const rowData: any = {};

        row.eachCell((cell, colNumber) => {
          const header = headers[colNumber - 1]; // Get header based on column index
          rowData[header] = cell.text; // Map cell value to the header
        });

        jsonData.push(rowData);
      }
    });
    console.log("Extracted JSON Data:", jsonData);
    jsonData = jsonData.map((row: any) => ({
      ...row,
      schoolId: schoolId,
      classId: selectedClassId,
      section: classSelectOptions.find(
        (cl: any) => cl.value === selectedClassId
      ).section,
      languages: [row.languages],
    }));
    await axiosInstance.post("/student/bulk-create", jsonData);
  };

  const columns = [
    {
      title: "Admission No",
      dataIndex: "admissionId",
      render: (text: string, record: any) => (
        <Link
          to={`${routes.studentDetail}`}
          className="link-primary"
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
            getStudentDetails(schoolId, record.admissionId)
              .then((res) => dispatch(setStudent(res)))
              .catch((err) => console.log(err));
          }}
        >
          {text}
        </Link>
      ),
      sorter: (a: TableData, b: TableData) =>
        a.AdmissionNo.length - b.AdmissionNo.length,
    },
    {
      title: "Roll No",
      dataIndex: "rollNumber",
      sorter: (a: TableData, b: TableData) => a.RollNo.length - b.RollNo.length,
    },
    {
      title: "Name",
      dataIndex: "firstName",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <Link to="#" className="avatar avatar-md">
            <ImageWithBasePath
              src="assets/img/students/student-02.jpg"
              className="img-fluid rounded-circle"
              alt="img"
            />
          </Link>
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">
                {record.firstName} {record.lastName}
              </Link>
            </p>
          </div>
        </div>
      ),
      sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },
    {
      title: "Class",
      dataIndex: "classId",
      sorter: (a: TableData, b: TableData) => a.class.length - b.class.length,
    },
    {
      title: "Section",
      dataIndex: "section",
      sorter: (a: TableData, b: TableData) =>
        a.section.length - b.section.length,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a: TableData, b: TableData) => a.gender.length - b.gender.length,
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (text: string) => (
        <>
          {text === "Active" ? (
            <span className="badge badge-soft-success d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          ) : (
            <span className="badge badge-soft-danger d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.status.length - b.status.length,
    },
    {
      title: "Date of Join",
      dataIndex: "admissionDate",
      sorter: (a: TableData, b: TableData) =>
        a.DateofJoin.length - b.DateofJoin.length,
    },
    {
      title: "DOB",
      dataIndex: "dateofBirth",
      sorter: (a: TableData, b: TableData) => a.DOB.length - b.DOB.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: string, record: any) => (
        <>
          <div className="d-flex align-items-center">
            <Link
              to="#"
              className="btn btn-outline-light bg-white btn-icon d-flex align-items-center justify-content-center rounded-circle  p-0 me-2"
            >
              <i className="ti ti-brand-hipchat" />
            </Link>
            <Link
              to="#"
              className="btn btn-outline-light bg-white btn-icon d-flex align-items-center justify-content-center rounded-circle  p-0 me-2"
            >
              <i className="ti ti-phone" />
            </Link>
            <Link
              to="#"
              className="btn btn-outline-light bg-white btn-icon d-flex align-items-center justify-content-center rounded-circle p-0 me-3"
            >
              <i className="ti ti-mail" />
            </Link>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#add_fees_collect"
              className="btn btn-light fs-12 fw-semibold me-3"
            >
              Collect Fees
            </Link>
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="student-details"
                  >
                    <i className="ti ti-menu me-2" />
                    View Student
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to={routes.editStudent}
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Edit
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#login_detail"
                  >
                    <i className="ti ti-lock me-2" />
                    Login Details
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item rounded-1" to="#">
                    <i className="ti ti-toggle-right me-2" />
                    Disable
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="student-promotion"
                  >
                    <i className="ti ti-arrow-ramp-right-2 me-2" />
                    Promote Student
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    // data-bs-toggle="modal"
                    // data-bs-target="#delete-modal"
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                      deleteStudent(1, record.admissionId)
                        .then((res) => {
                          getAllStudentsBySchool(schoolId).then(
                            (allStudent) => {
                              setStudents(allStudent);
                            }
                          );
                        })
                        .catch((err) => console.log(err));
                    }}
                  >
                    <i className="ti ti-trash-x me-2" />
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    setOptions((prev: any) => ({ ...prev, genderOptions: ["Male", "Female"] }));
    setOptions((prev: any) => ({
      ...prev,
      statusOptions: ["Active", "Inactive"],
    }));
    setOptions((prev: any) => ({
      ...prev,
      categoryOptions: cast.map((c) => c.value),
    }));
    setOptions((prev: any) => ({
      ...prev,
      religionOptions: religion.map((c) => c.value),
    }));
    setOptions((prev: any) => ({
      ...prev,
      academicYearOptions: academicYear.map((c) => c.value),
    }));
    setOptions((prev: any) => ({
      ...prev,
      motherTongueOptions: mothertongue.map((c) => c.value),
    }));

    fetchOptions();
    getAllStudentsBySchool(schoolId).then((res) => {
      setStudents(res);
      console.log("student response", res);
      dispatch(fetchMetaData(schoolId) as any);
    });
  }, [schoolId]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Students List</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">Students</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Students
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex gap-4 my-xl-auto right-content align-items-center flex-wrap">
              <div className="mb-2 ">
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#import-modal"
                >
                  Import
                </Link>
              </div>

              <div className="mb-2">
                <Link
                  to={routes.addStudent}
                  className="btn btn-primary d-flex align-items-center"
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Add Student
                </Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Students List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Students List</h4>
              <div className="d-flex align-items-center flex-wrap">
                <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div>
                <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div
                    className="dropdown-menu drop-width"
                    ref={dropdownMenuRef}
                  >
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 pb-0 border-bottom">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Class</label>
                              <CommonSelect
                                className="select"
                                options={allClass}
                                defaultValue={allClass[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Section</label>
                              <CommonSelect
                                className="select"
                                options={allSection}
                                defaultValue={allSection[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Name</label>
                              <CommonSelect
                                className="select"
                                options={names}
                                defaultValue={names[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Gender</label>
                              <CommonSelect
                                className="select"
                                options={gender}
                                defaultValue={gender[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Status</label>
                              <CommonSelect
                                className="select"
                                options={status}
                                defaultValue={status[0]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <Link
                          to={routes.studentGrid}
                          className="btn btn-primary"
                          onClick={handleApplyClick}
                        >
                          Apply
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="d-flex align-items-center bg-white border rounded-2 p-1 mb-3 me-2">
                  <Link
                    to={routes.studentList}
                    className="active btn btn-icon btn-sm me-1 primary-hover"
                  >
                    <i className="ti ti-list-tree" />
                  </Link>
                  <Link
                    to={routes.studentGrid}
                    className="btn btn-icon btn-sm bg-light primary-hover"
                  >
                    <i className="ti ti-grid-dots" />
                  </Link>
                </div>
                <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Student List */}
              <Table dataSource={students} columns={columns} Selection={true} />
              {/* /Student List */}
            </div>
          </div>
          {/* /Students List */}
        </div>
      </div>
      {/* /Page Wrapper */}
      <StudentModals />
      {/* import modal */}
      <div className="modal fade " id="import-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Import </h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <div className="modal-body d-flex gap-5 flex-column">
              <div>
                <label className="form-label">Select Class</label>
                <Select
                  className="react-select mb-2"
                  options={classSelectOptions}
                  onChange={(value: any) => setSelectedClassId(value.value)}
                />

                <Link
                  to="#"
                  onClick={() => downloadFormat()}
                  className="btn btn-primary"
                >
                  Download Format
                </Link>
              </div>
              <label className="btn btn-primary">
                {" "}
                <input
                  onChange={(e) => handleImportFile(e)}
                  style={{ display: "none" }}
                  type="file"
                  accept=".xlsx, .xlw, .csv"
                />
                Import Excel File
              </label>
            </div>
            <div className="modal-footer">
              Note: Please download the formatted Excel sheet to ensure your
              data matches the required structure before importing.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentList;
