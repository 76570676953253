import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller, set } from "react-hook-form";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { all_routes } from "../../../router/all_routes";
import {
  AdmissionNo,
  Hostel,
  PickupPoint,
  VehicleNumber,
  academicYear,
  allSection,
  bloodGroup,
  cast,
  gender,
  getSelectedOptons,
  house,
  mothertongue,
  names,
  religion,
  rollno,
  roomNO,
  route,
  state,
  status,
} from "../../../../core/common/selectoption/selectoption";
import { TagsInput } from "react-tag-input-component";
import CommonSelect from "../../../../core/common/commonSelect";
import { useLocation, useNavigate } from "react-router-dom";
import { initialStudentValues, Student } from "../studentService/student.model";
import {
  addStudent,
  getStudentDetails,
  mapStudentForSubmission,
  updateStudent,
} from "../studentService/studentService";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { setStudent } from "../../../../core/data/redux/studentSlice";
import axiosInstance from "../../../auth/axiosInstance";
import { Button } from "react-bootstrap";
import { FaPlus, FaTrash } from "react-icons/fa";

const AddStudent = () => {
  const routes = all_routes;

  const { control, handleSubmit, reset, watch, setValue } = useForm<Student>({
    defaultValues: initialStudentValues,
  });
  const classId = watch("classId");
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [owner, setOwner] = useState<string[]>(["English", "Spanish"]);
  const [owner1, setOwner1] = useState<string[]>([]);
  const [owner2, setOwner2] = useState<string[]>([]);
  const [newContents, setNewContents] = useState<number[]>([0]);
  const studentFromStore = useSelector((state: any) => state.studentSlice);
  const [expenseCategoryList, setExpenseCategoryList] = useState([]);
  const [categoryJson, setCategoryJson] = useState<{ [key: string]: string }>(
    {}
  );
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [feesOptions, setFeesOptions] = useState([]);
  const [feeData, setFeeData] = useState({
    items: [
      {
        description: "",
        amount: 0,
        categoryId: "",
        subCategoryId: "",
        subCategoryName: "",
        categoryName: "",
      },
    ],
  });
  const getFeesOptions = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get(
      "/ledger/school/" + schoolId + "/category/feesType"
    );
    console.log(res.data);
    setFeesOptions(res.data.map((p: any) => ({ value: p.id, label: p.name })));
  };

  const allClass = useSelector(
    (state: any) => state.metaDataSlice?.classOptions
  );
  console.log("state", allClass);
  const location = useLocation();
  const navigate = useNavigate();
  const addNewContent = () => {
    setNewContents([...newContents, newContents.length]);
  };
  const removeContent = (index: number) => {
    setNewContents(newContents.filter((_, i) => i !== index));
  };
  useEffect(() => {
    if (location.pathname === routes.editStudent) {
      setIsEdit(true);
      setOwner(["English"]);
      setOwner1(["Medecine Name"]);
      setOwner2(["Allergy", "Skin Allergy"]);

      reset(studentFromStore);
    } else {
      setIsEdit(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (classId) {
      if (typeof classId === "object") {
        setValue("section", classId["section"]);
      } else {
        const selectedClass = allClass.find(
          (item: any) => item.value === classId
        );
        setValue("section", selectedClass?.section);
      }
    }
  }, [classId]);

  // const fetchExpenseCategoryList = async () => {
  //   try {
  //     const response = await axiosInstance.get("/expenseCategory", {
  //       params: {
  //         schoolId: schoolId,
  //       },
  //     });
  //     // console.log(response.data);
  //     const transformedData = response.data.map((item: any, idx: number) => ({
  //       value: item.id,
  //       label: item.name,
  //     }));

  //     const transexpenseCategoryJson: { [key: string]: string } = {};

  //     for (let i in response.data) {
  //       transexpenseCategoryJson[response.data[i].id] = response.data[i].name;
  //     }

  //     setCategoryJson(transexpenseCategoryJson);

  //     if (transformedData.length > 0) {
  //       setExpenseCategoryList(transformedData);
  //     } else {
  //       setExpenseCategoryList([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching expense Category list:", error);
  //   }
  // };

  // const fetchSubCategoryList = async () => {
  //   try {
  //     const response = await axiosInstance.get("/subCategory/school/" + schoolId);
  //     // console.log(response.data);
  //     const transformedData = response.data.map((item: any, idx: number) => ({
  //       value: item.id,
  //       label: item.name,
  //       categoryId: item.categoryId,
  //     }));
  //     console.log(transformedData);

  //     if (transformedData.length > 0) {
  //       setSubCategoryList(transformedData);
  //     } else {
  //       setSubCategoryList([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching expense Category list:", error);
  //   }
  // };

  useEffect(() => {
    // fetchExpenseCategoryList();
    // fetchSubCategoryList();
    getFeesOptions();
  }, []);

  const handleAddFeesType = () => {
    setFeeData({
      ...feeData,
      items: [
        ...feeData.items,
        {
          description: "",
          amount: 0,
          categoryId: "",
          subCategoryId: "",
          subCategoryName: "",
          categoryName: "",
        },
      ],
    });
  };

  const handleDeleteRow = (index: number) => {
    const newItems = feeData.items.filter((_, i) => i !== index);
    setFeeData({ ...feeData, items: newItems });
  };

  const handleItemChange = (
    index: number,
    field: string,
    value: string | number,
    title: string
  ) => {
    const newItems = [...feeData.items];
    newItems[index] = { ...newItems[index], [field]: value };

    setFeeData({ ...feeData, items: newItems });
  };

  const onSubmit = (data: Student) => {
    if (!schoolId) return;
    data.schoolId = schoolId;
    data.feeJson = feeData;
    console.log(data);

    const studentData = mapStudentForSubmission(data);

    isEdit
      ? updateStudent(
          studentData.admissionId,
          studentData as unknown as Student
        )
          .then((res) => {
            getStudentDetails(schoolId, studentData.admissionId).then(
              (data) => {
                dispatch(setStudent(data));
                navigate(routes.studentDetail);
              }
            );
          })
          .catch((err) => {
            alert("Unable to update student. Please try again.");
          })
      : addStudent(studentData as Student)
          .then((res) => {
            dispatch(setStudent(res));
            navigate(routes.studentDetail);
          })
          .catch((err) => {
            alert(
              "Unable to create student. Please check the provided data and try again."
            );
          });
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content content-two">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="mb-1">{isEdit ? "Edit" : "Add"} Student</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={routes.studentList}>Students</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {isEdit ? "Edit" : "Add"} Student
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* Personal Information */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-info-square-rounded fs-16" />
                      </span>
                      <h4 className="text-dark">Personal Information</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                          <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                            <i className="ti ti-photo-plus fs-16" />
                          </div>
                          <div className="profile-upload">
                            <div className="profile-uploader d-flex align-items-center">
                              <div className="drag-upload-btn mb-3">
                                Upload
                                <input
                                  type="file"
                                  className="form-control image-sign"
                                  multiple
                                />
                              </div>
                              <Link to="#" className="btn btn-primary mb-3">
                                Remove
                              </Link>
                            </div>
                            <p className="fs-12">
                              Upload image size 4MB, Format JPG, PNG, SVG
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row row-cols-xxl-5 row-cols-md-6">
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Academic Year</label>
                          <Controller
                            name="academicYear"
                            control={control}
                            rules={{ required: "Academic Year is required" }}
                            render={({ field, fieldState }) => (
                              <>
                                <Select
                                  classNamePrefix="react-select"
                                  className="select"
                                  options={academicYear}
                                  value={getSelectedOptons(
                                    academicYear,
                                    field.value
                                  )}
                                  onChange={(value: any) =>
                                    field.onChange(value)
                                  }
                                  placeholder="Select"
                                />
                                {fieldState.error && (
                                  <p className="text-danger">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Admission Number</label>
                          <Controller
                            name="admissionId"
                            control={control}
                            render={({ field }) => (
                              <input
                                type="text"
                                className="form-control"
                                value={field.value}
                                disabled
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Admission Date </label>
                          <Controller
                            name="admissionDate"
                            control={control}
                            rules={{ required: "Admission Date is required" }}
                            render={({ field, fieldState }) => (
                              <div className="input-icon position-relative">
                                <DatePicker
                                  className="form-control datetimepicker"
                                  format="DD-MM-YYYY"
                                  value={
                                    field.value
                                      ? dayjs(field.value, "MM-DD-YYYY")
                                      : null
                                  }
                                  onChange={(date, dateString) =>
                                    field.onChange(dateString)
                                  }
                                  placeholder="Select Date"
                                />
                                <span className="input-icon-addon">
                                  <i className="ti ti-calendar" />
                                </span>

                                {fieldState.error && (
                                  <p className="text-danger">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Roll Number</label>
                          <Controller
                            name="rollNumber"
                            control={control}
                            render={({ field }) => (
                              <input
                                disabled={true}
                                type="text"
                                className="form-control"
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Status</label>
                          <Controller
                            name="status"
                            control={control}
                            rules={{ required: "Status is required" }}
                            render={({ field, fieldState }) => (
                              <>
                                <Select
                                  classNamePrefix="react-select"
                                  className="select"
                                  options={status}
                                  value={getSelectedOptons(status, field.value)}
                                  onChange={(value: any) =>
                                    field.onChange(value)
                                  }
                                  placeholder="Select"
                                />
                                {fieldState.error && (
                                  <p className="text-danger">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">First Name</label>
                          <Controller
                            name="firstName"
                            control={control}
                            rules={{ required: "First Name is required" }}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                                {fieldState.error && (
                                  <p className="text-danger">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Last Name</label>
                          <Controller
                            name="lastName"
                            control={control}
                            rules={{ required: "Last Name is required" }}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                                {fieldState.error && (
                                  <p className="text-danger">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Class</label>
                          <Controller
                            name="classId"
                            control={control}
                            rules={{ required: "Class is required" }}
                            render={({ field, fieldState }) => (
                              <>
                                <Select
                                  classNamePrefix="react-select"
                                  className="select"
                                  options={allClass}
                                  value={getSelectedOptons(
                                    allClass,
                                    field.value
                                  )}
                                  onChange={(value: any) =>
                                    field.onChange(value)
                                  }
                                  isDisabled={isEdit}
                                  placeholder="Select"
                                />
                                {fieldState.error && (
                                  <p className="text-danger">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Section</label>
                          <Controller
                            name="section"
                            control={control}
                            rules={{ required: "Section is required" }}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  placeholder="Section"
                                  disabled
                                />
                                {fieldState.error && (
                                  <p className="text-danger">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Gender</label>
                          <Controller
                            name="gender"
                            control={control}
                            rules={{ required: "Gender is required" }}
                            render={({ field, fieldState }) => (
                              <>
                                <Select
                                  classNamePrefix="react-select"
                                  className="select"
                                  options={gender}
                                  value={getSelectedOptons(gender, field.value)}
                                  onChange={(value: any) =>
                                    field.onChange(value)
                                  }
                                  placeholder="Select"
                                />
                                {fieldState.error && (
                                  <p className="text-danger">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Date of Birth</label>
                          <Controller
                            name="dateOfBirth"
                            control={control}
                            rules={{ required: "Date of Birth is required" }}
                            render={({ field, fieldState }) => (
                              <>
                                <div className="input-icon position-relative">
                                  <DatePicker
                                    className="form-control datetimepicker"
                                    format="DD-MM-YYYY"
                                    value={
                                      field.value
                                        ? dayjs(field.value, "DD-MM-YYYY")
                                        : null
                                    }
                                    onChange={(date, dateString) =>
                                      field.onChange(dateString)
                                    }
                                    placeholder="Select Date"
                                  />
                                  <span className="input-icon-addon">
                                    <i className="ti ti-calendar" />
                                  </span>
                                </div>
                                {fieldState.error && (
                                  <p className="text-danger">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Blood Group</label>
                          <Controller
                            name="bloodGroup"
                            control={control}
                            rules={{ required: "Blood Group is required" }}
                            render={({ field, fieldState }) => (
                              <>
                                <Select
                                  classNamePrefix="react-select"
                                  className="select"
                                  options={bloodGroup}
                                  value={getSelectedOptons(
                                    bloodGroup,
                                    field.value
                                  )}
                                  onChange={(value: any) =>
                                    field.onChange(value)
                                  }
                                  placeholder="Select"
                                />
                                {fieldState.error && (
                                  <p className="text-danger">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">House</label>
                          <CommonSelect
                            className="select"
                            options={house}
                            defaultValue={isEdit ? house[0] : undefined}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Religion</label>
                          <Controller
                            name="religion"
                            control={control}
                            render={({ field }) => (
                              <Select
                                classNamePrefix="react-select"
                                className="select"
                                options={religion}
                                value={getSelectedOptons(religion, field.value)}
                                onChange={(value: any) => field.onChange(value)}
                                placeholder="Select"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Category</label>
                          <Controller
                            name="category"
                            control={control}
                            render={({ field }) => (
                              <Select
                                classNamePrefix="react-select"
                                className="select"
                                options={cast}
                                value={getSelectedOptons(cast, field.value)}
                                onChange={(value: any) => field.onChange(value)}
                                placeholder="Select"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Primary Contact Number
                          </label>
                          <Controller
                            name="contactNumber"
                            control={control}
                            rules={{
                              required: "Primary Contact Number is required",
                            }}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                                {fieldState.error && (
                                  <p className="text-danger">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Email Address</label>
                          <Controller
                            name="email"
                            control={control}
                            rules={{
                              required: "Email Address is required",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: "Invalid email address",
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                                {fieldState.error && (
                                  <p className="text-danger">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Caste</label>
                          <Controller
                            name="caste"
                            control={control}
                            render={({ field }) => (
                              <input
                                type="text"
                                className="form-control"
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Mother Tongue</label>
                          <Controller
                            name="motherTongue"
                            control={control}
                            render={({ field }) => (
                              <Select
                                classNamePrefix="react-select"
                                className="select"
                                options={mothertongue}
                                value={getSelectedOptons(
                                  mothertongue,
                                  field.value
                                )}
                                onChange={(value: any) => field.onChange(value)}
                                placeholder="Select"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-xxl col-xl-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Language Known</label>
                          <TagsInput
                            // className="input-tags form-control"
                            value={owner}
                            onChange={setOwner}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Personal Information */}
                {/* Parents & Guardian Information */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-user-shield fs-16" />
                      </span>
                      <h4 className="text-dark">
                        Parents &amp; Guardian Information
                      </h4>
                    </div>
                  </div>
                  <div className="card-body pb-0">
                    <div className="border-bottom mb-3">
                      <h5 className="mb-3">Father’s Info</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                              <i className="ti ti-photo-plus fs-16" />
                            </div>
                            <div className="profile-upload">
                              <div className="profile-uploader d-flex align-items-center">
                                <div className="drag-upload-btn mb-3">
                                  Upload
                                  <input
                                    type="file"
                                    className="form-control image-sign"
                                    multiple
                                  />
                                </div>
                                <Link to="#" className="btn btn-primary mb-3">
                                  Remove
                                </Link>
                              </div>
                              <p className="fs-12">
                                Upload image size 4MB, Format JPG, PNG, SVG
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Father Name</label>
                            <Controller
                              name="fatherName"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <Controller
                              name="fatherEmail"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <Controller
                              name="fatherContactNumber"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Father Occupation
                            </label>
                            <Controller
                              name="fatherOccupation"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom mb-3">
                      <h5 className="mb-3">Mother’s Info</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                              <i className="ti ti-photo-plus fs-16" />
                            </div>
                            <div className="profile-upload">
                              <div className="profile-uploader d-flex align-items-center">
                                <div className="drag-upload-btn mb-3">
                                  Upload
                                  <input
                                    type="file"
                                    className="form-control image-sign"
                                    multiple
                                  />
                                </div>
                                <Link to="#" className="btn btn-primary mb-3">
                                  Remove
                                </Link>
                              </div>
                              <p className="fs-12">
                                Upload image size 4MB, Format JPG, PNG, SVG
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Mother Name</label>
                            <Controller
                              name="motherName"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <Controller
                              name="motherEmail"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <Controller
                              name="motherContactNumber"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Mother Occupation
                            </label>
                            <Controller
                              name="motherOccupation"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h5 className="mb-3">Guardian Details</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-2">
                            <div className="d-flex align-items-center flex-wrap">
                              <label className="form-label text-dark fw-normal me-2">
                                If Guardian Is
                              </label>
                              <div className="form-check me-3 mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="guardian"
                                  id="parents"
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="parents"
                                >
                                  Parents
                                </label>
                              </div>
                              <div className="form-check me-3 mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="guardian"
                                  id="guardian"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="guardian"
                                >
                                  Guardian
                                </label>
                              </div>
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="guardian"
                                  id="other"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="other"
                                >
                                  Others
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                            <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                              <i className="ti ti-photo-plus fs-16" />
                            </div>
                            <div className="profile-upload">
                              <div className="profile-uploader d-flex align-items-center">
                                <div className="drag-upload-btn mb-3">
                                  Upload
                                  <input
                                    type="file"
                                    className="form-control image-sign"
                                    multiple
                                  />
                                </div>
                                <Link to="#" className="btn btn-primary mb-3">
                                  Remove
                                </Link>
                              </div>
                              <p className="fs-12">
                                Upload image size 4MB, Format JPG, PNG, SVG
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Guardian Name</label>
                            <Controller
                              name="guardianName"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Guardian Relation
                            </label>
                            <Controller
                              name="guardianRelation"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <Controller
                              name="guardianContactNumber"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <Controller
                              name="guardianEmail"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Occupation</label>
                            <Controller
                              name="guardianOccupation"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Address</label>
                            <Controller
                              name="guardianAddress"
                              control={control}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={field.value}
                                  onChange={(value) => field.onChange(value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Parents & Guardian Information */}
                {/* Sibilings */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-users fs-16" />
                      </span>
                      <h4 className="text-dark">Sibilings</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="addsibling-info">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-2">
                            <label className="form-label">Sibling Info</label>
                            <div className="d-flex align-items-center flex-wrap">
                              <label className="form-label text-dark fw-normal me-2">
                                Is Sibling studying in the same school
                              </label>
                              <div className="form-check me-3 mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="sibling"
                                  id="yes"
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="yes"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="sibling"
                                  id="no"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="no"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {newContents.map((_, index) => (
                          <div key={index} className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-3 col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">Name</label>
                                  <CommonSelect
                                    className="select"
                                    options={names}
                                    defaultValue={isEdit ? names[0] : undefined}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">Roll No</label>
                                  <CommonSelect
                                    className="select"
                                    options={rollno}
                                    defaultValue={
                                      isEdit ? rollno[0] : undefined
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Admission No
                                  </label>
                                  <CommonSelect
                                    className="select"
                                    options={AdmissionNo}
                                    defaultValue={
                                      isEdit ? AdmissionNo[0] : undefined
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6">
                                <div className="mb-3">
                                  <div className="d-flex align-items-center">
                                    <div className="w-100">
                                      <label className="form-label">
                                        Class
                                      </label>
                                      <CommonSelect
                                        className="select"
                                        options={allClass}
                                        defaultValue={
                                          isEdit ? allClass[0] : undefined
                                        }
                                      />
                                    </div>
                                    {newContents.length > 1 && (
                                      <div>
                                        <label className="form-label">
                                          &nbsp;
                                        </label>
                                        <Link
                                          to="#"
                                          className="trash-icon ms-3"
                                          onClick={() => removeContent(index)}
                                        >
                                          <i className="ti ti-trash-x" />
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="border-top pt-3">
                      <Link
                        to="#"
                        onClick={addNewContent}
                        className="add-sibling btn btn-primary d-inline-flex align-items-center"
                      >
                        <i className="ti ti-circle-plus me-2" />
                        Add New
                      </Link>
                    </div>
                  </div>
                </div>
                {/* /Sibilings */}
                {/* Address */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-map fs-16" />
                      </span>
                      <h4 className="text-dark">Address</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Current Address</label>
                          <Controller
                            name="currentAddress"
                            control={control}
                            render={({ field }) => (
                              <input
                                type="text"
                                className="form-control"
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Permanent Address
                          </label>
                          <Controller
                            name="permanentAddress"
                            control={control}
                            render={({ field }) => (
                              <input
                                type="text"
                                className="form-control"
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Address */}
                {/* Transport Information */}
                <div className="card">
                  <div className="card-header bg-light d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-bus-stop fs-16" />
                      </span>
                      <h4 className="text-dark">Transport Information</h4>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Route</label>
                          <CommonSelect
                            className="select"
                            options={route}
                            defaultValue={isEdit ? route[0] : undefined}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Vehicle Number</label>
                          <CommonSelect
                            className="select"
                            options={VehicleNumber}
                            defaultValue={isEdit ? VehicleNumber[0] : undefined}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Pickup Point</label>
                          <CommonSelect
                            className="select"
                            options={PickupPoint}
                            defaultValue={isEdit ? PickupPoint[0] : undefined}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Transport Information */}
                {/* Hostel Information */}
                <div className="card">
                  <div className="card-header bg-light d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-building-fortress fs-16" />
                      </span>
                      <h4 className="text-dark">Hostel Information</h4>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Hostel</label>
                          <CommonSelect
                            className="select"
                            options={Hostel}
                            defaultValue={isEdit ? Hostel[0] : undefined}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Room No</label>
                          <CommonSelect
                            className="select"
                            options={roomNO}
                            defaultValue={isEdit ? roomNO[0] : undefined}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Hostel Information */}
                {/* Documents */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-file fs-16" />
                      </span>
                      <h4 className="text-dark">Documents</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-2">
                          <div className="mb-3">
                            <label className="form-label mb-1">
                              Medical Condition
                            </label>
                            <p>Upload image size of 4MB, Accepted Format PDF</p>
                          </div>
                          <div className="d-flex align-items-center flex-wrap">
                            <div className="btn btn-primary drag-upload-btn mb-2 me-2">
                              <i className="ti ti-file-upload me-1" />
                              Change
                              <input
                                type="file"
                                className="form-control image_sign"
                                multiple
                              />
                            </div>
                            {isEdit ? (
                              <p className="mb-2">BirthCertificate.pdf</p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-2">
                          <div className="mb-3">
                            <label className="form-label mb-1">
                              Upload Transfer Certificate
                            </label>
                            <p>Upload image size of 4MB, Accepted Format PDF</p>
                          </div>
                          <div className="d-flex align-items-center flex-wrap">
                            <div className="btn btn-primary drag-upload-btn mb-2">
                              <i className="ti ti-file-upload me-1" />
                              Upload Document
                              <input
                                type="file"
                                className="form-control image_sign"
                                multiple
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Documents */}
                {/* Medical History */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-medical-cross fs-16" />
                      </span>
                      <h4 className="text-dark">Medical History</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-2">
                          <label className="form-label">
                            Medical Condition
                          </label>
                          <div className="d-flex align-items-center flex-wrap">
                            <label className="form-label text-dark fw-normal me-2">
                              Medical Condition of a Student
                            </label>
                            <div className="form-check me-3 mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="condition"
                                id="good"
                                defaultChecked
                              />
                              <label
                                className="form-check-label"
                                htmlFor="good"
                              >
                                Good
                              </label>
                            </div>
                            <div className="form-check me-3 mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="condition"
                                id="bad"
                              />
                              <label className="form-check-label" htmlFor="bad">
                                Bad
                              </label>
                            </div>
                            <div className="form-check mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="condition"
                                id="others"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="others"
                              >
                                Others
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Allergies</label>

                        <TagsInput
                          // className="input-tags form-control"
                          value={owner2}
                          onChange={setOwner2}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Medications</label>
                        <TagsInput
                          // className="input-tags form-control"
                          value={owner1}
                          onChange={setOwner1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Medical History */}
                {/* Previous School details */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-building fs-16" />
                      </span>
                      <h4 className="text-dark">Previous School Details</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">School Name</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              isEdit ? "Oxford Matriculation, USA" : undefined
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Address</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              isEdit
                                ? "1852 Barnes Avenue, Cincinnati, OH 45202"
                                : undefined
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Previous School details */}
                {/* Other Details */}
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-building-bank fs-16" />
                      </span>
                      <h4 className="text-dark">Other Details</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="mb-3">
                          <label className="form-label">Bank Name</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              isEdit ? "Bank of America" : undefined
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="mb-3">
                          <label className="form-label">Branch</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={isEdit ? "Cincinnati" : undefined}
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="mb-3">
                          <label className="form-label">IFSC Number</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={isEdit ? "BOA83209832" : undefined}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">
                            Other Information
                          </label>
                          <textarea
                            className="form-control"
                            rows={3}
                            defaultValue={""}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header bg-light">
                    <div className="d-flex align-items-center">
                      <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                        <i className="ti ti-building-bank fs-16" />
                      </span>
                      <h4 className="text-dark">Fees Details</h4>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="row">
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Total Amount</label>
                          <Controller
                            name="totalFees"
                            control={control}
                            render={({ field }) => (
                              <input
                                type="text"
                                className="form-control"
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Amount Paid</label>
                          <Controller
                            name="feesPaid"
                            control={control}
                            render={({ field }) => (
                              <input
                                type="text"
                                className="form-control"
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Amount Due</label>
                          <Controller
                            name="feesDue"
                            control={control}
                            render={({ field }) => (
                              <input
                                type="text"
                                className="form-control"
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Total Discount</label>
                          <Controller
                            name="feesDiscount"
                            control={control}
                            render={({ field }) => (
                              <input
                                type="text"
                                className="form-control"
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="invoice-product-table">
                        <div className="table-responsive invoice-table">
                          <table className="table ">
                            <thead>
                              <tr>
                                <th>SL No.</th>
                                <th>Category</th>
                                <th>Amount</th>
                                <th>
                                  {" "}
                                  <Button onClick={handleAddFeesType}>
                                    <FaPlus />
                                  </Button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {feeData.items.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <Select
                                      className="react-select"
                                      options={feesOptions}
                                      onChange={(value: any, label: any) => {
                                        handleItemChange(
                                          index,
                                          "ledgerId",
                                          value?.value || "",
                                          label?.label || ""
                                        );
                                      }}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Amount"
                                      value={item.amount}
                                      onChange={(e) =>
                                        handleItemChange(
                                          index,
                                          "amount",
                                          parseFloat(e.target.value) || 0,
                                          ""
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      variant="danger"
                                      onClick={() => handleDeleteRow(index)}
                                    >
                                      <FaTrash />
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Other Details */}
                <div className="text-end">
                  <button type="button" className="btn btn-light me-3">
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {isEdit ? "Update Student" : "Add Student"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default AddStudent;
