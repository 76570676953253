import { Button } from "antd";
import { useRef } from "react";
import generatePDF from "react-to-pdf";

const GenerateInvoice = ({
  data = {
    invoiceNumber: "INV-12345",
    date: "2023-10-01",
    customerName: "John Doe",
    customerAddress: "123 Main St, Anytown, USA",
    items: [
      { description: "Tuition Fee", quantity: 1, unitPrice: 500, total: 500 },
      { description: "Library Fee", quantity: 1, unitPrice: 50, total: 50 },
      { description: "Lab Fee", quantity: 1, unitPrice: 100, total: 100 },
      { description: "Sports Fee", quantity: 1, unitPrice: 75, total: 75 },
    ],
    subtotal: 725,
    tax: 72.5,
    total: 797.5,
    recipientName: "Rahul Sharma",
    recipientPhone: "+9145203499",
    recipientEmail: "rahul13@gmail.com",
    recipientLocation: "New Delhi, 110086",
    paymentAccountNo: "1451******14",
    paymentAccountName: "Rahul Sharma",
    paymentBranchName: "West Delhi",
  },
  logo = "",
  signature = "Principal",
}) => {
  const invoiceComponentRef = useRef(null);

  const handleDownload = async () => {
    const invoicePDF_file = await generatePDF(invoiceComponentRef, {
      method: "open",
    });
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        {/* UPPER DIV */}
        <div className="d-md-flex d-block align-items-center  justify-content-between mb-3 ">
          <div className="my-auto mb-2 d-flex gap-4">
            <h3 className="page-title mb-1">Generate Invoice </h3>
            <Button type="primary" onClick={handleDownload}>
              Download <span className="fa fa-arrow-down ms-2"></span>
            </Button>
          </div>
        </div>
        {/* viewale invoice component */}
        <div id="viewable-invoice-component" className="card p-3 ">
          {/* UPPER DIV */}
          <div className="d-flex justify-content-between ">
            <div className="d-flex gap-3 flex-column justify-content-between">
              <div>
                <h4>Schooza</h4>
                <p className="mb-0 text-muted">Company Tagline Here</p>
              </div>
              <div className="text-right">
                <h5>Invoice to:</h5>
                <div>
                  <p className="m-0">{data.recipientName}</p>
                  <p className="m-0">Phone: {data.recipientPhone}</p>
                  <p className="m-0">Email: {data.recipientEmail}</p>
                  <p className="m-0">Location: {data.recipientLocation}</p>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 flex-column justify-content-between ">
              <div>
                <p className="m-0 mb-1 ">
                  <span className="fw-bold">Invoice No:</span> {data.invoiceNumber}
                </p>
                <p> Date: {data.date} </p>
              </div>
              <div>
                <h5> Payment Method</h5>
                <p className="m-0">Account No: {data.paymentAccountNo}</p>
                <p className="m-0">Account Name: {data.paymentAccountName}</p>
                <p className="m-0">Branch Name: {data.paymentBranchName}</p>
              </div>
            </div>
          </div>
          {/* MIDDLE DIV */}
          <div className="table-responsive mt-4">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>SL No.</th>
                  <th>Description/Item Name</th>
                  <th>Period</th>
                  <th>Quantity (QTY)</th>
                  <th>Unit Price</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {data.items.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.description}</td>
                    <td>Jan 2024 - Mar 2024</td>
                    <td>{item.quantity}</td>
                    <td>{item.unitPrice}</td>
                    <td>{item.total}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="5" className="text-right">
                    Subtotal
                  </td>
                  <td>{data.subtotal}</td>
                </tr>
                <tr>
                  <td colSpan="5" className="text-right">
                    Tax
                  </td>
                  <td>{data.tax}</td>
                </tr>
                <tr>
                  <td colSpan="5" className="text-right">
                    Total
                  </td>
                  <td>{data.total}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          {/* LOWER DIV */}
          <div className="d-flex flex-wrap-reverse gap-4 justify-content-between mt-5">
            <div className="d-flex gap-2 flex-column">
              <h5>Terms and Conditions</h5>
              <p className="m-0">1. Payment is due within 30 days.</p>
              <p className="m-0">2. Late payments may incur additional fees.</p>
              <p className="m-0">3. Please contact us for any queries.</p>
            </div>
            <div className="text-right">
              <h5>Authorized Signature</h5>
              <p className="m-0 mt-5">_________________________</p>
              <p className="m-0">{signature}</p>
            </div>
          </div>
        </div>

        {/* non viewable invoce component */}
        <div
          id="non-viewable-invoice-component"
          ref={invoiceComponentRef}
          style={{ position: "absolute", top: "-10000px", left: "-10000px" }}
          className="card p-3 "
        >
          {/* UPPER DIV */}
          <div className="d-flex justify-content-between ">
            <div className="d-flex gap-3 flex-column justify-content-between">
              <div>
                <h4>Schooza</h4>
                <p className="mb-0 text-muted">Company Tagline Here</p>
              </div>
              <div className="text-right">
                <h5>Invoice to:</h5>
                <div>
                  <p className="m-0">{data.recipientName}</p>
                  <p className="m-0">Phone: {data.recipientPhone}</p>
                  <p className="m-0">Email: {data.recipientEmail}</p>
                  <p className="m-0">Location: {data.recipientLocation}</p>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 flex-column justify-content-between ">
              <div>
                <p className="m-0 mb-1 ">
                  <span className="fw-bold">Invoice No:</span> {data.invoiceNumber}
                </p>
                <p> Date: {data.date} </p>
              </div>
              <div>
                <h5> Payment Method</h5>
                <p className="m-0">Account No: {data.paymentAccountNo}</p>
                <p className="m-0">Account Name: {data.paymentAccountName}</p>
                <p className="m-0">Branch Name: {data.paymentBranchName}</p>
              </div>
            </div>
          </div>
          {/* MIDDLE DIV */}
          <div className="table-responsive mt-4">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>SL No.</th>
                  <th>Description/Item Name</th>
                  <th>Period</th>
                  <th>Quantity (QTY)</th>
                  <th>Unit Price</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {data.items.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.description}</td>
                    <td>Jan 2024 - Mar 2024</td>
                    <td>{item.quantity}</td>
                    <td>{item.unitPrice}</td>
                    <td>{item.total}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="5" className="text-right">
                    Subtotal
                  </td>
                  <td>{data.subtotal}</td>
                </tr>
                <tr>
                  <td colSpan="5" className="text-right">
                    Tax
                  </td>
                  <td>{data.tax}</td>
                </tr>
                <tr>
                  <td colSpan="5" className="text-right">
                    Total
                  </td>
                  <td>{data.total}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          {/* LOWER DIV */}
          <div className="d-flex flex-wrap-reverse gap-4 justify-content-between mt-5">
            <div className="d-flex gap-2 flex-column">
              <h5>Terms and Conditions</h5>
              <p className="m-0">1. Payment is due within 30 days.</p>
              <p className="m-0">2. Late payments may incur additional fees.</p>
              <p className="m-0">3. Please contact us for any queries.</p>
            </div>
            <div className="text-right">
              <h5>Authorized Signature</h5>
              <p className="m-0 mt-5">_________________________</p>
              <p className="m-0">{signature}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateInvoice;
