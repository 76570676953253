import React, { useEffect } from "react";
import { Link, useNavigate, useNavigation, useParams } from "react-router-dom";
import PredefinedDateRanges from "../../core/common/datePicker";
import { TableData } from "../../core/data/interface";
import Table from "../../core/common/dataTable/index";
import { permission } from "../../core/data/json/permission";
import { all_routes } from "../router/all_routes";
import TooltipOption from "../../core/common/tooltipOption";
import axiosInstance from "../auth/axiosInstance";
import { Button } from "antd";
import { useSelector } from "react-redux";

const RolePermissions = () => {
  const data = permission;
  const routes = all_routes;
  const { roleId } = useParams();
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const navigate = useNavigate();
  const [roleData, setRoleData] = React.useState<any>({});
  const getRoleData = async () => {
    const res = await axiosInstance.get(`/role?id=${roleId}`);
    setRoleData(res.data);
  };
  const [permissions, setPermissions] = React.useState<
    { name: string; id: number; accessLevel: string }[]
  >([]);
  const getRolePermissions = async () => {
    const res = await axiosInstance.get(`/rolePermission/role/${roleId}`);
    const data = res.data.map((perm: any) => ({
      name: perm.name,
      id: perm.id,
      accessLevel: perm.accessLevel,
    }));
    setPermissions(data);
  };
  const handleUpdateRolePermissions = async () => {
    const res = await axiosInstance.patch(
      `/rolePermission/role/${roleId}?schoolId=${schoolId}`,
      permissions
    );
    console.log(res.data);
  };
  console.log(permissions);
  useEffect(() => {
    getRoleData();
    getRolePermissions();
  }, []);
  const columns = [
    {
      title: "Modules",
      dataIndex: "name",
      // sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },
    {
      title: "NoAccess",

      render: (text: string, record: any, index: number) => (
        <>
          <label className="custom-radio">
            <input
              type="radio"
              // name={`student${record.id}`}
              checked={record.accessLevel === "NoAccess"}
              // onChange={() => handleAttendanceChange(record.id, false)}
              onChange={(e) =>
                setPermissions((prev) => {
                  return prev.map((perm) => {
                    if (perm.id === record.id) {
                      return {
                        ...perm,
                        accessLevel: e.target.checked ? "NoAccess" : perm.accessLevel,
                      };
                    }
                    return perm;
                  });
                })
              }
            />
            <span className="checkmark " />
          </label>
        </>
      ),
      // sorter: (a: TableData, b: TableData) => a.delete.length - b.delete.length,
    },
    {
      title: "Read",

      render: (text: string, record: any, index: number) => (
        <>
          <label className="custom-radio">
            <input
              type="radio"
              // name={`student${record.id}`}
              checked={record.accessLevel === "Read"}
              onChange={(e) =>
                setPermissions((prev) => {
                  return prev.map((perm) => {
                    if (perm.id === record.id) {
                      return {
                        ...perm,
                        accessLevel: e.target.checked ? "Read" : perm.accessLevel,
                      };
                    }
                    return perm;
                  });
                })
              }
              // onChange={() => handleAttendanceChange(record.id, false)}
            />
            <span className="checkmark " />
          </label>
        </>
      ),
      // sorter: (a: TableData, b: TableData) => a.view.length - b.view.length,
    },
    {
      title: "Write",

      render: (text: string, record: any, index: number) => (
        <>
          <label className="custom-radio">
            <input
              type="radio"
              // name={`student${record.id}`}
              checked={record.accessLevel === "Write"}
              // onChange={() => handleAttendanceChange(record.id, false)}
              onChange={(e) =>
                setPermissions((prev) => {
                  return prev.map((perm) => {
                    if (perm.id === record.id) {
                      return {
                        ...perm,
                        accessLevel: e.target.checked ? "Write" : perm.accessLevel,
                      };
                    }
                    return perm;
                  });
                })
              }
            />
            <span className="checkmark " />
          </label>
        </>
      ),
      // sorter: (a: TableData, b: TableData) => a.edit.length - b.edit.length,
    },
  ];
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Roles &amp; Permissions</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">User Management</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Roles &amp; Permissions
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                <TooltipOption />
                <div className="mb-2">
                  <Link to={routes.roles} className="btn btn-primary d-flex align-items-center">
                    {/* <i className="ti ti-square-rounded-plus me-2" /> */}
                    All Roles
                  </Link>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Filter Section */}
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                <h4 className="mb-3">Role: {roleData.name}</h4>
                <p className="mb-3">Description: {roleData.description}</p>
                {/* <div className="d-flex align-items-center flex-wrap">
                  <div className="input-icon-start mb-3 me-2 position-relative">
                    <PredefinedDateRanges />
                  </div>
                  <div className="dropdown mb-3">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-sort-ascending-2 me-2" />
                      Sort by A-Z
                    </Link>
                    <ul className="dropdown-menu p-3">
                      <li>
                        <Link to="#" className="dropdown-item rounded-1 active">
                          Ascending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Descending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Viewed
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Added
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
              <div className="card-body p-0 py-3">
                {/* Student List */}
                <Table columns={columns} dataSource={permissions} Selection={false} />
                {/* /Student List */}
              </div>
            </div>
            <Button type="primary" className="mb-2" onClick={() => handleUpdateRolePermissions()}>
              Save Changes
            </Button>
            {/* /Filter Section */}
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
    </div>
  );
};

export default RolePermissions;
