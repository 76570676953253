import React, { useEffect, useState } from "react";
import {
  bloodGroup,
  Contract,
  gender,
  Hostel,
  Marital,
  PickupPoint,
  roomno,
  route,
  Shift,
  staffDepartment,
  staffrole,
  VehicleNumber,
} from "../../../../core/common/selectoption/selectoption";
import CommonSelect from "../../../../core/common/commonSelect";
import { Link, useParams } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { Button, DatePicker } from "antd";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import axios from "axios";
import dayjs from "dayjs";
import axiosInstance from "../../../auth/axiosInstance";
import { useSelector } from "react-redux";

const EditStaff = () => {
  const routes = all_routes;
  const [owner, setOwner] = useState<string[]>([]);
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const [departmentOptions, setDepartmentOptions] = useState([{ label: "Select", value: "" }]);

  const [designationOptions, setDesignationOptions] = useState([{ label: "Select", value: "" }]);
  const [roleOptions, setRoleOptions] = useState([{ label: "Select", value: "" }]);
  const [formData, setFormData] = useState({
    userId: undefined,
    first_name: "",
    last_name: "",
    fatherName: "",
    motherName: "",
    qualification: "",
    workExperience: "",
    address: "",
    permanentAddress: "",
    role: "",
    department: "",
    designation: "",
    epfNumber: "",
    basicSalary: "",
    gender: "",
    marital: "",
    contract: "",
    primary_contact: "",
    shift: "",
    route: "",
    vehicleNumber: "",
    pickupPoint: "",
    hostel: "",
    roomno: "",
    bloodGroup: "",
    email: "",
    phone: "",
    dateOfJoining: dayjs(Date.now()),
    dateOfBirth: dayjs(Date.now()),
    workLocation: "",
    leaves: [],
    accountName: "",
    accountNumber: "",
    bankName: "",
    ifscCode: "",
    branchName: "",
    facebookURL: "",
    twitterURL: "",
    linkedinURL: "",
    instagramURL: "",
    password: "",
    confirmPassword: "",
    note: "",
    schoolId: schoolId,
    // Add other fields as necessary
  });
  const { id } = useParams();

  const initData = async () => {
    const response = await axiosInstance.get(`/staff/${id}`);
    setFormData({
      schoolId: response.data.schoolId,
      userId: response.data.userId,
      first_name: response.data.firstName,
      last_name: response.data.lastName,
      fatherName: response.data.fathersName,
      motherName: response.data.mothersName,
      qualification: response.data.qualification,
      workExperience: response.data.workExperience,
      leaves: response.data.leaveBalance,
      address: response.data.currentAddress || "",
      permanentAddress: response.data.permanentAddress || "",
      role: response.data.role,
      department: response.data.department,
      designation: response.data.designation,
      epfNumber: response.data.epfNumber || "",
      basicSalary: response.data.basicSalary || "",
      gender: response.data.gender,
      marital: response.data.maritalStatus,
      contract: response.data.contractType || "",
      primary_contact: response.data.primaryContactNumber,
      shift: response.data.workShift || "",
      route: response.data.route || "",
      vehicleNumber: response.data.vehicleNumber || "",
      pickupPoint: response.data.pickupPoint || "",
      hostel: response.data.hostel || "",
      roomno: response.data.roomno || "",
      bloodGroup: response.data.bloodGroup,
      email: response.data.emailAddress,
      phone: response.data.phone || "",
      dateOfBirth: dayjs(new Date(response.data.dateOfBirth).toLocaleDateString()),
      dateOfJoining: dayjs(new Date(response.data.dateOfJoining).toLocaleDateString()),
      workLocation: response.data.workLocation || "",
      accountName: response.data.accountName || "",
      accountNumber: response.data.accountNumber || "",
      bankName: response.data.bankName || "",
      ifscCode: response.data.ifscCode || "",
      branchName: response.data.branchName || "",
      facebookURL: response.data.facebookURL || "",
      twitterURL: response.data.twitterURL || "",
      linkedinURL: response.data.linkedinURL || "",
      instagramURL: response.data.instagramURL || "",
      password: response.data.password || "",
      confirmPassword: response.data.confirmPassword || "",
      note: response.data.notes || "",
    });
    console.log("1️⃣1️⃣", dayjs(new Date(response.data.dateOfJoining).toLocaleDateString("en-US")));
    console.log(formData.dateOfJoining);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async () => {
    console.log(formData.first_name);

    const res = await axiosInstance.put(`/staff/edit?id=${id}`, { ...formData });
    console.log(res);
  };
  const getDepartments = async () => {
    if (!schoolId) return;
    try {
      const response = await axiosInstance.get(`/department?schoolId=${schoolId}`);
      if (response.status === 200) {
        const options = response.data.map((dep: any) => {
          return {
            label: dep.name,
            value: dep.name,
          };
        });
        setDepartmentOptions(options);
      }
    } catch (error) {
      console.error("Error getting departments:", error);
    }
  };

  console.log(formData.first_name);
  const getDesignations = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/designation", { params: { schoolId } });
    const data = res.data.map((des: any) => ({ label: des.name, value: des.name }));
    setDesignationOptions(data);
  };
  const getRoles = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/role/all", { params: { schoolId } });
    const data = res.data.map((des: any) => ({ label: des.name, value: des.name }));
    setRoleOptions(data);
  };
  useEffect(() => {
    getDepartments();
    getDesignations();
    getRoles();
    setTimeout(() => {
      initData();
    }, 1000);
  }, [schoolId]);
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content content-two">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="mb-1">Edit Staff</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">HRM</li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Edit Staff
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-md-12">
                <form>
                  {/* Personal Information */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-info-square-rounded fs-16" />
                        </span>
                        <h4 className="text-dark">Personal Information</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="add-section">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="d-flex align-items-center flex-wrap row-gap-3 mb-3">
                              <div className="d-flex align-items-center justify-content-center avatar avatar-xxl border border-dashed me-2 flex-shrink-0 text-dark frames">
                                <i className="ti ti-photo-plus fs-16" />
                              </div>
                              <div className="profile-upload">
                                <div className="profile-uploader d-flex align-items-center">
                                  <div className="drag-upload-btn mb-3">
                                    Upload
                                    <input
                                      type="file"
                                      className="form-control image-sign"
                                      multiple
                                    />
                                  </div>
                                  <Link to="#" className="btn btn-primary mb-3">
                                    Remove
                                  </Link>
                                </div>
                                <p className="fs-12">Upload image size 4MB, Format JPG, PNG, SVG</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row row-cols-xxl-5 row-cols-md-6">
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">First Name</label>
                              <input
                                type="text"
                                name="first_name"
                                className="form-control"
                                value={formData.first_name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Last Name</label>
                              <input
                                type="text"
                                name="last_name"
                                className="form-control"
                                value={formData.last_name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Role</label>

                              <Select
                                name="role"
                                className="select"
                                options={roleOptions}
                                onChange={(value) => {
                                  setFormData({ ...formData, role: value?.value || "" });
                                }}
                                value={{ value: formData.role, label: formData.role }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Department</label>
                              <Select
                                name="department"
                                className="select"
                                onChange={(value) => {
                                  setFormData({ ...formData, department: value?.value || "" });
                                }}
                                options={departmentOptions}
                                value={{
                                  value: formData.department,
                                  label: formData.department,
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Designation</label>
                              <Select
                                name="designation"
                                className="select"
                                options={designationOptions}
                                onChange={(value) => {
                                  setFormData({ ...formData, designation: value?.value || "" });
                                }}
                                value={{
                                  value: formData.designation,
                                  label: formData.designation,
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Gender</label>
                              <Select
                                name="gender"
                                className="select"
                                options={gender}
                                onChange={(value) => {
                                  setFormData({ ...formData, gender: value?.value || "" });
                                }}
                                value={{ value: formData.gender, label: formData.gender }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Primary Contact Number</label>
                              <input
                                name="primary_contact"
                                onChange={handleChange}
                                type="text"
                                className="form-control"
                                value={formData.primary_contact}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Email Address</label>
                              <input
                                name="email"
                                type="email"
                                className="form-control"
                                onChange={handleChange}
                                defaultValue={formData.email}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Blood Group</label>
                              <input
                                name="bloodGroup"
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                defaultValue={formData.bloodGroup}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Marital Status</label>
                              <Select
                                name="marital"
                                className="select"
                                options={Marital}
                                onChange={(value) => {
                                  setFormData({ ...formData, marital: value?.value || "" });
                                }}
                                value={{ value: formData.marital, label: formData.marital }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Father’s Name</label>
                              <input
                                name="fatherName"
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                defaultValue={formData.fatherName}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Mother’s Name</label>
                              <input
                                name="motherName"
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                defaultValue={formData.motherName}
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Date of Birth</label>
                              <div className="input-icon position-relative">
                                <span className="input-icon-addon">
                                  <i className="ti ti-calendar" />
                                </span>
                                <DatePicker
                                  name="dateOfBirth"
                                  value={formData.dateOfBirth}
                                  onChange={(date) => {
                                    setFormData({ ...formData, dateOfBirth: date });
                                  }}
                                  className="form-control datetimepicker"
                                  format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask",
                                  }}
                                  placeholder="Select Date"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Date of Joining</label>
                              <div className="input-icon position-relative">
                                <span className="input-icon-addon">
                                  <i className="ti ti-calendar" />
                                </span>
                                <DatePicker
                                  name="dateOfJoining"
                                  defaultValue={formData.dateOfJoining}
                                  onChange={(date) => {
                                    setFormData({ ...formData, dateOfJoining: date });
                                  }}
                                  className="form-control datetimepicker"
                                  format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask",
                                  }}
                                  placeholder="Select Date"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Language Known</label>
                              <TagsInput value={owner} onChange={setOwner} />
                            </div>
                          </div>
                          <div className="col-xxl-4 col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Qualification</label>
                              <input
                                name="qualification"
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                defaultValue={formData.qualification}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-4 col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Work Experience</label>
                              <input
                                name="workExperience"
                                onChange={handleChange}
                                type="text"
                                className="form-control"
                                defaultValue={formData.workExperience}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-4  col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Note</label>
                              <input
                                name="note"
                                onChange={handleChange}
                                type="text"
                                className="form-control"
                                defaultValue={formData.note}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-3  col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Address</label>
                              <input
                                name="address"
                                type="text"
                                onChange={handleChange}
                                value={formData.address}
                                className="form-control"
                                defaultValue="3495 Red Hawk Road, Buffalo Lake, MN 55314"
                              />
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-3  col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Permanent Address</label>
                              <input
                                name="permanentAddress"
                                type="text"
                                className="form-control"
                                defaultValue="3495 Red Hawk Road, Buffalo Lake, MN 55314"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Personal Information */}
                  {/* Payroll */}
                  <div className="card ">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-user-shield fs-16" />
                        </span>
                        <h4 className="text-dark">Payroll</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">EPF No</label>
                            <input
                              name="epfNumber"
                              defaultValue={formData.epfNumber}
                              onChange={handleChange}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Basic Salary</label>
                            <input
                              name="basicSalary"
                              defaultValue={formData.basicSalary}
                              onChange={handleChange}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Contract Type</label>
                            <Select
                              name="contract"
                              onChange={(value) => {
                                setFormData({ ...formData, contract: value?.value || "" });
                              }}
                              className="select"
                              options={Contract}
                              value={{ value: formData.contract, label: formData.contract }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Work Shift</label>
                            <Select
                              name="shift"
                              value={{ value: formData.shift, label: formData.shift }}
                              onChange={(value) => {
                                setFormData({ ...formData, shift: value?.value || "" });
                              }}
                              className="select"
                              options={Shift}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Work Location</label>
                            <input
                              name="workLocation"
                              defaultValue={formData.workLocation}
                              onChange={handleChange}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Payroll */}
                  {/* Leaves */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-users fs-16" />
                        </span>
                        <h4 className="text-dark">Leaves</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        {formData.leaves &&
                          formData.leaves.length > 0 &&
                          formData.leaves.map((leave: any, index: number) => (
                            <div className="col-lg-3 col-md-6" key={index}>
                              <div className="mb-3">
                                <label className="form-label">{leave.type_name}</label>
                                <div>
                                  <span className="me-3">
                                    Total:{" "}
                                    <input
                                      className="form-control"
                                      type="number"
                                      value={leave.totalLeaves ?? 0}
                                      onChange={(e) => {
                                        const newTotal = Math.max(
                                          parseInt(e.target.value, 10) || 0,
                                          leave.usedLeaves ?? 0
                                        );
                                        setFormData((prev: any) => ({
                                          ...prev,
                                          leaves: prev.leaves.map((l: any, i: number) =>
                                            i === index
                                              ? {
                                                  ...l,
                                                  totalLeaves: newTotal,
                                                  remainingLeaves: newTotal - (l.usedLeaves ?? 0),
                                                  usedLeaves: l.usedLeaves ?? 0,
                                                }
                                              : l
                                          ),
                                        }));
                                      }}
                                    />
                                  </span>
                                  <span className="me-3">Rem: {leave.remainingLeaves ?? 0}</span>
                                  <span className="me-3">Used: {leave.usedLeaves ?? 0}</span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  {/* /Leaves */}
                  {/* Bank Details */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-users fs-16" />
                        </span>
                        <h4 className="text-map">Bank Account Detail</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Account Name</label>
                            <input
                              name="accountName"
                              type="text"
                              className="form-control"
                              defaultValue={formData.accountName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Account Number</label>
                            <input
                              name="accountNumber"
                              type="text"
                              className="form-control"
                              defaultValue={formData.accountNumber}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Bank Name</label>
                            <input
                              name="bankName"
                              type="text"
                              className="form-control"
                              defaultValue={formData.bankName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">IFSC Code</label>
                            <input
                              name="ifscCode"
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              defaultValue={formData.ifscCode}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Branch Name</label>
                            <input
                              name="branchName"
                              type="text"
                              className="form-control"
                              defaultValue={formData.branchName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Bank Details */}
                  {/* Transport Information */}
                  <div className="card">
                    <div className="card-header bg-light d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-bus-stop fs-16" />
                        </span>
                        <h4 className="text-dark">Transport Information</h4>
                      </div>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" />
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Route</label>
                            <Select
                              name="route"
                              className="select"
                              options={route}
                              onChange={(value) => {
                                setFormData({ ...formData, route: value?.value || "" });
                              }}
                              value={{ value: formData.route, label: formData.route }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Vehicle Number</label>
                            <input
                              name="vehicleNumber"
                              type="text"
                              className="form-control"
                              defaultValue={formData.vehicleNumber}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Pickup Point</label>
                            <input
                              name="pickupPoint"
                              type="text"
                              className="form-control"
                              defaultValue={formData.pickupPoint}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Transport Information */}
                  {/* Hostel Information */}
                  <div className="card">
                    <div className="card-header bg-light d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-building-fortress fs-16" />
                        </span>
                        <h4 className="text-dark">Hostel Information</h4>
                      </div>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" />
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Hostel</label>
                            <input
                              name="hostel"
                              type="text"
                              className="form-control"
                              defaultValue={formData.hostel}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Room No</label>
                            <input
                              name="roomno"
                              type="text"
                              className="form-control"
                              defaultValue={formData.roomno}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Hostel Information */}
                  {/* Social Media Links */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-building fs-16" />
                        </span>
                        <h4 className="text-dark">Social Media Links</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Facebook URL</label>
                            <input
                              name="facebookURL"
                              type="text"
                              className="form-control"
                              defaultValue={formData.facebookURL}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Twitter URL</label>
                            <input
                              name="twitterURL"
                              type="text"
                              className="form-control"
                              defaultValue={formData.twitterURL}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Linkediin URL</label>
                            <input
                              name="linkedinURL"
                              type="text"
                              className="form-control"
                              defaultValue={formData.linkedinURL}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Instagram URL</label>
                            <input
                              name="instagramURL"
                              type="text"
                              className="form-control"
                              defaultValue={formData.instagramURL}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Social Media Links */}
                  {/* Documents */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-file fs-16" />
                        </span>
                        <h4 className="text-dark">Documents</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-2">
                            <div className="mb-3">
                              <label className="form-label">Upload Resume</label>
                              <p>Upload image size of 4MB, Accepted Format PDF</p>
                            </div>
                            <div className="d-flex align-items-center flex-wrap">
                              <div className="btn btn-primary drag-upload-btn mb-2 me-2">
                                <i className="ti ti-file-upload me-1" />
                                Change
                                <input type="file" className="form-control image_sign" multiple />
                              </div>
                              <p className="mb-2">Resume.pdf</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-2">
                            <div className="mb-3">
                              <label className="form-label">Upload Joining Letter</label>
                              <p>Upload image size of 4MB, Accepted Format PDF</p>
                            </div>
                            <div className="d-flex align-items-center flex-wrap">
                              <div className="btn btn-primary drag-upload-btn mb-2">
                                <i className="ti ti-file-upload me-1" />
                                Upload Document
                                <input type="file" className="form-control image_sign" multiple />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Documents */}
                  {/* Password */}
                  <div className="card">
                    <div className="card-header bg-light">
                      <div className="d-flex align-items-center">
                        <span className="bg-white avatar avatar-sm me-2 text-gray-7 flex-shrink-0">
                          <i className="ti ti-file fs-16" />
                        </span>
                        <h4 className="text-dark">Password</h4>
                      </div>
                    </div>
                    <div className="card-body pb-1">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">New Password</label>
                            <input type="password" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Confirm Password</label>
                            <input type="password" className="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Password */}
                  <div className="text-end">
                    <button type="button" className="btn btn-light me-3">
                      Cancel
                    </button>
                    <Button onClick={handleSubmit} className="btn btn-primary">
                      Save Changes
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
    </div>
  );
};

export default EditStaff;
