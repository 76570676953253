import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import ImageWithBasePath from "../../core/common/imageWithBasePath";
import { all_routes } from "../router/all_routes";
import generatePDF from "react-to-pdf";
import { Button } from "antd";
import axiosInstance from "../auth/axiosInstance";

interface InvoiceData {
  studentName: string;
  admissionId: string;
  class: string;
  invoiceNumber: string;
  date: string;
  paymentMode: string;
  address: string;
  email: string;
  mobile: string;
  subCategoryName: string;
  categoryName: string;

  items: { title: string; amount: number }[];
}

const Invoice = (data: InvoiceData) => {
  const location = useLocation();
  const { pdfJsonData } = location.state || {};
  const user: any = pdfJsonData?.user ? pdfJsonData.user : { userName: "Student", id: "Admission" };
  const invoiceComponentRef = useRef(null);
  console.log("sssssssssssssssssssssssssssss", pdfJsonData);

  const handleDownload = async () => {
    const invoicePDF_file = await generatePDF(invoiceComponentRef, {
      method: "open",
    });
  };

  const routes = all_routes;
  return (
    <div>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content content-two" ref={invoiceComponentRef}>
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Invoice</h3>

              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={routes.teacherList}>Finance &amp; Accounts</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    View Invoice
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="invoice-popup-head d-flex align-items-center justify-content-between mb-4">
                <span>
                  <ImageWithBasePath src="assets/img/logo.svg" alt="Img" />
                </span>
                <div className="popup-title">
                  <Button type="primary" onClick={handleDownload}>
                    Download <span className="fa fa-arrow-down ms-2"></span>
                  </Button>
                  <h2>UNIVERSITY NAME</h2>
                  <p>Original For Recipient</p>
                </div>
              </div>
              <div className="tax-info mb-2">
                <div className="mb-4 text-center">
                  <h1>Tax Invoice</h1>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="tax-invoice-info d-flex align-items-center justify-content-between">
                      <h5> {user.userName} Name :</h5>
                      <h6>{pdfJsonData?.name}</h6>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="tax-invoice-info d-flex align-items-center justify-content-between">
                      <h5>{user?.id} ID :</h5>
                      <h6>
                        {user?.userName === "Student" ? pdfJsonData?.admissionId : user?.userId}
                      </h6>
                    </div>
                  </div>
                  {user.userName === "Student" && (
                    <div className="col-lg-4">
                      <div className="tax-invoice-info d-flex align-items-center justify-content-between">
                        <h5>Class</h5>
                        <h6>{pdfJsonData?.class}</h6>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-4">
                    <div className="tax-invoice-info d-flex align-items-center justify-content-between">
                      <h5>Invoice No :</h5>
                      <h6>{pdfJsonData?.invoiceNo}</h6>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="tax-invoice-info d-flex align-items-center justify-content-between">
                      <h5>Invoice Date :</h5>
                      <h6>{pdfJsonData?.date}</h6>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="tax-invoice-info d-flex align-items-center justify-content-between">
                      <h5>Payment Method :</h5>
                      <h6>{pdfJsonData?.paymentMethodName}</h6>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <h6 className="mb-1">Bill To :</h6>
                  <p>
                    <span className="text-dark">{pdfJsonData?.name}</span> <br />
                    {pdfJsonData?.address} <br />
                    {pdfJsonData?.email} <br />
                    {pdfJsonData?.mobile}
                  </p>
                </div>
                <div className="invoice-product-table">
                  <div className="table-responsive invoice-table">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>SL No.</th>
                          <th>Category</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pdfJsonData &&
                          pdfJsonData?.items.map(
                            (item: { amount: number; ledgerName: string }, index: number) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.ledgerName}</td>
                                <td>{item.amount}</td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <h5 className="mb-1">Important Note: </h5>
                      <p className="text-dark mb-0">
                        Delivery dates are not guaranteed and Seller has
                      </p>
                      <p className="text-dark">
                        no liability for damages that may be incurred due to any delay. has
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="total-amount-tax">
                      <ul>
                        <li className="fw-medium text-dark">Subtotal</li>
                        <li className="fw-medium text-dark">Discount</li>
                        <li className="fw-medium text-dark">Tax</li>
                      </ul>
                      <ul>
                        <li>₹{pdfJsonData?.subTotal}</li>
                        <li>- ₹{pdfJsonData?.discount}</li>
                        <li>+ ₹{pdfJsonData?.tax}</li>
                      </ul>
                    </div>
                    <div className="total-amount-tax mb-3">
                      <ul className="total-amount">
                        <li className="text-dark">Amount Payable</li>
                      </ul>
                      <ul className="total-amount">
                        <li className="text-dark">₹{pdfJsonData?.amount}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="payment-info">
                  <div className="row align-items-center">
                    <div className="col-lg-6 mb-4 pt-4">
                      <h5 className="mb-2">School Stamp:</h5>
                    </div>
                    <div className="col-lg-6 text-end mb-4 pt-4 ">
                      <h5>Authorized Signature</h5>
                      <p className="m-0 mt-5">_________________________</p>
                    </div>
                  </div>
                </div>
                <div className="border-bottom text-center pt-4 pb-4">
                  <span className="text-dark fw-medium">Terms &amp; Conditions : </span>
                  <p>
                    Here we can write a additional notes for the client to get a<br></br>
                    better understanding of this invoice.
                  </p>
                </div>
                <p className="text-center pt-3">Thanks for your Business</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </div>
  );
};

export default Invoice;
