import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import themeSettingSlice from "./themeSettingSlice";
import sidebarSlice from "./sidebarSlice";
import studentSlice from "./studentSlice";
import userSlice from "./userSlice";
import metaDataSlice from "./metaDataSlice";
import axiosInstance from "../../../feature-module/auth/axiosInstance";

// Define the root state type
export type RootState = {
  themeSetting: ReturnType<typeof themeSettingSlice>;
  sidebarSlice: ReturnType<typeof sidebarSlice>;
  studentSlice: ReturnType<typeof studentSlice>;
  userSlice: ReturnType<typeof userSlice>;
  metaDataSlice: ReturnType<typeof metaDataSlice>;
};

// Fetch the encryption key from AWS Secrets Manager
const fetchEncryptionKey = async (): Promise<string> => {
  const response = await axiosInstance.get("/auth/encryption-key");
  console.log(response.data);
  return response.data;
};

const createPersistConfig = (secretKey: string) => ({
  key: "root",
  storage,
  transforms: [
    encryptTransform({
      secretKey,
      onError: function (error) {
        console.error(error);
      },
    }),
  ],
  whitelist: ["userSlice", "themeSetting", "sidebarSlice", "metaDataSlice"],
});

const rootReducer = combineReducers({
  themeSetting: themeSettingSlice,
  sidebarSlice,
  studentSlice,
  userSlice,
  metaDataSlice,
});

const initializeStore = async () => {
  const secretKey = process.env.REACT_APP_ENCRYPTION_KEY_FE;
  console.log(secretKey);
  let store;
  let persistor;

  if (secretKey) {
    const persistConfig = createPersistConfig(secretKey);
    const persistedReducer = persistReducer<RootState>(
      persistConfig,
      rootReducer
    );

    store = configureStore({
      reducer: persistedReducer,
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
          },
        }),
    });

    persistor = persistStore(store);
  } else {
    store = configureStore({
      reducer: rootReducer,
    });
  }

  return { store, persistor };
};

export default initializeStore;
export type AppDispatch = ReturnType<typeof configureStore>["dispatch"];
