import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import StudentModals from "../studentModals";
import StudentSidebar from "./studentSidebar";
import StudentBreadcrumb from "./studentBreadcrumb";
import Table from "../../../../core/common/dataTable/index";
import { TableData } from "../../../../core/data/interface";
import { leaveData } from "../../../../core/data/json/leaveData";
import { Attendance } from "../../../../core/data/json/attendance";
import axiosInstance from "../../../auth/axiosInstance";
import { Button } from "react-bootstrap";
import StudentDetails from "./studentDetails";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const StudentFees = () => {
  const navigate = useNavigate();
  const routes = all_routes;
  const data = leaveData;
  const data2 = Attendance;
  const [feeDataList, setFeeDataList] = useState([]);
  const studentDetails = useSelector((state: any) => state.studentSlice);
  const [feeData, setFeeData] = useState({
    id: null,
    type: "",
    invoiceNo: "",
    description: "",
    feesType: "",
    date: "",
    name: "",
    admissionId: "",
    class: "",
    customerAddress: "",
    items: [{ title: "", amount: 0 }],
    subtotal: 0,
    tax: 0,
    amount: 0,
    subTotal: 0,
    discount: 0,
    mobile: "",
    email: "",
    address: "",
    paymentMethod: "",
    userId: "",
    schoolId: "",
  });
  const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");

  const listColumns = [
    {
      title: "Id",
      dataIndex: "id",
      render: (text: string, record: any) => (
        <Button
          className="btn btn-primary"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            console.log(record);
            let feeData = record.feesJson;
            feeData.id = record.id;
            return navigate("/accounts/invoice", { state: { feeData } });
          }}
        >
          {record.id}
        </Button>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMethod",
    },
    {
      title: "Discount",
      dataIndex: "discount",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
  ];

  const fetchFeesList = async () => {
    console.log("sssssssssss", studentDetails);
    if (!studentDetails.userId) return;
    try {
      const response = await axiosInstance.get(`schoolAccountRoutes/user/${studentDetails.userId}`);

      if (response.data.length > 0) {
        console.log("fees List");

        let data = response.data.filter((d: any) => d.ledger.ledgerType === "asset");
        console.log("🛒🛒🛒🛒new data", data);
        data = data.map((d: any) => ({
          id: d.id,
          amount: d.amount,
          date: dayjs(d.date).format("DD-MMM-YYYY"),
          paymentMethod: d.ledger.name,
          discount: 0,
        }));

        setFeeDataList(data);
      }
    } catch (error) {
      console.error("Error fetching staff list:", error);
    }
  };

  useEffect(() => {
    fetchFeesList();
  }, [studentDetails.userId]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            {/* Page Header */}
            <StudentBreadcrumb />
            {/* /Page Header */}
          </div>
          <div className="row">
            {/* Student Information */}
            <StudentSidebar />
            {/* /Student Information */}
            <div className="col-xxl-9 col-xl-8">
              <div className="row">
                <div className="col-md-12">
                  {/* List */}
                  <ul className="nav nav-tabs nav-tabs-bottom mb-4">
                    <li>
                      <Link to={routes.studentDetail} className="nav-link">
                        <i className="ti ti-school me-2" />
                        Student Details
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentFees} className="nav-link active">
                        <i className="ti ti-report-money me-2" />
                        Fees History
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentFeesDetails} className="nav-link">
                        <i className="ti ti-report-money me-2" />
                        Fees Details
                      </Link>
                    </li>
                    {/*<li>
                      <Link to={routes.studentTimeTable} className="nav-link">
                        <i className="ti ti-table-options me-2" />
                        Time Table
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentLeaves} className="nav-link ">
                        <i className="ti ti-calendar-due me-2" />
                        Leave &amp; Attendance
                      </Link>
                    </li>
                    
                    <li>
                      <Link to={routes.studentResult} className="nav-link">
                        <i className="ti ti-bookmark-edit me-2" />
                        Exam &amp; Results
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.studentLibrary} className="nav-link">
                        <i className="ti ti-books me-2" />
                        Library
                      </Link>
                    </li>
                    */}
                  </ul>
                  {/* /List */}
                  <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                      <h4 className="mb-3">Fees</h4>
                      <div className="d-flex align-items-center flex-wrap">
                        <div className="dropdown mb-3 me-2">
                          <Link
                            to=""
                            className="btn btn-outline-light bg-white dropdown-toggle"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                          >
                            <i className="ti ti-calendar-due me-2" />
                            Year : 2024 / 2025
                          </Link>
                          <ul className="dropdown-menu p-3">
                            <li>
                              <Link to="" className="dropdown-item rounded-1">
                                Year : 2024 / 2025
                              </Link>
                            </li>
                            <li>
                              <Link to="" className="dropdown-item rounded-1">
                                Year : 2023 / 2024
                              </Link>
                            </li>
                            <li>
                              <Link to="" className="dropdown-item rounded-1">
                                Year : 2022 / 2023
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0 py-3">
                      {/* Fees List */}
                      <Table columns={listColumns} dataSource={feeDataList} Selection={false} />
                      {/* /Fees List */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      <StudentModals />
    </>
  );
};

export default StudentFees;
