import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { base_path } from "./environment";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../src/style/css/feather.css";
import "../src/index.scss";
import { Provider } from "react-redux";
import "../src/style/icon/boxicons/boxicons/css/boxicons.min.css";
import "../src/style/icon/weather/weathericons.css";
import "../src/style/icon/typicons/typicons.css";
import "../src/style/icon/fontawesome/css/fontawesome.min.css";
import "../src/style/icon/fontawesome/css/all.min.css";
import "../src/style/icon/ionic/ionicons.css";
import "../src/style/icon/tabler-icons/webfont/tabler-icons.css";
import ALLRoutes from "./feature-module/router/router";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import { PersistGate } from "redux-persist/integration/react";
import initializeStore from "./core/data/redux/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Root = () => {
  const [store, setStore] = useState<
    Awaited<ReturnType<typeof initializeStore>>["store"] | null
  >(null);
  const [persistor, setPersistor] = useState<
    Awaited<ReturnType<typeof initializeStore>>["persistor"] | null
  >(null);

  useEffect(() => {
    const initStore = async () => {
      const { store, persistor } = await initializeStore();
      setStore(store);
      setPersistor(persistor);
    };

    initStore();
  }, []);

  if (!store) {
    return <div>Loading...</div>;
  }

  return (
    <Provider store={store}>
      {persistor ? (
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename={base_path}>
            <ALLRoutes />
          </BrowserRouter>
        </PersistGate>
      ) : (
        <BrowserRouter basename={base_path}>
          <ALLRoutes />
        </BrowserRouter>
      )}
    </Provider>
  );
};

root.render(<Root />);
