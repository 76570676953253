import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import "bootstrap-daterangepicker/daterangepicker.css";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { all_routes } from "../../router/all_routes";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AdminDashboardModal from "./adminDashboardModal";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import StepModal from "./onbaordingModal";
import axiosInstance from "../../auth/axiosInstance";
import dayjs from "dayjs";

const AdminDashboard = () => {
  const formatIndianNumber = (number: any) => {
    if (!number) return 0;
    const parts = number.toString().split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1] ? "." + parts[1] : "";

    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);

    const formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    return otherDigits
      ? formattedOtherDigits + "," + lastThreeDigits + decimalPart
      : lastThreeDigits + decimalPart;
  };
  const routes = all_routes;

  const [date, setDate] = useState<Nullable<Date>>(null);
  const { kycStatus } = useSelector((state: any) => state.userSlice);
  const [showModal, setShowModal] = useState(false);
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const [schoolStats, setSchoolStats] = useState<any>();
  const [feeBarsData, setfeeBarsData] = useState<any>({
    series: [
      {
        name: "Fee Collected",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 5,
          borderRadiusApplication: "end",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: "₹ (Amount)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return "₹ " + val;
          },
        },
      },
    },
  });
  function SampleNextArrow(props: any) {
    const { style, onClick } = props;
    return (
      <div
        className="slick-nav slick-nav-next"
        style={{ ...style, display: "flex", top: "30%", right: "30%" }}
        onClick={onClick}
      >
        <i className="fas fa-chevron-right" style={{ color: "#677788" }}></i>
      </div>
    );
  }

  function SamplePrevArrow(props: any) {
    const { style, onClick } = props;
    return (
      <div
        className="slick-nav slick-nav-prev"
        style={{ ...style, display: "flex", top: "30%", left: "30%" }}
        onClick={onClick}
      >
        <i className="fas fa-chevron-left" style={{ color: "#677788" }}></i>
      </div>
    );
  }
  const settings = {
    dots: false,
    autoplay: false,
    arrows: false,
    slidesToShow: 2,
    margin: 24,
    speed: 500,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const student = {
    dots: false,
    autoplay: false,
    slidesToShow: 1,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const teacher = {
    dots: false,
    autoplay: false,
    slidesToShow: 1,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const [studentDonutChart, setStudentDonutChart] = useState<any>({
    chart: {
      height: 218,
      width: 218,
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    labels: ["Present", "Absent"],
    legend: {
      show: false,
    },
    colors: ["#138e11", "#dc3545"],
    series: [3610, 44],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 180,
          },
        },
      },
    ],
  });
  const [teacherDonutChart] = useState<any>({
    chart: {
      height: 218,
      width: 218,
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    colors: ["#3D5EE1", "#6FCCD8"],
    series: [346, 54],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 180,
          },
        },
      },
    ],
  });
  const [staffDonutChart, setStaffDonutChart] = useState<any>({
    chart: {
      height: 218,
      width: 218,
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    labels: ["Present", "Absent"],
    legend: {
      show: false,
    },
    colors: ["#138e11", "#dc3545"],
    series: [620, 80],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 180,
          },
        },
      },
    ],
  });
  const [classDonutChart] = useState<any>({
    chart: {
      height: 218,
      width: 218,
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    labels: ["Good", "Average", "Below Average"],
    legend: { show: false },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      tickAmount: 3,
      labels: {
        offsetX: -15,
      },
    },
    grid: {
      padding: {
        left: -8,
      },
    },
    colors: ["#3D5EE1", "#EAB300", "#E82646"],
    series: [45, 11, 2],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 180,
          },
        },
      },
    ],
  });
  const [feesBar] = useState<any>({
    chart: {
      height: 275,
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
      horizontalAlign: "left",
      position: "top",
      fontSize: "14px",
      labels: {
        colors: "#5D6369",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    colors: ["#3D5EE1", "#E9EDF4"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    grid: {
      padding: {
        left: -8,
      },
    },
    series: [
      {
        name: "Collected Fee",
        data: [30, 40, 38, 40, 38, 30, 35, 38, 40],
      },
      {
        name: "Total Fee",
        data: [45, 50, 48, 50, 48, 40, 40, 50, 55],
      },
    ],
    xaxis: {
      categories: [
        "Q1: 2023",
        "Q1: 2023",
        "Q1: 2023",
        "Q1: 2023",
        "Q1: 2023",
        "uQ1: 2023l",
        "Q1: 2023",
        "Q1: 2023",
        "Q1: 2023",
      ],
    },
    yaxis: {
      tickAmount: 3,
      labels: {
        offsetX: -15,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return "$ " + val + " thousands";
        },
      },
    },
  });
  const [totalEarningArea] = useState<any>({
    chart: {
      height: 90,
      type: "area",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    colors: ["#3D5EE1"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    series: [
      {
        name: "Earnings",
        data: [50, 55, 40, 50, 45, 55, 50],
      },
    ],
  });
  const [totalExpenseArea] = useState<any>({
    chart: {
      height: 90,
      type: "area",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    colors: ["#E82646"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    series: [
      {
        name: "Expense",
        data: [40, 30, 60, 55, 50, 55, 40],
      },
    ],
  });

  const getSchoolStats = async () => {
    if (!schoolId) return;
    const res = await axiosInstance.get("/school/" + schoolId + "/stats");
    setSchoolStats(res.data);
    const ydata = res.data.feesQuarters.map((f: any) => f.feesCollected);
    const xdata = res.data.feesQuarters.map((f: any) => f.quarter);
    setfeeBarsData((prev: any) => ({
      ...prev,
      series: [{ name: "Fees Collected", data: ydata }],
      options: { ...prev.options, xaxis: { categories: xdata } },
    }));
    setStaffDonutChart((prev: any) => ({ ...prev, series: res.data.todayStaffAttendance }));
    setStudentDonutChart((prev: any) => ({ ...prev, series: res.data.todayStudentAttendance }));
  };

  useEffect(() => {
    getSchoolStats();
  }, [schoolId]);
  // useEffect(() => {}, [schoolStats?.feesQuarters]);

  useEffect(() => {
    if (!kycStatus) {
      setShowModal(true);
    }
  }, [kycStatus]);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <>
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Admin Dashboard</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Admin Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-md-12">
                {/* Dashboard Content */}
                <div className="card bg-dark">
                  <div className="overlay-img">
                    <ImageWithBasePath
                      src="assets/img/bg/shape-04.png"
                      alt="img"
                      className="img-fluid shape-01"
                    />
                    <ImageWithBasePath
                      src="assets/img/bg/shape-01.png"
                      alt="img"
                      className="img-fluid shape-02"
                    />
                    <ImageWithBasePath
                      src="assets/img/bg/shape-02.png"
                      alt="img"
                      className="img-fluid shape-03"
                    />
                    <ImageWithBasePath
                      src="assets/img/bg/shape-03.png"
                      alt="img"
                      className="img-fluid shape-04"
                    />
                  </div>
                  <div className="card-body">
                    <div className="d-flex align-items-xl-center justify-content-xl-between flex-xl-row flex-column">
                      <div className="mb-3 mb-xl-0">
                        <div className="d-flex align-items-center flex-wrap mb-2">
                          <h1 className="text-white me-2">Welcome Back, Mr. Herald</h1>
                        </div>
                        <p className="text-white">Have a Good day at work</p>
                      </div>
                      <p className="text-white custom-text-white">.</p>
                    </div>
                  </div>
                </div>
                {/* /Dashboard Content */}
              </div>
            </div>
            <div className="row">
              {/* Total Students */}
              <div className="col-xxl-3 col-sm-6 d-flex">
                <div className="card flex-fill animate-card border-0">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="avatar avatar-xl bg-danger-transparent me-2 p-1">
                        <ImageWithBasePath src="assets/img/icons/student.svg" alt="img" />
                      </div>
                      <div className="overflow-hidden flex-fill">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="counter">
                            <CountUp end={schoolStats?.studentsCnt || 100} />
                          </h2>
                          {/* <span className="badge bg-danger">1.4%</span> */}
                        </div>
                        <p>Total Students</p>
                      </div>
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-between border-top mt-3 pt-3">
                      <p className="mb-0">
                        Active : <span className="text-dark fw-semibold">3643</span>
                      </p>
                      <span className="text-light">|</span>
                      <p>
                        Inactive : <span className="text-dark fw-semibold">11</span>
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* /Total Students */}
              {/* Total Teachers */}
              <div className="col-xxl-3 col-sm-6 d-flex">
                <div className="card flex-fill animate-card border-0">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="avatar avatar-xl me-2 bg-secondary-transparent p-1">
                        <ImageWithBasePath src="assets/img/icons/teacher.svg" alt="img" />
                      </div>
                      <div className="overflow-hidden flex-fill">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="counter">
                            <CountUp end={schoolStats?.teacherCnt || 100} />
                          </h2>
                          {/* <span className="badge bg-pending">1.2%</span> */}
                        </div>
                        <p>Total Teachers</p>
                      </div>
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-between border-top mt-3 pt-3">
                      <p className="mb-0">
                        Active : <span className="text-dark fw-semibold">254</span>
                      </p>
                      <span className="text-light">|</span>
                      <p>
                        Inactive : <span className="text-dark fw-semibold">30</span>
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* /Total Teachers */}
              {/* Total Staff */}
              <div className="col-xxl-3 col-sm-6 d-flex">
                <div className="card flex-fill animate-card border-0">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="avatar avatar-xl me-2 bg-warning-transparent p-1">
                        <ImageWithBasePath src="assets/img/icons/staff.svg" alt="img" />
                      </div>
                      <div className="overflow-hidden flex-fill">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="counter">
                            <CountUp end={schoolStats?.staffCnt || 100} />
                          </h2>
                          {/* <span className="badge bg-warning">1.2%</span> */}
                        </div>
                        <p>Total Staff</p>
                      </div>
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-between border-top mt-3 pt-3">
                      <p className="mb-0">
                        Active : <span className="text-dark fw-semibold">161</span>
                      </p>
                      <span className="text-light">|</span>
                      <p>
                        Inactive : <span className="text-dark fw-semibold">02</span>
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* /Total Staff */}
              {/* Total Subjects */}
              <div className="col-xxl-3 col-sm-6 d-flex">
                <div className="card flex-fill animate-card border-0">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="avatar avatar-xl me-2 bg-success-transparent p-1">
                        <ImageWithBasePath src="assets/img/icons/subject.svg" alt="img" />
                      </div>
                      <div className="overflow-hidden flex-fill">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="counter">
                            <CountUp end={schoolStats?.subjectsCnt || 0} />
                          </h2>
                          {/* <span className="badge bg-success">1.2%</span> */}
                        </div>
                        <p>Total Subjects</p>
                      </div>
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-between border-top mt-3 pt-3">
                      <p className="mb-0">
                        Active : <span className="text-dark fw-semibold">81</span>
                      </p>
                      <span className="text-light">|</span>
                      <p>
                        Inactive : <span className="text-dark fw-semibold">01</span>
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* /Total Subjects */}
            </div>
            <div className="row">
              {/* Attendance */}
              <div className="col-xxl-4 col-xl-6 col-md-12 d-flex flex-column">
                <div className="card">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <h4 className="card-title">Attendance</h4>
                    {/* <div className="dropdown">
                      <Link to="#" className="bg-white dropdown-toggle" data-bs-toggle="dropdown">
                        <i className="ti ti-calendar-due me-1" />
                        Today
                      </Link>
                      <ul className="dropdown-menu mt-2 p-3">
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            This Week
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            Last Week
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            Last Week
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                    <h5 className="form-title">{dayjs().format("DD MMM YYYY")} </h5>
                  </div>
                  <div className="card-body">
                    <div className="list-tab mb-4">
                      <ul className="nav">
                        <li>
                          <Link
                            to="#"
                            className="active"
                            data-bs-toggle="tab"
                            data-bs-target="#students"
                          >
                            Students
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="#" data-bs-toggle="tab" data-bs-target="#teachers">
                            Teachers
                          </Link>
                        </li> */}
                        <li>
                          <Link to="#" data-bs-toggle="tab" data-bs-target="#staff">
                            Staff
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content">
                      <div className="tab-pane fade active show" id="students">
                        <div className="row gx-3">
                          <div className="col-sm-4">
                            <div className="card bg-light-300 shadow-none border-0">
                              <div className="card-body p-3 text-center">
                                <h5>{schoolStats?.todayStudentAttendance[0]}</h5>
                                <p className="fs-12">Present</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="card bg-light-300 shadow-none border-0">
                              <div className="card-body p-3 text-center">
                                <h5>{schoolStats?.todayStudentAttendance[1]}</h5>
                                <p className="fs-12">Absent</p>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-sm-4">
                            <div className="card bg-light-300 shadow-none border-0">
                              <div className="card-body p-3 text-center">
                                <h5>01</h5>
                                <p className="fs-12">Late</p>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div className="text-center">
                          <ReactApexChart
                            id="student-chart"
                            className="mb-4"
                            options={studentDonutChart}
                            series={studentDonutChart.series}
                            type="donut"
                            height={210}
                          />
                          <Link to={routes.studentAttendance} className="btn btn-light">
                            <i className="ti ti-calendar-share me-1" />
                            View All
                          </Link>
                        </div>
                      </div>
                      {/* <div className="tab-pane fade" id="teachers">
                        <div className="row gx-3">
                          <div className="col-sm-4">
                            <div className="card bg-light-300 shadow-none border-0">
                              <div className="card-body p-3 text-center">
                                <h5>30</h5>
                                <p className="fs-12">Emergency</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="card bg-light-300 shadow-none border-0">
                              <div className="card-body p-3 text-center">
                                <h5>03</h5>
                                <p className="fs-12">Absent</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="card bg-light-300 shadow-none border-0">
                              <div className="card-body p-3 text-center">
                                <h5>03</h5>
                                <p className="fs-12">Late</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <ReactApexChart
                            id="teacher-chart"
                            className="mb-4"
                            options={teacherDonutChart}
                            series={teacherDonutChart.series}
                            type="donut"
                            height={210}
                          />
                          <Link to={routes.staffAttendance} className="btn btn-light">
                            <i className="ti ti-calendar-share me-1" />
                            View All
                          </Link>
                        </div>
                      </div> */}
                      <div className="tab-pane fade" id="staff">
                        <div className="row gx-3">
                          <div className="col-sm-4">
                            <div className="card bg-light-300 shadow-none border-0">
                              <div className="card-body p-3 text-center">
                                <h5>{schoolStats?.todayStaffAttendance[0] || 0}</h5>
                                <p className="fs-12">Present</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="card bg-light-300 shadow-none border-0">
                              <div className="card-body p-3 text-center">
                                <h5>{schoolStats?.todayStaffAttendance[1] || 0}</h5>
                                <p className="fs-12">Absent</p>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-sm-4">
                            <div className="card bg-light-300 shadow-none border-0">
                              <div className="card-body p-3 text-center">
                                <h5>10</h5>
                                <p className="fs-12">Late</p>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div className="text-center">
                          <div id="staff-chart" className="mb-4" />
                          <ReactApexChart
                            id="staff-chart"
                            className="mb-4"
                            options={staffDonutChart}
                            series={staffDonutChart.series}
                            type="donut"
                            height={210}
                          />
                          <Link to={routes.staffAttendance} className="btn btn-light">
                            <i className="ti ti-calendar-share me-1" />
                            View All
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Attendance */}
              <div className="col-xxl-4 col-md-12 d-flex flex-column">
                {/* Quick Links */}
                <div className="card flex-fill">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <h4 className="card-title">Quick Links</h4>
                  </div>
                  <div className="card-body pb-1">
                    <Slider {...settings} className="owl-carousel link-slider">
                      <div className="item">
                        <Link
                          to={routes.classTimetable}
                          className="d-block bg-success-transparent ronded p-2 text-center mb-3 class-hover"
                        >
                          <div className="avatar avatar-lg border p-1 border-success rounded-circle mb-2">
                            <span className="d-inline-flex align-items-center justify-content-center w-100 h-100 bg-success rounded-circle">
                              <i className="ti ti-calendar" />
                            </span>
                          </div>
                          <p className="text-dark">Calendar</p>
                        </Link>
                        <Link
                          to={routes.feesGroup}
                          className="d-block bg-secondary-transparent ronded p-2 text-center mb-3 class-hover"
                        >
                          <div className="avatar avatar-lg border p-1 border-secondary rounded-circle mb-2">
                            <span className="d-inline-flex align-items-center justify-content-center w-100 h-100 bg-secondary rounded-circle">
                              <i className="ti ti-license" />
                            </span>
                          </div>
                          <p className="text-dark">Fees</p>
                        </Link>
                      </div>
                      <div className="item">
                        <Link
                          to={routes.examResult}
                          className="d-block bg-primary-transparent ronded p-2 text-center mb-3 class-hover"
                        >
                          <div className="avatar avatar-lg border p-1 border-primary rounded-circle mb-2">
                            <span className="d-inline-flex align-items-center justify-content-center w-100 h-100 bg-primary rounded-circle">
                              <i className="ti ti-hexagonal-prism" />
                            </span>
                          </div>
                          <p className="text-dark">Exam Result</p>
                        </Link>
                        <Link
                          to={routes.classHomeWork}
                          className="d-block bg-danger-transparent ronded p-2 text-center mb-3 class-hover"
                        >
                          <div className="avatar avatar-lg border p-1 border-danger rounded-circle mb-2">
                            <span className="d-inline-flex align-items-center justify-content-center w-100 h-100 bg-danger rounded-circle">
                              <i className="ti ti-report-money" />
                            </span>
                          </div>
                          <p className="text-dark">Home Works</p>
                        </Link>
                      </div>
                      <div className="item">
                        <Link
                          to={routes.studentAttendance}
                          className="d-block bg-warning-transparent ronded p-2 text-center mb-3 class-hover"
                        >
                          <div className="avatar avatar-lg border p-1 border-warning rounded-circle mb-2">
                            <span className="d-inline-flex align-items-center justify-content-center w-100 h-100 bg-warning rounded-circle">
                              <i className="ti ti-calendar-share" />
                            </span>
                          </div>
                          <p className="text-dark">Attendance</p>
                        </Link>
                        <Link
                          to={routes.attendanceReport}
                          className="d-block bg-skyblue-transparent ronded p-2 text-center mb-3 class-hover"
                        >
                          <div className="avatar avatar-lg border p-1 border-skyblue rounded-circle mb-2">
                            <span className="d-inline-flex align-items-center justify-content-center w-100 h-100 bg-pending rounded-circle">
                              <i className="ti ti-file-pencil" />
                            </span>
                          </div>
                          <p className="text-dark">Reports</p>
                        </Link>
                      </div>
                    </Slider>
                  </div>
                </div>
                {/* /Quick Links */}
              </div>
              {/* Notice Board */}
              <div className="col-xxl-4 col-md-12 d-flex flex-column">
                <div className="card flex-fill">
                  <div className="card-header  d-flex align-items-center justify-content-between">
                    <h4 className="card-title">Notice Board</h4>
                    <Link to={routes.noticeBoard} className="fw-medium">
                      View All
                    </Link>
                  </div>
                  <div className="card-body">
                    <div className="notice-widget">
                      {schoolStats?.notices &&
                        schoolStats.notices.map((notice: any) => (
                          <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <div className="d-flex align-items-center overflow-hidden me-2 mb-2 mb-sm-0">
                              <span className="bg-skyblue-transparent avatar avatar-md me-2 rounded-circle flex-shrink-0">
                                <i className="ti ti-notes fs-16" />
                              </span>
                              <div className="overflow-hidden">
                                <h6 className="text-truncate mb-1">{notice.title}</h6>
                                <p>
                                  <i className="ti ti-calendar me-2" />
                                  Added on : {dayjs(notice.date).format("DD MMM YYYY")}
                                </p>
                              </div>
                            </div>
                            {/* <span className="badge bg-light text-dark">
                              <i className="ti ti-clck me-1" />
                              02 Days
                            </span> */}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* /Notice Board */}
            </div>
            <div className="row">
              {/* Fees Collection */}
              <div className="col-xxl-8 col-xl-6 d-flex">
                <div className="card flex-fill">
                  <div className="card-header  d-flex align-items-center justify-content-between">
                    <h4 className="card-title">Fees Collection</h4>
                    {/* <div className="dropdown">
                      <Link to="#" className="bg-white dropdown-toggle" data-bs-toggle="dropdown">
                        <i className="ti ti-calendar  me-2" />
                        Last 8 Quater
                      </Link>
                      <ul className="dropdown-menu mt-2 p-3">
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            This Month
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            This Year
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            Last 12 Quater
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item rounded-1">
                            Last 16 Quater
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <div className="card-body pb-0">
                    {schoolStats && schoolStats?.feesQuarters && (
                      <ReactApexChart
                        id="fees-chart"
                        options={feeBarsData.options}
                        series={feeBarsData.series}
                        type="bar"
                        height={270}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* /Fees Collection */}
              {/* Leave Requests */}
              <div className="col-xxl-4 col-xl-6 d-flex">
                <div className="card flex-fill">
                  <div className="card-header  d-flex align-items-center justify-content-between">
                    <h4 className="card-title">Leave Requests</h4>
                    <Link to={routes.leaveApplications} className="fw-medium">
                      View All
                    </Link>
                  </div>

                  {schoolStats?.leaveApplications &&
                    schoolStats?.leaveApplications.map((leave: any) => (
                      <div className="card-body">
                        <div className="card mb-2">
                          <div className="card-body p-3">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <div className="d-flex align-items-center overflow-hidden me-2">
                                <Link to="#" className="avatar avatar-lg flex-shrink-0 me-2">
                                  <ImageWithBasePath
                                    src="assets/img/profiles/avatar-14.jpg"
                                    alt="student"
                                  />
                                </Link>
                                <div className="overflow-hidden">
                                  <h6 className="mb-1 text-truncate">
                                    <Link to="#">{leave.appliedBy}</Link>
                                    <span className="badge badge-info ms-1">
                                      {leave.leaveType.type_name}
                                    </span>
                                  </h6>
                                  {/* <p className="text-truncate">Physics Teacher</p> */}
                                </div>
                              </div>
                              {/* <div className="d-flex align-items-center">
                                <Link to="#" className="avatar avatar-xs p-0 btn btn-success me-1">
                                  <i className="ti ti-checks" />
                                </Link>
                                <Link to="#" className="avatar avatar-xs p-0 btn btn-danger">
                                  <i className="ti ti-x" />
                                </Link>
                              </div> */}
                            </div>
                            <div className="d-flex align-items-center justify-content-between border-top pt-3">
                              <p className="mb-0">
                                Leave :{" "}
                                <span className="fw-semibold">
                                  {dayjs(leave.startDate).format("DD")} -
                                  {dayjs(leave.endDate).format("DD MMM")}
                                </span>
                              </p>
                              <p>
                                Apply on :{" "}
                                <span className="fw-semibold">
                                  {dayjs(leave.createdAt).format("DD MMM YYYY")}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {/* /Leave Requests */}

            <div className="row">
              {/* Total Earnings */}
              <div className="col-xxl-4 col-xl-6 d-flex flex-column">
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h6 className="mb-1">Total Earnings</h6>
                        <h2>₹ {formatIndianNumber(schoolStats?.totalIncome)} </h2>
                      </div>
                      <span className="avatar avatar-lg bg-primary">
                        <i className="ti ti-user-dollar" />
                      </span>
                    </div>
                  </div>
                  {/* <div id="total-earning" /> */}
                  <ReactApexChart
                    id="total-earning"
                    options={totalEarningArea}
                    series={totalEarningArea.series}
                    type="area"
                    height={90}
                  />
                </div>
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h6 className="mb-1">Total Expenses</h6>
                        <h2>₹ {formatIndianNumber(schoolStats?.totalExpense)} </h2>
                      </div>
                      <span className="avatar avatar-lg bg-danger">
                        <i className="ti ti-user-dollar" />
                      </span>
                    </div>
                  </div>
                  <div id="total-expenses" />
                  <ReactApexChart
                    id="total-expenses"
                    options={totalExpenseArea}
                    series={totalExpenseArea.series}
                    type="area"
                    height={90}
                  />
                </div>
              </div>
              {/* /Total Earnings */}

              {/* Fees Collection */}
              <div className="col-xxl-3 col-xl-6 order-2 order-xxl-3 d-flex flex-column">
                <div className="card flex-fill mb-2">
                  <div className="card-body">
                    <p className="mb-2">Total Fees Collected</p>
                    <div className="d-flex align-items-end justify-content-between">
                      <h2>₹ {formatIndianNumber(schoolStats?.totalCollectedFees)} </h2>
                      {/* <span className="badge badge-soft-success">
                        <i className="ti ti-chart-line me-1" />
                        1.2%
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="card flex-fill mb-2">
                  <div className="card-body">
                    <p className="mb-2">Fine Collected till date</p>
                    <div className="d-flex align-items-end justify-content-between">
                      <h2>₹ {formatIndianNumber(schoolStats?.totalFineCollectedFees)} </h2>
                      {/* <span className="badge badge-soft-danger">
                        <i className="ti ti-chart-line me-1" />
                        1.2%
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="card flex-fill mb-2">
                  <div className="card-body">
                    <p className="mb-2">Total Due Fees</p>
                    <div className="d-flex align-items-end justify-content-between">
                      <h2>₹ {formatIndianNumber(schoolStats?.totalDueFees)} </h2>
                      {/* <span className="badge badge-soft-info">
                        <i className="ti ti-chart-line me-1" />
                        1.2%
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* /Fees Collection */}
            </div>
          </>
        </div>
      </div>
      {/* /Page Wrapper */}
      <AdminDashboardModal />
      {/* modal for onbaroding */}
      <div className="modal fade active" id="delete-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-body text-center">
                <span className="delete-icon">
                  <i className="ti ti-trash-x" />
                </span>
                <h4>Confirm Deletion</h4>
                <p>You want to delete all the marked items, this cant be undone once you delete.</p>
                <div className="d-flex justify-content-center">
                  <Link to="#" className="btn btn-light me-3" data-bs-dismiss="modal">
                    Cancel
                  </Link>
                  <button type="submit" className="btn btn-danger" data-bs-dismiss="modal">
                    Yes, Delete
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <StepModal showModal={showModal} closeModal={() => setShowModal(false)} />
      {/* modal for onbaroding */}
    </>
  );
};

export default AdminDashboard;
