import React, { useEffect, useRef, useState } from "react";
// import { classes } from "../../../core/data/json/classes";
import Table from "../../../core/common/dataTable/index";
import PredefinedDateRanges from "../../../core/common/datePicker";
import Select from "react-select";
import {
  activeList,
  classSection,
  classSylabus,
  school,
} from "../../../core/common/selectoption/selectoption";
import CommonSelect from "../../../core/common/commonSelect";
import { Studentlist } from "../../../core/data/json/studentList";
import { TableData } from "../../../core/data/interface";
import { Link, useParams } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import { Button } from "react-bootstrap";
import axios from "axios";
import { DatePicker, DatePickerProps } from "antd";
import dayjs from "dayjs";
import axiosInstance from "../../auth/axiosInstance";
import { useSelector } from "react-redux";

const ClassDetails = () => {
  const routes = all_routes;
  const { id } = useParams<{ id: string }>();
  const { schoolId } = useSelector((state: any) => state.userSlice);
  const [activeTab, setActiveTab] = React.useState<string>("studentsList");
  const [subjects, setSubjects] = React.useState<any[]>([]);
  const [students, setStudents] = React.useState<any[]>([]);
  const today = dayjs().format("DD-MM-YYYY");
  const [date, setDate] = useState<string>(today);
  const [absenties, setAbsenties] = useState<string[]>([]);
  const [attendance, setAttendance] = useState<any[]>([]);
  const [newAbsenties, setNewAbsenties] = useState<string[]>([]);
  const [isUploadEnabled, setIsUploadEnabled] = useState<boolean>(false);

  const [classDetails, setClassDetails] = React.useState<any>({
    classTeacher: {
      firstName: "",
      lastName: "",
      primaryContactNumber: "",
      emailAddress: "",
      qualification: "",
      workExperience: "",
    },
    className: "",
    "No of Students": 0,
    "No of Subjects": 0,
  });
  const handleGetAttendace = async () => {
    let absenties: any[] = [];
    let res: any;
    try {
      res = await axiosInstance.get("/attendance/byDateAndClass", {
        params: {
          date: dayjs(date, "DD-MM-YYYY").format("YYYY-MM-DD"),
          classId: id,
          schoolId: schoolId,
        },
      });

      absenties = res.data.absenties;
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        absenties = [];
      } else {
        console.error(err);
        return;
      }
    }

    setAbsenties(absenties);
    setNewAbsenties(absenties); // Initialize newAbsenties with fetched absenties
    setAttendance((prevStudents = []) => {
      const studentsList = prevStudents.map((s: any) => {
        const absent = absenties.includes(s.id.toString());
        return {
          ...s,
          absent,
        };
      });
      console.log(studentsList);

      return studentsList;
    });
  };

  const handleAttendanceChange = (studentId: string, isAbsent: boolean) => {
    setAttendance((prevAttendance) =>
      prevAttendance.map((student: any) =>
        student.id === studentId ? { ...student, absent: isAbsent } : student
      )
    );
    const updatedAbsenties = attendance
      .filter((student: any) => (student.id === studentId ? isAbsent : student.absent))
      .map((student: any) => student.id.toString());
    setNewAbsenties(updatedAbsenties);
    setIsUploadEnabled(JSON.stringify(updatedAbsenties) !== JSON.stringify(absenties));
  };

  const handleUploadAttendance = async () => {
    if (!schoolId) return;
    // Implement the logic to upload the new attendance data
    console.log("Uploading new attendance data:", newAbsenties);
    const res = await axiosInstance.put("/attendance/updateByDateAndClass", {
      date: dayjs(date, "DD-MM-YYYY").format("YYYY-MM-DD"),
      classId: id,
      schoolId: schoolId,
      absenties: newAbsenties,
    });
    console.log(res);
  };

  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const studentColumns = [
    {
      title: "Admission ID",
      dataIndex: "id",
      sorter: (a: TableData, b: TableData) => a.RollNo.length - b.RollNo.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">{text}</Link>
            </p>
          </div>
        </div>
      ),
      sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },
    {
      title: "Contact No.",
      dataIndex: "contactNumber",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <p className="text-dark mb-0">{record.contactNumber}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Date of Birth",
      dataIndex: "dateOfBirth",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <p className="text-dark mb-0">
              {new Date(record.dateOfBirth).toLocaleDateString("en-GB")}
            </p>
          </div>
        </div>
      ),
    },
  ];

  const subjectColumns = [
    {
      title: "Subject Name",
      dataIndex: "subjectName",
      sorter: (a: TableData, b: TableData) => a.name.localeCompare(b.name),
    },
    {
      title: "Teacher",
      dataIndex: "subjectTeacher",
      sorter: (a: TableData, b: TableData) => a.subjectTeacher.localeCompare(b.subjectTeacher),
    },
  ];

  const attendanceColumns = [
    {
      title: "Admission ID",
      dataIndex: "id",
      sorter: (a: TableData, b: TableData) => a.RollNo.length - b.RollNo.length,
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <p className="text-dark mb-0">{record.id}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">{text}</Link>
            </p>
          </div>
        </div>
      ),
      sorter: (a: TableData, b: TableData) => a.name.length - b.name.length,
    },
    {
      title: "Contact No.",
      dataIndex: "contactNumber",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <p className="text-dark mb-0">{record.contactNumber}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Date of Birth",
      dataIndex: "dateOfBirth",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          <div className="ms-2">
            <p className="text-dark mb-0">
              {new Date(record.dateOfBirth).toLocaleDateString("en-GB")}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "Attendance",
      dataIndex: "attendance",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center check-radio-group flex-nowrap">
          <label className="custom-radio">
            <input
              type="radio"
              name={`student${record.id}`}
              checked={record.absent === false}
              onChange={() => handleAttendanceChange(record.id, false)}
            />
            <span className="checkmark checkmark-present" />
            Present
          </label>
          <label className="custom-radio">
            <input
              type="radio"
              name={`student${record.id}`}
              checked={record.absent === true}
              onChange={() => handleAttendanceChange(record.id, true)}
            />
            <span className="checkmark checkmark-absent" />
            Absent
          </label>
        </div>
      ),
      sorter: (a: TableData, b: TableData) => a.attendance.length - b.attendance.length,
    },
  ];

  const getClassData = async () => {
    const res = await axiosInstance.get(`/class?id=${id}`);
    // console.log(res.data);
    setClassDetails({
      classTeacher: {
        firstName: res.data.classTeacher?.firstName || " ",
        lastName: res.data.classTeacher?.lastName || "",
        primaryContactNumber: res.data.classTeacher?.primaryContactNumber || "",
        emailAddress: res.data.classTeacher?.emailAddress || "",
        qualification: res.data.classTeacher?.qualification || "",
        workExperience: res.data.classTeacher?.workExperience || "",
      },
      className: res.data.name + " " + res.data.section,
      "No of Students": res.data.noOfStudents,
      "No of Subjects": res.data.noOfSubjects,
    });
    setSubjects(res.data.subjects);
    let studentsList = res.data.students.map((s: any) => ({
      ...s,
      name: s.firstName + " " + s.lastName,
    }));
    setStudents(studentsList);
    setAttendance(studentsList);
  };

  useEffect(() => {
    getClassData();
    handleGetAttendace(); // Fetch current day's attendance
  }, []);

  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        {/* Page Header START */}
        <div className="d-md-flex d-block align-items-center  justify-content-between mb-3 ">
          <div className="my-auto mb-2">
            <h3 className="page-title mb-1">Class Details</h3>
            <nav>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to={routes.adminDashboard}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={routes.classes}>Classes </Link>
                </li>
                <li className="breadcrumb-item">Class Details</li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="mb-3 ">
          <ul className="nav nav-tabs">
            <li
              className={`nav-item nav-link ${
                activeTab === "classDetails" ? "active" : ""
              } cursor-pointer`}
              onClick={() => setActiveTab("classDetails")}
            >
              <Link to="#">Class Details</Link>
            </li>
            <li
              className={`nav-item nav-link ${
                activeTab === "studentsList" ? "active" : ""
              } cursor-pointer`}
              onClick={() => setActiveTab("studentsList")}
            >
              <Link to="#">Students</Link>
            </li>
            <li
              className={`nav-item nav-link ${
                activeTab === "subjectsList" ? "active" : ""
              } cursor-pointer`}
              onClick={() => setActiveTab("subjectsList")}
            >
              <Link to="#">Subjects</Link>
            </li>
            <li
              className={`nav-item nav-link ${
                activeTab === "attendence" ? "active" : ""
              } cursor-pointer`}
              onClick={() => setActiveTab("attendence")}
            >
              <Link to="#">Attendence</Link>
            </li>
          </ul>
        </div>
        {/* Page Header END */}
        {/* Class List */}
        {activeTab === "classDetails" && (
          <div className="card flex-fill mb-3">
            <div className="card-header">
              <h4 className="mb-3">Class Details</h4>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-6">
                  <strong>Class Teacher:</strong> {classDetails.classTeacher.firstName}{" "}
                  {classDetails.classTeacher.lastName}
                </div>
                <div className="col-md-6">
                  <strong>Contact Number:</strong> {classDetails.classTeacher.primaryContactNumber}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <strong>Email:</strong> {classDetails.classTeacher.emailAddress}
                </div>
                <div className="col-md-6">
                  <strong>Qualification:</strong> {classDetails.classTeacher.qualification}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <strong>Work Experience:</strong> {classDetails.classTeacher.workExperience} years
                </div>
                <div className="col-md-6">
                  <strong>Class Name:</strong> {classDetails.className}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <strong>No of Students:</strong> {classDetails["No of Students"]}
                </div>
                <div className="col-md-6">
                  <strong>No of Subjects:</strong> {classDetails["No of Subjects"]}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Students List */}
        {activeTab === "studentsList" && (
          <div className="card flex-fill me-3 mb-3">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3 ">Students List</h4>
              {/* Filters */}
              {/* <div className="d-flex align-items-center flex-wrap">
              <div className="input-icon-start mb-3 me-2 position-relative">
              <PredefinedDateRanges />
              </div>
              <div className="dropdown mb-3 me-2">
                <Link
                  to={"#"}
                  className="btn bnt-bg-outline-light bg-white dropdown-toggle"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                >
                  <i className="ti ti-filter me-2" />
                  Filter
                </Link>
                <div className="dropdown-menu drop-width" ref={dropdownMenuRef}>
                  <form>
                    <div className="d-flex align-items-center border-bottom p-3">
                      <h4>Filter</h4>
                    </div>
                    <div className="p-3 border-bottom pb-0">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Class</label>
                            <CommonSelect
                              className="select"
                              options={classSylabus}
                              defaultValue={classSylabus[0]}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Section</label>
                            <CommonSelect
                              className="select"
                              options={classSection}
                              defaultValue={classSection[0]}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Status</label>
                            <CommonSelect
                              className="select"
                              options={activeList}
                              defaultValue={activeList[0]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-3 d-flex align-items-center justify-content-end">
                      <Link to="#" className="btn btn-light me-3">
                        Reset
                      </Link>
                      <Link to="#" className="btn btn-primary" onClick={handleApplyClick}>
                        Apply
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
              <div className="dropdown mb-3">
                <Link
                  to="#"
                  className="btn btn-outline-light bg-white dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <i className="ti ti-sort-ascending-2 me-2" />
                  Sort by A-Z
                </Link>
                <ul className="dropdown-menu p-3">
                  <li>
                    <Link to="#" className="dropdown-item rounded-1 active">
                      Ascending
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="dropdown-item rounded-1">
                      Descending
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="dropdown-item rounded-1">
                      Recently Viewed
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="dropdown-item rounded-1">
                      Recently Added
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
            </div>
            {/* Students List */}
            <div className="card-body p-0 py-3">
              <Table
                columns={studentColumns}
                dataSource={students}
                Selection={false}
                notRequirePagination={true}
              />
            </div>
          </div>
        )}
        {/* Subjects List */}
        {activeTab === "subjectsList" && (
          <div className="card flex-fill mb-3">
            <div className="card-header">
              <h4 className="mb-3">Subjects List </h4>
            </div>
            <div className="card-body p-0 py-3">
              <Table
                columns={subjectColumns}
                dataSource={subjects}
                Selection={false}
                notRequirePagination={true}
              />
            </div>
          </div>
        )}
        {/* Attendece */}
        {activeTab === "attendence" && (
          <div className="card flex-fill mb-3">
            <div className="card-header">
              <h4 className="mb-3">Attendence</h4>
              <div className="d-flex gap-3">
                <DatePicker
                  onChange={(date: any, dateString: string | string[]) => {
                    if (typeof dateString === "string") {
                      setDate(dateString);
                    }
                  }}
                  value={dayjs(date, "DD-MM-YYYY")}
                  format={"DD-MM-YYYY"}
                />
                <Button onClick={handleGetAttendace}>Get Attendance</Button>
                <Button
                  style={{ backgroundColor: "green", color: "white", border: "none" }}
                  onClick={handleUploadAttendance}
                >
                  Upload Attendance
                </Button>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              <Table
                columns={attendanceColumns}
                dataSource={attendance}
                Selection={false}
                notRequirePagination={true}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassDetails;
