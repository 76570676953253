import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import axiosInstance from "../../auth/axiosInstance";
interface StepModalProps {
  showModal: boolean;
  closeModal: () => void;
}

type Ledger = {
  name: string;
  categoryType: string;
  ledgerType: string;
  balance: number;
  schoolId: number;
};

const StepModal = ({ showModal, closeModal }: StepModalProps) => {
  const { schoolId, schoolName, schoolLocation } = useSelector((state: any) => state.userSlice);
  const [step, setStep] = useState(1); // Track the current step
  const [kycData, setKycData] = useState<{
    schoolName: string;
    schoolLocation: string;

    assets: [Ledger, Ledger, Ledger]; // Tuple type for exactly 3 elements
    liabilities: [Ledger, Ledger, Ledger]; // Tuple type for exactly 3 elements
  }>({
    schoolName: schoolName,
    schoolLocation: schoolLocation,
    assets: [
      { name: "", ledgerType: "asset", categoryType: "", balance: 0, schoolId },
      { name: "", ledgerType: "asset", categoryType: "", balance: 0, schoolId },
      { name: "", ledgerType: "asset", categoryType: "", balance: 0, schoolId },
    ],
    liabilities: [
      { name: "", ledgerType: "liability", categoryType: "", balance: 0, schoolId },
      { name: "", ledgerType: "liability", categoryType: "", balance: 0, schoolId },
      { name: "", ledgerType: "liability", categoryType: "", balance: 0, schoolId },
    ],
  });
  const goToNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const goToPreviousStep = () => {
    setStep((prevStep) => (prevStep === 1 ? 3 : prevStep - 1));
  };
  const handleUpdateKYC = async () => {
    // console.log(kycData);
    const res = await axiosInstance.post("/school/" + schoolId + "/kyc", kycData);
    closeModal();
  };

  const assetOptions = [
    // { label: "Select", value: "Select" },
    { label: "Payment Method", value: "payment" },
    // { label: "Fees Type", value: "feesType" },
    // { label: "Salary Type", value: "salaryType" },
    // { label: "Vendor", value: "vendor" },
    { label: "Other", value: "other" },
  ];
  const liabilityOptions = [
    // { label: "Select", value: "Select" },
    // { label: "Payment Method", value: "payment" },
    // { label: "Fees Type", value: "feesType" },
    // { label: "Salary Type", value: "salaryType" },
    { label: "Vendor", value: "vendor" },
    { label: "Other", value: "other" },
  ];
  return (
    <Modal onHide={closeModal} show={showModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Welcome to Schooza!</h4>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="text-center">
        <div>
          {step === 1 && (
            <div className="text-center p-4">
              <i className="fas fa-school fa-3x text-primary mb-3"></i> {/* School Icon */}
              <h5 className="mb-3">Welcome to Schooza!</h5>
              <p>
                Let’s take a quick tour and show you how Schooza can make managing your school
                easier. From handling admissions to tracking progress, we’ve got you covered!
              </p>
              <ul className="list-unstyled text-start mt-4">
                <li className="mb-2">
                  <i className="fas fa-user-graduate text-success me-2"></i>
                  Manage students effortlessly.
                </li>
                <li className="mb-2">
                  <i className="fas fa-chalkboard-teacher text-warning me-2"></i>
                  Simplify teacher workflows.
                </li>
                <li className="mb-2">
                  <i className="fas fa-book text-info me-2"></i>
                  Organize lessons and assignments with ease.
                </li>
              </ul>
              <p className="mt-4">Ready to see it in action? Let’s keep going!</p>
            </div>
          )}
          {step === 2 && (
            <div className="text-center p-4">
              <i className="fas fa-building fa-3x text-primary mb-3"></i> {/* School Icon */}
              <h5 className="mb-3">Step 1: Basic School Details</h5>
              <p>
                We’ve pre-filled some details about your school. Please check if everything looks
                correct!
              </p>
              <div className="row mt-4">
                {/* School Name Input */}
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label">
                      <i className="fas fa-school text-secondary me-2"></i> School Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="schoolName"
                      onChange={(e) =>
                        setKycData((prev: any) => ({ ...prev, schoolName: e.target.value }))
                      }
                      value={kycData?.schoolName}
                      //   onChange={handleInputChange}
                    />
                  </div>
                </div>

                {/* School Location Input */}
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label">
                      <i className="fas fa-map-marker-alt text-danger me-2"></i> School Location
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="schoolLocation"
                      onChange={(e) =>
                        setKycData((prev: any) => ({ ...prev, schoolLocation: e.target.value }))
                      }
                      value={kycData?.schoolLocation}

                      //   onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <p className="mt-4">
                If these details look good, click <strong>Next</strong> to continue. If something’s
                off, you can make changes above.
              </p>
            </div>
          )}

          {step === 3 && (
            <div>
              <div>
                <h5>Step 2: Set Up School Finances & Payment Methods</h5>
                <h4 className="form-title">Asset Details</h4>
              </div>

              <div className="row mt-5  ">
                {kycData?.assets.map((asset: any, index: any) => (
                  <>
                    <div key={index} className="row mb-3 ">
                      <div className="col-md-4">
                        <label className="form-label">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={asset.name}
                          onChange={(e) =>
                            setKycData((prev: any) => ({
                              ...prev,
                              assets: prev.assets.map((s: any, idx: any) =>
                                idx === index ? { ...s, name: e.target.value } : s
                              ),
                            }))
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="form-label">Category</label>
                        <Select
                          options={assetOptions}
                          onChange={(obj: any) =>
                            setKycData((prev: any) => ({
                              ...prev,
                              assets: prev.assets.map((s: any, idx: any) =>
                                idx === index ? { ...s, categoryType: obj?.value } : s
                              ),
                            }))
                          }
                          value={
                            assetOptions.filter(
                              (a: any) => a.value === kycData?.assets[index].categoryType
                            )[0]
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="form-label">Opening Balance</label>
                        <input
                          type="number"
                          className="form-control"
                          value={asset.balance}
                          onChange={(e) =>
                            setKycData((prev: any) => ({
                              ...prev,
                              assets: prev.assets.map((s: any, idx: any) =>
                                idx === index ? { ...s, balance: e.target.value } : s
                              ),
                            }))
                          }
                        />
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          )}
          {step === 4 && (
            <div>
              <h5>Step 3: Set Up School Finances & Payment Methods</h5>
              <h4 className="form-title">Liabilities Details</h4>

              <div className="row mt-5  ">
                {kycData.liabilities.map((liability: any, index: any) => (
                  <>
                    <div key={index} className="row mb-3 ">
                      <div className="col-md-4">
                        <label className="form-label">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={liability.name}
                          onChange={(e) =>
                            setKycData((prev: any) => ({
                              ...prev,
                              liabilities: prev.liabilities.map((s: any, idx: any) =>
                                idx === index ? { ...s, name: e.target.value } : s
                              ),
                            }))
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="form-label">Category</label>
                        <Select
                          options={liabilityOptions}
                          value={
                            liabilityOptions.filter(
                              (a: any) => a.value === kycData?.liabilities[index].categoryType
                            )[0]
                          }
                          onChange={(obj: any) =>
                            setKycData((prev: any) => ({
                              ...prev,
                              liabilities: prev.liabilities.map((s: any, idx: any) =>
                                idx === index ? { ...s, categoryType: obj.value } : s
                              ),
                            }))
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="form-label">Opening Balance</label>
                        <input
                          type="number"
                          className="form-control"
                          value={liability.balance}
                          onChange={(e) =>
                            setKycData((prev: any) => ({
                              ...prev,
                              liabilities: prev.liabilities.map((s: any, idx: any) =>
                                idx === index ? { ...s, balance: e.target.value } : s
                              ),
                            }))
                          }
                        />
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          )}

          {step === 100 && (
            <div>
              <h5>Step 5: Set Up School Finances & Payment Methods</h5>
              <p>Let's set up the payment methods and manage your school’s finances:</p>

              <div className="row">
                {/* Payment Methods Section */}
                <div className="col-md-6 mb-3">
                  <h6 className="text-primary">Payment Methods</h6>
                  <div>
                    <div className="d-flex align-items-center">
                      <i
                        className="fas fa-credit-card text-success me-2"
                        style={{ fontSize: "1.5rem" }}
                      ></i>
                      <label className="form-label">UPI</label>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter details"
                      defaultValue="UPI Payment"
                    />
                  </div>
                  <div className="mt-3">
                    <div className="d-flex align-items-center">
                      <i
                        className="fas fa-money-bill-alt text-warning me-2"
                        style={{ fontSize: "1.5rem" }}
                      ></i>
                      <label className="form-label">Cash</label>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter details"
                      defaultValue="Cash Payment"
                    />
                  </div>
                  <div className="mt-3">
                    <div className="d-flex align-items-center">
                      <i
                        className="fas fa-building text-info me-2"
                        style={{ fontSize: "1.5rem" }}
                      ></i>
                      <label className="form-label">Bank</label>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter details"
                      defaultValue="XYZ Bank Account"
                    />
                  </div>

                  <button className="btn btn-outline-secondary mt-3">
                    <i className="fas fa-plus-circle me-2"></i> Add More Payment Methods
                  </button>
                </div>

                {/* Assets and Liabilities Section */}
                <div className="col-md-6 mb-3">
                  <h6 className="text-primary">Assets</h6>

                  {/* Assets List */}
                  <div>
                    <div className="d-flex gap-2 justify-content-start align-items-center">
                      <label className="form-label"> Laptop</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Opening Balance"
                        defaultValue="5000"
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="d-flex gap-2 justify-content-start align-items-center">
                      <label className="form-label">Building</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Opening Balance"
                        defaultValue="200000"
                      />
                    </div>
                  </div>

                  {/* Liabilities List */}
                  <div className="mt-4">
                    <h6 className="text-primary">Liabilities</h6>
                    <div className="d-flex justify-content-between align-items-center">
                      <label className="form-label"> Loan</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Outstanding Balance"
                        defaultValue="10000"
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label className="form-label">Vendor Payment</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Outstanding Balance"
                        defaultValue="5000"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <p>You can always update your payment methods and financial details later.</p>
              </div>
            </div>
          )}
          {step === 1000 && (
            <div>
              <h5 className="text-primary">Step 6: Set Up Notices & School Communication</h5>
              <p className="text-muted">
                Now, let's enable notices and communication channels for your school:
              </p>

              <div className="row">
                {/* Notices Section */}
                <div className="col-md-6 mb-3">
                  <div className="d-flex align-items-center">
                    <i className="fas fa-bell text-warning me-2" style={{ fontSize: "1.5rem" }}></i>
                    <h6 className="text-success">Notices</h6>
                  </div>
                  <p className="text-muted">
                    Enable notices to communicate important updates to students, parents, and staff.
                  </p>
                  <div>
                    <span className="badge bg-success">Enabled</span>
                  </div>
                </div>

                {/* Communication Channels Section */}
                <div className="col-md-6 mb-3">
                  <div className="d-flex align-items-center">
                    <i
                      className="fas fa-comments text-info me-2"
                      style={{ fontSize: "1.5rem" }}
                    ></i>
                    <h6 className="text-primary">School Communication</h6>
                  </div>
                  <p className="text-muted">
                    Choose your preferred communication channels for instant updates:
                  </p>

                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-2">
                      <i
                        className="fab fa-whatsapp text-success me-2"
                        style={{ fontSize: "1.5rem" }}
                      ></i>
                      <span>WhatsApp</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <i
                        className="fas fa-envelope text-primary me-2"
                        style={{ fontSize: "1.5rem" }}
                      ></i>
                      <span>Email</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <i className="fas fa-sms text-danger me-2" style={{ fontSize: "1.5rem" }}></i>
                      <span>SMS</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <p className="text-muted">
                  Feel free to modify these settings anytime from the admin panel.
                </p>
              </div>
            </div>
          )}
          {step === 5 && (
            <div>
              <h5 className="text-success"> Review & Finish</h5>
              <p className="text-muted">
                You’ve completed the onboarding steps! Here’s a quick summary of the details:
              </p>

              <div className="row d-flex justify-content-center">
                {/* School Name Section */}
                <div className="col-md-4 mb-4 text-center">
                  <i className="fas fa-school text-primary" style={{ fontSize: "2.5rem" }}></i>
                  <div className="mt-2">
                    <h6 className="text-dark">School Name</h6>
                    <p className="text-muted">{kycData?.schoolName}</p>
                  </div>
                </div>

                {/* Location Section */}
                <div className="col-md-4 mb-4 text-center">
                  <i
                    className="fas fa-map-marker-alt text-danger"
                    style={{ fontSize: "2.5rem" }}
                  ></i>
                  <div className="mt-2">
                    <h6 className="text-dark">Location</h6>
                    <p className="text-muted">{kycData?.schoolLocation}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          {/* Skip button aligned to the left */}
          {step !== 1 && (
            <Button variant="outline-primary" onClick={goToNextStep} className="me-auto">
              Skip
              <i className="fas fa-step-forward ms-2"></i>
            </Button>
          )}

          {/* Right-aligned buttons for Prev/Next/Finish */}
          <div className="d-flex gap-4">
            {step > 1 && (
              <Button variant="light" onClick={goToPreviousStep}>
                <i className="fa fa-angle-left me-2" />
                Prev
              </Button>
            )}
            {step < 5 ? (
              <Button variant="primary" onClick={goToNextStep}>
                Next
                <i className="fa fa-angle-right ms-2" />
              </Button>
            ) : (
              <Button variant="success" onClick={() => handleUpdateKYC()}>
                Finish
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default StepModal;
